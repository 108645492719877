@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Comfortaa:wght@400;700&family=Open+Sans:wght@400;500;600;700&display=swap);
.FieldError_center__26ZTE {
  text-align: center; }

.FieldError_rignt__1vqK_ {
  text-align: right; }

.FieldError_left__31hBM {
  text-align: left; }

.FieldError_small__1p7Bi {
  font-size: 14px; }

.FieldError_medium__1EMWt {
  font-size: 16px; }

.FieldError_big__3yl1F {
  font-size: 16px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Transition-enter {
  opacity: 0.01; }

.Transition-enter-active {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.Transition-exit {
  opacity: 1; }

.Transition-exit-active {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.italic {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.timePickerMaxIndex {
  z-index: 999999 !important; }

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes pulse-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.pulse-icon {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: pulse-shadow 2s infinite;
          animation: pulse-shadow 2s infinite; }
  .pulse-icon:hover {
    -webkit-animation: none;
            animation: none; }

.warning {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .warning p {
    color: #fff; }
  .warning.error {
    background: rgba(252, 68, 88, 0.5) !important; }

.pulse span,
.pulse path {
  -webkit-animation-name: pulse_animation;
          animation-name: pulse_animation;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.custom-btn {
  overflow: hidden;
  position: relative; }

.custom-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.custom-btn:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.wrap-select-custom {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .wrap-select-custom input {
    height: 35px !important;
    margin-top: 0; }
  .wrap-select-custom span {
    right: 0px; }

.opacity0 {
  opacity: 0 !important; }

.titleWithSelect {
  display: flex;
  align-items: center; }
  .titleWithSelect span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.require {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.container70 {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .container70 {
      padding: 0; } }

.color-green {
  color: #4caf50; }

.justify-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.content-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.max969 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.fz2550 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.fz2530 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.text-left {
  text-align: left !important; }

.fz1830 {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .fz1830 {
      font-size: 16px;
      line-height: 26px; } }

.fz1628 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .fz1628 {
      font-size: 14px;
      line-height: 28px; } }
  .fz1628 p,
  .fz1628 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .fz1628 p,
      .fz1628 a {
        font-size: 14px;
        line-height: 28px; } }

.fz1426 {
  font-size: 14px;
  line-height: 26px; }

.fw600 {
  font-weight: 600; }

.fw799 {
  font-weight: 700; }

.c57 {
  color: #575757; }

.btn-orange {
  color: var(--accent-color);
  cursor: pointer; }

.ml10 {
  margin-left: 10px; }

.mr10 {
  margin-right: 10px; }

.ml20 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .ml20 {
      margin-left: 15px; } }

.mr20 {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .mr20 {
      margin-right: 15px; } }

.ml30 {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .ml30 {
      margin-left: 20px; } }

.mr30 {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .mr30 {
      margin-right: 20px; } }

.mt30 {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .mt30 {
      margin-top: 15px; } }

.mb30 {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .mb30 {
      margin-bottom: 15px; } }

.mt50 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .mt50 {
      margin-top: 20px; } }

.mt50i {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .mt50i {
      margin-top: 20px !important; } }

.mb50 {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .mb50 {
      margin-bottom: 20px; } }

.mt20 {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .mt20 {
      margin-top: 15px; } }

.mb20 {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .mb20 {
      margin-bottom: 15px; } }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt0 {
  margin-top: 0 !important;
  flex-grow: 1; }

.mt-15 {
  margin-top: -15px !important; }

.w100 {
  width: 100% !important; }

.pt20 {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .pt20 {
      padding-top: 15px; } }

.pb20 {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .pb20 {
      padding-bottom: 15px; } }

.p0 {
  padding: 0 !important; }

.m_auto {
  margin-right: auto;
  margin-left: auto; }

.pt50 {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .pt50 {
      padding-top: 20px; } }

.pt30 {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .pt30 {
      padding-top: 15px; } }

.pb100 {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .pb100 {
      padding-bottom: 50px; } }

.pb50 {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .pb50 {
      padding-bottom: 20px; } }

.pb30 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .pb30 {
      padding-bottom: 15px; } }

.title-invent {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .title-invent {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.description-invent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .description-invent a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .description-invent {
      font-size: 14px;
      line-height: 28px; } }

.caption {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .caption b {
    display: flex;
    margin-top: -5px; }
    .caption b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .caption {
      font-size: 22px;
      line-height: 30px; } }

.wrap-loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.wrap-loader {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .wrap-loader svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.btn-green {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .btn-green {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.btn-green:hover {
  background: rgba(76, 175, 80, 0.7); }

.btn-green:active {
  background: rgba(76, 175, 80, 0.5); }

.button-orange {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .button-orange:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .button-orange:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.button-orange-light {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .button-orange-light:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.fz1728 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.c8c {
  color: #8c8c8c; }

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.relative {
  position: relative; }

.scroll {
  overflow-y: scroll !important; }
  .scroll::-webkit-scrollbar {
    width: 2px; }
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .scroll::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.ticketIcon {
  position: relative;
  display: inline-flex; }
  .ticketIcon strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.textOnButton {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .textOnButton span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.modalType {
  background: var(--background-color) !important;
  max-width: 962px; }

.CloseBtn {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .CloseBtn svg {
    opacity: 1 !important; }
  .CloseBtn:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .CloseBtn:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .CloseBtn {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.inputWithCalendar {
  position: relative; }
  .inputWithCalendar input {
    padding-right: 20px; }
  .inputWithCalendar svg,
  .inputWithCalendar span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .inputWithCalendar svg path,
    .inputWithCalendar span path {
      fill: #8c8c8c; }

.text-orange {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.color-orange {
  color: var(--accent-color) !important; }

.overlay-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.phone-verification-form label {
  font-weight: 500;
  display: block;
  text-align: center;
  margin-bottom: 10px; }

.phone-verification-form input {
  text-align: center;
  display: block;
  width: 100px;
  margin: 5px auto; }

.phone-verification-form .text-small {
  font-size: 1em; }

.phone-verification-form .btn-blue {
  margin-top: 20px; }

.center {
  margin-left: auto;
  margin-right: auto; }

.checkbox-component {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  height: 24px;
  min-width: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .checkbox-component input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    margin: 0;
    width: 100%; }
  .checkbox-component .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 2px; }
    .checkbox-component .checkmark::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 2px;
      width: 9px;
      height: 14px;
      border: solid #00A5FF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .checkbox-component input:checked ~ .checkmark::after {
    display: block; }

.custom {
  min-height: 12px !important;
  width: auto !important;
  height: 100% !important;
  min-width: 12px !important;
  display: flex !important;
  align-items: center !important; }
  .custom .checkmark {
    min-height: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    opacity: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: 1px solid #8C8C8C !important;
    background: transparent;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important; }
    .custom .checkmark.large {
      -webkit-transform: scale(1.1) translateY(-50%);
              transform: scale(1.1) translateY(-50%);
      min-width: 17px !important;
      min-height: 17px !important; }
    .custom .checkmark.circle {
      border-radius: 10em; }
      .custom .checkmark.circle svg {
        margin-top: 0 !important; }
    .custom .checkmark svg {
      -webkit-transition: all .15s ease-in;
      transition: all .15s ease-in;
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      margin-top: 1px; }
    .custom .checkmark:after {
      display: none; }
  .custom input:checked ~ .checkmark::after {
    display: none; }
  .custom input:checked ~ .checkmark svg {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }

.checkbox-component.custom.large {
  min-height: 18px !important;
  min-width: 18px !important; }
  .checkbox-component.custom.large input {
    height: 18px; }

.PhoneInput_Component__2-kcR {
  margin-top: 5px;
  width: 100%; }
  .PhoneInput_ComponentInput__9Gq6D {
    width: 100% !important;
    height: 42px !important;
    border-radius: 2px !important;
    border: 1px solid #cccccc !important;
    height: 42px !important;
    font-size: 20px !important;
    border-radius: 2px !important;
    color: #23404b !important;
    font-weight: 500 !important;
    padding: 7px 7px 7px 48px !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.PhoneInput_defaultInput__3eO_v {
  border: 1px solid #8C8C8C !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  width: 100%;
  height: 46px !important;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .PhoneInput_defaultInput__3eO_v {
      width: 100%;
      font-size: 16px !important; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.PhoneInput_input-wrap__2rBD0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .PhoneInput_input-wrap__2rBD0 input::-webkit-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .PhoneInput_input-wrap__2rBD0 input::-moz-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .PhoneInput_input-wrap__2rBD0 input::-ms-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .PhoneInput_input-wrap__2rBD0 input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.PhoneInput_ticketBackground__31Xk- {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .PhoneInput_ticketBackground__31Xk- span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .PhoneInput_ticketBackground__31Xk- svg {
    z-index: 1; }
  .PhoneInput_ticketBackgroundSmall__2XJnm {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-right: -10px; }

.PhoneInput_wrap-input__vHM3a {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .PhoneInput_wrap-input__vHM3a {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.PhoneInput_labelInput__15xH0 {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .PhoneInput_labelInput__15xH0 {
      font-size: 14px;
      line-height: 25px; } }

.PhoneInput_defaultInput__3eO_v {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .PhoneInput_defaultInput__3eO_v {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.PhoneInput_checkbox__2PDCM {
  display: none; }

.PhoneInput_checkbox__2PDCM:checked + label:after {
  opacity: 1; }

.PhoneInput_checkbox__2PDCM + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0.7) translateY(calc(-50% - 5px));
          transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  -webkit-transition: opacity .35s ease;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .PhoneInput_checkbox__2PDCM + label:after {
      -webkit-transform: scale(0.7) translateX(3px) translateY(2px);
              transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.PhoneInput_checkbox__2PDCM + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  -webkit-transition: .2s;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .PhoneInput_checkbox__2PDCM + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.PhoneInput_checkbox__2PDCM + label {
  position: relative; }

.PhoneInput_checkbox__2PDCM + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0); }

.PhoneInput_checkbox__2PDCM:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.PhoneInput_checkbox__2PDCM:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa; }

.PhoneInput_checkbox__2PDCM:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.PhoneInput_simpleSelect__GKRtr {
  position: relative;
  margin-bottom: 20px; }

.PhoneInput_simpleInput__3mQEV {
  position: relative;
  margin-bottom: 20px; }
  .PhoneInput_simpleInput__3mQEV label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .PhoneInput_simpleInput__3mQEV label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .PhoneInput_simpleInput__3mQEV input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .PhoneInput_simpleInput__3mQEV input:focus, .PhoneInput_simpleInput__3mQEV input:active {
      border: 1px solid #8C8C8C !important; }
  .PhoneInput_simpleInput__3mQEV textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .PhoneInput_simpleInput__3mQEV textarea:focus, .PhoneInput_simpleInput__3mQEV textarea:active {
      border: 1px solid #8C8C8C !important; }
  .PhoneInput_simpleInput__3mQEV select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    -webkit-transition: border .35s ease;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .PhoneInput_simpleInput__3mQEV select option {
      width: 30px; }
    .PhoneInput_simpleInput__3mQEV select:focus, .PhoneInput_simpleInput__3mQEV select:active {
      border: 1px solid #8C8C8C !important; }

.PhoneInput_simpleInputBorder__3okeL {
  margin-bottom: 0; }
  .PhoneInput_simpleInputBorder__3okeL input {
    border: 1px solid #8C8C8C !important; }

.PhoneInput_hideBorder__3H363 input {
  border: 1px solid transparent !important;
  -webkit-transition: border .35s ease;
  transition: border .35s ease; }

.PhoneInput_borderNone__2g4_o {
  border: 1px solid transparent !important; }
  .PhoneInput_borderNone__2g4_o * {
    border: none; }

.PhoneInput_column__2uXAp {
  display: flex;
  flex-direction: column; }

.PhoneInput_fz17__lOhgC {
  font-size: 17px !important; }
  .PhoneInput_fz17__lOhgC * {
    font-size: 17px !important; }

.PhoneInput_radio-control__3sN9U {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .PhoneInput_radio-control__3sN9U {
      flex-direction: column; } }
  .PhoneInput_radio-control__3sN9U label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .PhoneInput_radio-control__3sN9U label {
        width: 100%; } }
  .PhoneInput_radio-control__3sN9U input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.PhoneInput_radio__6Ew4l {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  -webkit-transition: border .35s ease, color .35s ease;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .PhoneInput_radio__6Ew4l span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    -webkit-transform: scale(0);
            transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    -webkit-transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, transform .35s ease;
    transition: opacity .35s ease, transform .35s ease, -webkit-transform .35s ease; }

.PhoneInput_active__330yc {
  color: var(--accent-color); }
  .PhoneInput_active__330yc .PhoneInput_radio__6Ew4l {
    border: 1.2px solid var(--accent-color); }
    .PhoneInput_active__330yc .PhoneInput_radio__6Ew4l span {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.PhoneInput_input_outline__3Gkr1 {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .PhoneInput_input_outline__3Gkr1 span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  -webkit-transition: border .34s ease;
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.PhoneInput_zIndex10__miy6g {
  z-index: 10 !important; }

.imageForm {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: hidden; }
  .imageForm > * {
    will-change: transform;
    position: absolute; }

.big-box {
  right: 200px;
  z-index: 1;
  bottom: 90px; }

.small-box {
  right: 180px;
  z-index: 10;
  bottom: 80px; }

.box-shadow {
  bottom: 50px;
  right: 180px; }

.shadow {
  bottom: 140px;
  right: 50px;
  z-index: -1; }

.ticket {
  bottom: 190px;
  right: 170px;
  z-index: 20; }

.icon-1 {
  right: 30px;
  bottom: 400px; }

.icon-2 {
  right: 110px;
  bottom: 300px; }

.icon-3 {
  right: 200px;
  bottom: 440px;
  z-index: 8; }

.icon-4 {
  right: 320px;
  z-index: 22;
  bottom: 380px;
  -webkit-filter: blur(1px);
          filter: blur(1px); }

.icon-5 {
  bottom: 600px;
  right: 200px; }

.icon-6 {
  bottom: 440px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.ModalSign_input-wrap__2D_ta {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .ModalSign_input-wrap__2D_ta input::-webkit-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .ModalSign_input-wrap__2D_ta input::-moz-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .ModalSign_input-wrap__2D_ta input::-ms-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .ModalSign_input-wrap__2D_ta input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ModalSign_ticketBackground__2Hk8n {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .ModalSign_ticketBackground__2Hk8n span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .ModalSign_ticketBackground__2Hk8n svg {
    z-index: 1; }
  .ModalSign_ticketBackgroundSmall__RlNya {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-right: -10px; }

.ModalSign_wrap-input__3IDgd {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .ModalSign_wrap-input__3IDgd {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.ModalSign_labelInput__3KnJQ {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .ModalSign_labelInput__3KnJQ {
      font-size: 14px;
      line-height: 25px; } }

.ModalSign_defaultInput__4nprE {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .ModalSign_defaultInput__4nprE {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.ModalSign_checkbox__A5Ctf {
  display: none; }

.ModalSign_checkbox__A5Ctf:checked + label:after {
  opacity: 1; }

.ModalSign_checkbox__A5Ctf + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0.7) translateY(calc(-50% - 5px));
          transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  -webkit-transition: opacity .35s ease;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .ModalSign_checkbox__A5Ctf + label:after {
      -webkit-transform: scale(0.7) translateX(3px) translateY(2px);
              transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.ModalSign_checkbox__A5Ctf + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  -webkit-transition: .2s;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .ModalSign_checkbox__A5Ctf + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.ModalSign_checkbox__A5Ctf + label {
  position: relative; }

.ModalSign_checkbox__A5Ctf + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0); }

.ModalSign_checkbox__A5Ctf:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.ModalSign_checkbox__A5Ctf:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa; }

.ModalSign_checkbox__A5Ctf:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.ModalSign_simpleSelect__3JSNP {
  position: relative;
  margin-bottom: 20px; }

.ModalSign_simpleInput__1bx-S {
  position: relative;
  margin-bottom: 20px; }
  .ModalSign_simpleInput__1bx-S label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .ModalSign_simpleInput__1bx-S label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .ModalSign_simpleInput__1bx-S input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .ModalSign_simpleInput__1bx-S input:focus, .ModalSign_simpleInput__1bx-S input:active {
      border: 1px solid #8C8C8C !important; }
  .ModalSign_simpleInput__1bx-S textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .ModalSign_simpleInput__1bx-S textarea:focus, .ModalSign_simpleInput__1bx-S textarea:active {
      border: 1px solid #8C8C8C !important; }
  .ModalSign_simpleInput__1bx-S select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    -webkit-transition: border .35s ease;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .ModalSign_simpleInput__1bx-S select option {
      width: 30px; }
    .ModalSign_simpleInput__1bx-S select:focus, .ModalSign_simpleInput__1bx-S select:active {
      border: 1px solid #8C8C8C !important; }

.ModalSign_simpleInputBorder__30OxF {
  margin-bottom: 0; }
  .ModalSign_simpleInputBorder__30OxF input {
    border: 1px solid #8C8C8C !important; }

.ModalSign_hideBorder__2qD8K input {
  border: 1px solid transparent !important;
  -webkit-transition: border .35s ease;
  transition: border .35s ease; }

.ModalSign_borderNone__HcnkM {
  border: 1px solid transparent !important; }
  .ModalSign_borderNone__HcnkM * {
    border: none; }

.ModalSign_column__1hNea {
  display: flex;
  flex-direction: column; }

.ModalSign_fz17__20J0l {
  font-size: 17px !important; }
  .ModalSign_fz17__20J0l * {
    font-size: 17px !important; }

.ModalSign_radio-control__RmUAz {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .ModalSign_radio-control__RmUAz {
      flex-direction: column; } }
  .ModalSign_radio-control__RmUAz label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .ModalSign_radio-control__RmUAz label {
        width: 100%; } }
  .ModalSign_radio-control__RmUAz input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.ModalSign_radio__1NsBA {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  -webkit-transition: border .35s ease, color .35s ease;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .ModalSign_radio__1NsBA span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    -webkit-transform: scale(0);
            transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    -webkit-transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, transform .35s ease;
    transition: opacity .35s ease, transform .35s ease, -webkit-transform .35s ease; }

.ModalSign_active__43aVn {
  color: var(--accent-color); }
  .ModalSign_active__43aVn .ModalSign_radio__1NsBA {
    border: 1.2px solid var(--accent-color); }
    .ModalSign_active__43aVn .ModalSign_radio__1NsBA span {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.ModalSign_input_outline__k25Yi {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .ModalSign_input_outline__k25Yi span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  -webkit-transition: border .34s ease;
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.ModalSign_zIndex10__3939Z {
  z-index: 10 !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.ModalSign_Transition-enter__3p3ke {
  opacity: 0.01; }

.ModalSign_Transition-enter-active__2kj-z {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.ModalSign_Transition-exit__pb-LN {
  opacity: 1; }

.ModalSign_Transition-exit-active__2pun2 {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.ModalSign_italic__EdasJ {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.ModalSign_timePickerMaxIndex__1tFIb {
  z-index: 999999 !important; }

@-webkit-keyframes ModalSign_pulse_animation__8kpGM {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ModalSign_pulse_animation__8kpGM {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes ModalSign_pulse-shadow__3LYf4 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes ModalSign_pulse-shadow__3LYf4 {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.ModalSign_pulse-icon__28D71 {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: ModalSign_pulse-shadow__3LYf4 2s infinite;
          animation: ModalSign_pulse-shadow__3LYf4 2s infinite; }
  .ModalSign_pulse-icon__28D71:hover {
    -webkit-animation: none;
            animation: none; }

.ModalSign_warning__3clHJ {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .ModalSign_warning__3clHJ p {
    color: #fff; }
  .ModalSign_warning__3clHJ.ModalSign_error__1UK9g {
    background: rgba(252, 68, 88, 0.5) !important; }

.ModalSign_pulse__3HHjs span,
.ModalSign_pulse__3HHjs path {
  -webkit-animation-name: ModalSign_pulse_animation__8kpGM;
          animation-name: ModalSign_pulse_animation__8kpGM;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.ModalSign_custom-btn__1gbW2 {
  overflow: hidden;
  position: relative; }

.ModalSign_custom-btn__1gbW2:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.ModalSign_custom-btn__1gbW2:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: ModalSign_slideme__3_0Fj;
  animation-name: ModalSign_slideme__3_0Fj;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes ModalSign_slideme__3_0Fj {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.ModalSign_wrap-select-custom__2qeKk {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .ModalSign_wrap-select-custom__2qeKk input {
    height: 35px !important;
    margin-top: 0; }
  .ModalSign_wrap-select-custom__2qeKk span {
    right: 0px; }

.ModalSign_opacity0__3nOof {
  opacity: 0 !important; }

.ModalSign_titleWithSelect__-e85k {
  display: flex;
  align-items: center; }
  .ModalSign_titleWithSelect__-e85k span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.ModalSign_require__1OcwQ {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.ModalSign_container70__3oqbJ {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .ModalSign_container70__3oqbJ {
      padding: 0; } }

.ModalSign_color-green__1pdBt {
  color: #4caf50; }

.ModalSign_justify-center__1m9X3 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.ModalSign_content-center__1ceCn {
  display: flex;
  align-items: center;
  justify-content: center; }

.ModalSign_max969__2p159 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.ModalSign_fz2550__1njHm {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.ModalSign_fz2530__3rUjH {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.ModalSign_text-left__3Gl7V {
  text-align: left !important; }

.ModalSign_fz1830__2gPWs {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_fz1830__2gPWs {
      font-size: 16px;
      line-height: 26px; } }

.ModalSign_fz1628__Q9Tog {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .ModalSign_fz1628__Q9Tog {
      font-size: 14px;
      line-height: 28px; } }
  .ModalSign_fz1628__Q9Tog p,
  .ModalSign_fz1628__Q9Tog a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .ModalSign_fz1628__Q9Tog p,
      .ModalSign_fz1628__Q9Tog a {
        font-size: 14px;
        line-height: 28px; } }

.ModalSign_fz1426__2txvh {
  font-size: 14px;
  line-height: 26px; }

.ModalSign_fw600__1Ppc3 {
  font-weight: 600; }

.ModalSign_fw799__cRLz7 {
  font-weight: 700; }

.ModalSign_c57__DDOL- {
  color: #575757; }

.ModalSign_btn-orange__H4cxy {
  color: var(--accent-color);
  cursor: pointer; }

.ModalSign_ml10__ukyeM {
  margin-left: 10px; }

.ModalSign_mr10__3OJ2N {
  margin-right: 10px; }

.ModalSign_ml20__jYyD1 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_ml20__jYyD1 {
      margin-left: 15px; } }

.ModalSign_mr20__33MCZ {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_mr20__33MCZ {
      margin-right: 15px; } }

.ModalSign_ml30__3-fpU {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_ml30__3-fpU {
      margin-left: 20px; } }

.ModalSign_mr30__1xXNk {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_mr30__1xXNk {
      margin-right: 20px; } }

.ModalSign_mt30__nnxwD {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_mt30__nnxwD {
      margin-top: 15px; } }

.ModalSign_mb30__s2yIP {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_mb30__s2yIP {
      margin-bottom: 15px; } }

.ModalSign_mt50__3j6T3 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .ModalSign_mt50__3j6T3 {
      margin-top: 20px; } }

.ModalSign_mt50i__10yEe {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .ModalSign_mt50i__10yEe {
      margin-top: 20px !important; } }

.ModalSign_mb50__34YkW {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .ModalSign_mb50__34YkW {
      margin-bottom: 20px; } }

.ModalSign_mt20__QqMUl {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_mt20__QqMUl {
      margin-top: 15px; } }

.ModalSign_mb20__3Vzyl {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_mb20__3Vzyl {
      margin-bottom: 15px; } }

.ModalSign_mb5__1hU84 {
  margin-bottom: 5px !important; }

.ModalSign_mt5__3vkdw {
  margin-top: 5px !important; }

.ModalSign_mb0__aTebQ {
  margin-bottom: 0 !important; }

.ModalSign_mt0__3ynCU {
  margin-top: 0 !important;
  flex-grow: 1; }

.ModalSign_mt-15__2Nj8J {
  margin-top: -15px !important; }

.ModalSign_w100__Fk-Ol {
  width: 100% !important; }

.ModalSign_pt20__1zJp5 {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_pt20__1zJp5 {
      padding-top: 15px; } }

.ModalSign_pb20__FylN_ {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_pb20__FylN_ {
      padding-bottom: 15px; } }

.ModalSign_p0__1oZfe {
  padding: 0 !important; }

.ModalSign_m_auto__2JePk {
  margin-right: auto;
  margin-left: auto; }

.ModalSign_pt50__2kkNt {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .ModalSign_pt50__2kkNt {
      padding-top: 20px; } }

.ModalSign_pt30__117Dt {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_pt30__117Dt {
      padding-top: 15px; } }

.ModalSign_pb100__4WNSd {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .ModalSign_pb100__4WNSd {
      padding-bottom: 50px; } }

.ModalSign_pb50__2w5Ui {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .ModalSign_pb50__2w5Ui {
      padding-bottom: 20px; } }

.ModalSign_pb30__3aJEa {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .ModalSign_pb30__3aJEa {
      padding-bottom: 15px; } }

.ModalSign_title-invent__OywOq {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .ModalSign_title-invent__OywOq {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.ModalSign_description-invent__1VEQu {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .ModalSign_description-invent__1VEQu a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .ModalSign_description-invent__1VEQu {
      font-size: 14px;
      line-height: 28px; } }

.ModalSign_caption__1zswH {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .ModalSign_caption__1zswH b {
    display: flex;
    margin-top: -5px; }
    .ModalSign_caption__1zswH b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .ModalSign_caption__1zswH {
      font-size: 22px;
      line-height: 30px; } }

.ModalSign_wrap-loader-overlay__6JirU {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.ModalSign_wrap-loader__36MaE {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .ModalSign_wrap-loader__36MaE svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.ModalSign_btn-green__1d_32 {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .ModalSign_btn-green__1d_32 {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.ModalSign_btn-green__1d_32:hover {
  background: rgba(76, 175, 80, 0.7); }

.ModalSign_btn-green__1d_32:active {
  background: rgba(76, 175, 80, 0.5); }

.ModalSign_button-orange__1jl_I {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .ModalSign_button-orange__1jl_I:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .ModalSign_button-orange__1jl_I:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.ModalSign_button-orange-light__2oLVz {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .ModalSign_button-orange-light__2oLVz:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.ModalSign_fz1728__1KUo8 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.ModalSign_c8c__3yVTP {
  color: #8c8c8c; }

.ModalSign_space-between__2oFtk {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ModalSign_relative__3KKZG {
  position: relative; }

.ModalSign_scroll__2XiIN {
  overflow-y: scroll !important; }
  .ModalSign_scroll__2XiIN::-webkit-scrollbar {
    width: 2px; }
  .ModalSign_scroll__2XiIN::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .ModalSign_scroll__2XiIN::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.ModalSign_ticketIcon__3pa6N {
  position: relative;
  display: inline-flex; }
  .ModalSign_ticketIcon__3pa6N strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.ModalSign_textOnButton__14Ce9 {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .ModalSign_textOnButton__14Ce9 span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.ModalSign_modalType__3IYeJ {
  background: var(--background-color) !important;
  max-width: 962px; }

.ModalSign_CloseBtn__2F5sy {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .ModalSign_CloseBtn__2F5sy svg {
    opacity: 1 !important; }
  .ModalSign_CloseBtn__2F5sy:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .ModalSign_CloseBtn__2F5sy:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .ModalSign_CloseBtn__2F5sy {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.ModalSign_inputWithCalendar__auyJW {
  position: relative; }
  .ModalSign_inputWithCalendar__auyJW input {
    padding-right: 20px; }
  .ModalSign_inputWithCalendar__auyJW svg,
  .ModalSign_inputWithCalendar__auyJW span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .ModalSign_inputWithCalendar__auyJW svg path,
    .ModalSign_inputWithCalendar__auyJW span path {
      fill: #8c8c8c; }

.ModalSign_text-orange__3JYpQ {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.ModalSign_color-orange__eG7OX {
  color: var(--accent-color) !important; }

.ModalSign_overlay-loader__1nyKt {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.ModalSign_rememberCheck__25y_I {
  display: flex;
  align-items: center; }
  .ModalSign_rememberCheck__25y_I label {
    margin-left: 10px;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ModalSign_signWithApp__MWfmT {
  display: flex;
  justify-content: center; }
  .ModalSign_signWithApp__MWfmT button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10em;
    justify-content: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03);
    -webkit-transition: box-shadow .35s ease;
    transition: box-shadow .35s ease; }
    .ModalSign_signWithApp__MWfmT button:hover, .ModalSign_signWithApp__MWfmT button:active {
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1); }

.ModalSign_fb__2Hgrb {
  background: #3B5998; }

.ModalSign_imageForm__3BI7- {
  position: absolute;
  left: calc(500px + 200px);
  top: 120px; }
  @media (max-width: 1350px) {
    .ModalSign_imageForm__3BI7- {
      right: 20px;
      z-index: 1;
      left: auto;
      opacity: .3; } }
  @media (max-width: 578px) {
    .ModalSign_imageForm__3BI7- {
      display: none; } }

.ModalSign_needHelp__2KK7M a {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: var(--accent-color); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding: 30px;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-overflow-style: none;
  overflow: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(2);
          transform: scale(2);
  visibility: hidden;
  opacity: 0;
  /* ONLY FOR MODAL SIGN*/ }
  .overlay-modal.show {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    visibility: visible; }
  @media (max-width: 576px) {
    .overlay-modal {
      padding: 0px; } }
  .overlay-modal::-webkit-scrollbar {
    width: 0; }
  .overlay-modal .modal {
    position: relative;
    width: 90%;
    max-width: 830px;
    margin: auto;
    height: auto;
    background: var(--background-color);
    border-radius: 2px;
    padding: 30px 60px; }
    @media (max-width: 568px) {
      .overlay-modal .modal {
        padding: 20px; } }
    .overlay-modal .modal.withImage {
      max-width: 1170px; }
    .overlay-modal .modal .btn-blue,
    .overlay-modal .modal .btn-grey {
      display: flex;
      align-items: center;
      margin: 0 auto; }
    .overlay-modal .modal h3 {
      text-align: center;
      padding: 20px;
      font-size: 22px; }
    .overlay-modal .modal .modal-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.2); }
    .overlay-modal .modal .modal-header {
      font-weight: 500;
      padding: 27px 0;
      text-align: center;
      font-size: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      color: #000; }
    .overlay-modal .modal .modal-main {
      text-align: left; }
    .overlay-modal .modal .close {
      top: 0;
      right: 0;
      position: absolute;
      opacity: 0.8;
      z-index: 100;
      color: #fff;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      -webkit-transform: translate(50%, -50%);
              transform: translate(50%, -50%); }
      @media (max-width: 578px) {
        .overlay-modal .modal .close {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0); } }
      .overlay-modal .modal .close:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        border-radius: 10em;
        width: 13px;
        height: 13px;
        background: #fff;
        z-index: -1; }
      .overlay-modal .modal .close:hover {
        opacity: 1; }
    @media (max-width: 576px) {
      .overlay-modal .modal {
        width: 100%;
        padding-top: 20px;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between; }
        .overlay-modal .modal .close {
          top: 0;
          right: 0px;
          font-size: 30px;
          color: #333; }
        .overlay-modal .modal .modal-header {
          padding: 15px 0; }
        .overlay-modal .modal .modal-main {
          padding: 20px; } }
    @media (max-width: 576px) and (max-width: 578px) {
      .overlay-modal .modal .modal-main {
        padding: 0; } }
    @media (max-width: 576px) {
        .overlay-modal .modal .modal .btn-blue {
          line-height: 43px;
          height: 40px; } }
  .overlay-modal .modal .modal-footer {
    text-align: center;
    border: none; }
  .overlay-modal .modal p {
    font-size: 16px; }
  .overlay-modal .modal .login-row {
    display: flex; }
  .overlay-modal .modal .checkbox-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .overlay-modal .modal .checkbox-field label {
      margin-left: 15px; }
  .overlay-modal .modal label svg path {
    fill: #8c8c8c;
    -webkit-transition: fill 0.35s ease;
    transition: fill 0.35s ease; }
  .overlay-modal .modal label div {
    position: relative; }
  .overlay-modal .modal label .password-field {
    position: relative; }
    .overlay-modal .modal label .password-field input {
      padding-right: 40px; }
    .overlay-modal .modal label .password-field.is-show svg path {
      fill: var(--accent-color); }
    .overlay-modal .modal label .password-field strong[role] {
      position: absolute;
      top: 50%;
      display: inline-flex;
      -webkit-transform: translateY(calc(-50% + 1px));
              transform: translateY(calc(-50% + 1px));
      right: 10px; }
  .overlay-modal .modal .passwordForm {
    color: rgba(0, 0, 0, 0.8); }
    .overlay-modal .modal .passwordForm h5 {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      margin: 20px 0; }
    .overlay-modal .modal .passwordForm label {
      text-align: left;
      display: block;
      margin-bottom: 20px; }
  .overlay-modal .modal .social {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    background-color: #4e71a8;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    margin-left: 20px; }
    .overlay-modal .modal .social.gp {
      background-color: #e3411f; }
    .overlay-modal .modal .social:hover {
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.9); }

.forgot {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: var(--accent-color);
  cursor: pointer; }

.wrap-remember {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 578px) {
    .wrap-remember {
      flex-direction: column;
      align-items: flex-start; }
      .wrap-remember button {
        margin-top: 20px; } }

.maxWidth500 {
  position: relative;
  z-index: 2;
  max-width: 500px; }
  @media (max-width: 992px) {
    .maxWidth500 {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 578px) {
    .maxWidth500 {
      max-width: 100%;
      width: 100%; } }

.agreeWithTerm {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #8c8c8c; }
  .agreeWithTerm a {
    color: var(--accent-color); }

.agreeWithTerm {
  display: flex; }
  .agreeWithTerm label {
    margin-left: 20px;
    margin-top: -5px; }

.widthAuto {
  width: auto !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.AgreementCookie_Component__2VmOn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 16px;
  padding: 8px 0;
  background: var(--background-color);
  z-index: 105;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s; }
  .AgreementCookie_Component__2VmOn p {
    font-size: 16px;
    margin: 0; }
    @media (max-width: 575.8px) {
      .AgreementCookie_Component__2VmOn p {
        font-size: 10px;
        line-height: 19px;
        margin-bottom: 10px;
        text-align: center; } }

.AgreementCookie_Component_hidden__jbd85 {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.AgreementCookie_Container__2zDkP {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 575.8px) {
    .AgreementCookie_Container__2zDkP {
      padding: 0 10px 0; } }
  @media (max-width: 575.8px) {
    .AgreementCookie_Container__2zDkP {
      flex-direction: column; } }

.AgreementCookie_Link__zlemc {
  text-decoration: underline;
  color: var(--accent-color); }
  @media (max-width: 575.8px) {
    .AgreementCookie_Link__zlemc {
      margin: 10px auto; } }

.AgreementCookie_Button__1yu6a {
  min-width: 135px;
  margin-left: 45px; }
  @media (max-width: 575.8px) {
    .AgreementCookie_Button__1yu6a {
      padding: 2px 0px;
      min-width: 124px;
      margin-left: 0px;
      font-size: 9px;
      line-height: 16px; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Button_Component__1nA8m {
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  line-height: 1.857em;
  font-size: 14px;
  padding: 4px 10px;
  min-width: 200px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  -webkit-transition: background 0.35s ease;
  transition: background 0.35s ease; }

.Button_Color-primary__Wp5-N, .Button_Color-primary-outline__9WP6h {
  color: #ffffff;
  background-color: var(--accent-color);
  border-color: var(--accent-color); }
  .Button_Color-primary__Wp5-N:hover, .Button_Color-primary-outline__9WP6h:hover {
    background: var(--accent-color);
    border-color: var(--accent-color); }
  .Button_Color-primary__Wp5-N:active, .Button_Color-primary-outline__9WP6h:active {
    background: #f2b39d;
    border-color: #f2b39d; }
  .Button_Color-primary__Wp5-N:disabled, .Button_Color-primary-outline__9WP6h:disabled {
    background: #8c8c8c;
    border: 2px solid #8c8c8c;
    cursor: not-allowed; }

.Button_Color-primary-light__2Glj9 {
  color: var(--accent-color);
  background-color: var(--light-accent-color);
  border-color: var(--light-accent-color);
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .Button_Color-primary-light__2Glj9:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

.Button_Color-primary-outline__9WP6h {
  background: #fff;
  color: var(--accent-color); }
  .Button_Color-primary-outline__9WP6h:active, .Button_Color-primary-outline__9WP6h:hover {
    background: #fff; }

.Button_Color-yellow__2cXuY, .Button_Color-yellow-outline__2vzGH {
  color: #575757;
  background-color: var(--accent-color);
  border-color: var(--accent-color); }

.Button_Color-yellow-outline__2vzGH {
  background: #fff; }
  .Button_Color-yellow-outline__2vzGH:hover {
    background: var(--accent-color);
    color: #ffffff; }
  .Button_Color-yellow-outline__2vzGH:active {
    background: #ffdbaf;
    color: #ffffff; }

.Button_TextTransform-uppercase__48L_K {
  text-transform: uppercase; }

.Button_TextTransform-lowercase__3sLFJ {
  text-transform: lowercase; }

.Button_TextTransform-capitalize__1idQT {
  text-transform: capitalize; }

@-webkit-keyframes Notification_Close__1-Stj {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  25% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; } }

@keyframes Notification_Close__1-Stj {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  25% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%); }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; } }

@-webkit-keyframes Notification_Progress__4t6hO {
  0% {
    width: 100%; }
  100% {
    width: 0; } }

@keyframes Notification_Progress__4t6hO {
  0% {
    width: 100%; }
  100% {
    width: 0; } }

.Notification_Notification__3G3dW {
  width: 300px;
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  font-size: 16px; }
  .Notification_Notification__3G3dW button, .Notification_Notification__3G3dW a {
    color: #ffffff;
    text-decoration: underline;
    padding: 0; }
  .Notification_Notification_info__1B6uM {
    background: #3498db; }
  .Notification_Notification_success__1hkDe {
    background: #07bc0c; }
  .Notification_Notification_warning__12iDv {
    background: #f1c40f; }
  .Notification_Notification_error__MQ_N4 {
    background: #e74c3c; }
  .Notification_Notification_default__GC-Bo {
    color: #222 !important;
    background: #ffffff; }
    .Notification_Notification_default__GC-Bo .Notification_NotificationClose__voZ12 {
      color: #222; }
  .Notification_Notification_close__2cMjY {
    -webkit-animation: Notification_Close__1-Stj 1s linear;
            animation: Notification_Close__1-Stj 1s linear; }
  .Notification_NotificationClose__voZ12 {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: #ffffff; }
  .Notification_NotificationProgress__1o6eE {
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-animation-name: Notification_Progress__4t6hO;
            animation-name: Notification_Progress__4t6hO;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
    background: rgba(255, 255, 255, 0.6); }

.Notifications_wrapNotification__3dXV7 {
  position: relative;
  z-index: 100005; }

.Notifications_Notifications__1iIRt {
  position: fixed;
  z-index: 10; }
  .Notifications_Notifications_top-right__1xolL {
    top: 1em;
    right: 1em; }
  .Notifications_Notifications_top-left__1vJ5h {
    top: 1em;
    left: 1em; }
  .Notifications_Notifications_top-center__3iEZN {
    top: 1em;
    left: calc(50%);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .Notifications_Notifications_bottom-right__1853s {
    right: 1em;
    bottom: 1em; }
  .Notifications_Notifications_bottom-left__3B4w1 {
    left: 1em;
    bottom: 1em; }
  .Notifications_Notifications_bottom-center__SUp2V {
    bottom: 1em;
    left: calc(50%);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.Preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  display: flex;
  z-index: 10000; }
  .Preloader img {
    margin: auto;
    height: auto;
    width: 213px; }
  .Preloader svg {
    margin: auto;
    height: auto;
    width: 160px;
    -webkit-animation: 1.4s infinite heart;
            animation: 1.4s infinite heart; }

@-webkit-keyframes heart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  40% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes heart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  40% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.wrap-table {
  width: 100%;
  background: #fff;
  border-radius: 2px; }

.table {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 1080px;
  color: #575757; }

.table-header {
  background: #e6e5e2;
  box-sizing: border-box;
  display: grid;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  grid-template-columns: 60px 3fr 2fr 1fr;
  grid-template-rows: 70px;
  grid-column-gap: 30px;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: flex-start;
  text-align: left;
  padding: 0 20px; }
  .table-header span:last-child {
    justify-self: center; }

.table-row {
  display: grid;
  padding: 0 20px;
  grid-template-rows: 70px;
  grid-template-columns: 60px 3fr 2fr 1fr;
  grid-column-gap: 30px;
  align-items: center;
  justify-content: flex-start;
  text-align: left; }
  .table-row span {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    text-transform: capitalize; }
    .table-row span a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #575757;
      text-transform: lowercase; }

.tableFull {
  grid-template-columns: 60px 3fr 2fr 1fr 170px; }

.block-action-wrap {
  position: relative; }

.action-area {
  position: relative; }
  .action-area .svg {
    display: inline-flex;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    left: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer; }

.block-action {
  padding-top: 20px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  -webkit-transform: translateY(calc(100% + 2px));
          transform: translateY(calc(100% + 2px));
  right: -80px; }
  .block-action.bottom {
    bottom: 30px;
    right: -60px; }
    .block-action.bottom .block-action-inner:after {
      right: 105px; }
  .block-action-inner {
    min-width: 309px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 10px rgba(222, 222, 222, 0.4);
    border-radius: 10.4939px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; }
    .block-action-inner:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 15px solid #fff;
      display: block;
      right: 155px;
      top: -12px;
      position: absolute; }
  .block-action button {
    font-style: normal;
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    padding-bottom: 13px; }
    .block-action button span {
      -webkit-transition: -webkit-transform 0.35s ease;
      transition: -webkit-transform 0.35s ease;
      transition: transform 0.35s ease;
      transition: transform 0.35s ease, -webkit-transform 0.35s ease;
      text-align: start;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); }
    .block-action button:last-child {
      padding-bottom: 0; }
    .block-action button svg {
      margin-right: 20px;
      max-width: 15px;
      flex-shrink: 0; }
    .block-action button svg path {
      fill: #575757;
      -webkit-transition: fill 0.35s ease;
      transition: fill 0.35s ease; }
    .block-action button svg.svgWhite circle {
      -webkit-transition: fill 0.35s ease;
      transition: fill 0.35s ease; }
    .block-action button svg.svgWhite path {
      fill: white; }
    .block-action button:hover span {
      color: #f24822 !important;
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
    .block-action button:hover svg path {
      fill: var(--accent-color); }
    .block-action button:hover svg.svgWhite circle {
      fill: var(--accent-color); }
    .block-action button:hover svg.svgWhite path {
      fill: white; }

.uploadFiles p {
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #8c8c8c; }

.height-block {
  height: 100px;
  display: block; }
  @media (max-width: 767.8px) {
    .height-block {
      height: 86px; } }
  @media (max-width: 575.8px) {
    .height-block {
      height: 58px; } }

.popup {
  display: flex;
  flex-direction: column;
  background: var(--background-color) !important;
  max-width: 1030px !important; }

.wrap-btn {
  display: flex;
  justify-content: center; }
  .wrap-btn button:last-child {
    margin-left: 20px; }
  @media (max-width: 575.8px) {
    .wrap-btn {
      flex-direction: column; }
      .wrap-btn button:last-child {
        margin-left: 0;
        margin-top: 20px; } }

.dropdown {
  position: relative; }

.react-add-to-calendar__dropdown {
  position: relative;
  display: flex;
  top: -23px;
  margin-bottom: -23px;
  background: #fff; }
  .react-add-to-calendar__dropdown a {
    color: var(--accent-color); }
    .react-add-to-calendar__dropdown a i {
      margin-right: 10px; }

.header-custom {
  z-index: 111 !important; }

@media (max-width: 575.8px) {
  .not-found-illustration {
    opacity: 0.3;
    width: 100% !important; } }

.tabs-control {
  display: flex;
  border-bottom: 2px solid var(--accent-color);
  margin-bottom: 50px; }
  @media (max-width: 767.8px) {
    .tabs-control {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      overflow: hidden;
      border-radius: 2px;
      border-bottom: 0; } }
  .tabs-control button {
    margin-bottom: 2px;
    position: relative;
    top: 2px;
    cursor: pointer;
    margin-right: 30px;
    flex-grow: 1;
    border-radius: 2px 2px 0 0;
    height: 70px;
    min-width: 170px;
    border: none;
    background: var(--light-accent-color);
    color: var(--accent-color);
    -webkit-transition: background 0.35s ease, color 0.35s ease;
    transition: background 0.35s ease, color 0.35s ease; }
    @media (max-width: 767.8px) {
      .tabs-control button {
        min-width: 100px;
        height: 45px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 0;
        margin: 0; } }
    .tabs-control button:last-child {
      margin-right: 0; }
    .tabs-control button.active, .tabs-control button:hover {
      background: var(--accent-color);
      color: #fff; }

.text-black {
  color: #333 !important; }
  .text-black button,
  .text-black a {
    color: #333 !important; }

.overflowH {
  overflow: hidden; }

.tabs-wrap {
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  overflow: hidden; }
  .tabs-wrap button {
    margin: 5px 10px 0 !important; }

.notSupported {
  background: red;
  color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1111;
  font-size: 24px;
  text-align: center;
  padding: 20px; }
  .notSupported a {
    text-decoration: underline;
    color: #000; }

.outline {
  position: relative; }
  .outline:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid var(--accent-color);
    border-radius: 2px; }

.remove-btn {
  margin-right: 0.5rem; }

.item-enter {
  opacity: 0; }

.item-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.item-exit {
  opacity: 1; }

.item-exit-active {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.fade {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 0; }

.entering {
  opacity: 1; }

.entered {
  opacity: 1; }

.exiting {
  opacity: 0; }

.exited {
  opacity: 0; }

.bg-pink {
  background: var(--background-color);
  min-height: 100vh;
  position: relative;
  box-sizing: border-box; }

.bottom-text-input {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c;
  margin-top: 10px; }
  @media (max-width: 575.8px) {
    .bottom-text-input {
      font-size: 14px;
      line-height: 18px; } }
  .bottom-text-input span {
    color: var(--accent-color);
    margin-right: 5px;
    position: relative;
    top: -2px;
    font-weight: normal; }

.btn-wrap {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 575.8px) {
    .btn-wrap {
      margin-top: 40px; } }
  .btn-wrap > button:last-child {
    margin-left: 20px; }

.upload-lists {
  display: flex;
  flex-direction: column; }
  .upload-lists .upload-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #575757;
    margin-left: 15px; }
    @media (max-width: 575.8px) {
      .upload-lists .upload-title {
        font-size: 14px;
        line-height: 26px;
        margin-left: 0; } }
  .upload-lists li {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 575.8px) {
      .upload-lists li {
        background: #ffffff;
        box-shadow: 0px 0px 27.4733px rgba(11, 12, 36, 0.04);
        border-radius: 12.6053px;
        padding: 10px 20px;
        flex-direction: column; } }
    @media (max-width: 575.8px) {
      .upload-lists li > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .upload-lists .upload-action span {
    cursor: pointer; }
  .upload-lists .upload-action span:last-child {
    margin-left: 20px; }
    @media (max-width: 575.8px) {
      .upload-lists .upload-action span:last-child {
        margin-left: 50px; } }

.list-empty {
  color: #8c8c8c;
  text-transform: uppercase; }

.password-field {
  position: relative; }
  .password-field svg path {
    fill: #8c8c8c; }
  .password-field input {
    padding-right: 40px; }
  .password-field.is-show svg path {
    fill: var(--accent-color); }
  .password-field strong[role],
  .password-field button {
    position: absolute;
    top: 50%;
    display: inline-flex;
    -webkit-transform: translateY(calc(-50% + 1px));
            transform: translateY(calc(-50% + 1px));
    right: 10px; }

.custom-modal {
  display: inline-flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%; }

.custom-modal-inner {
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: 1170px;
  overflow-y: auto;
  padding: 0 90px;
  background: var(--background-color);
  border-radius: 2px;
  margin-right: auto;
  margin-left: auto; }
  .custom-modal-inner::-webkit-scrollbar {
    width: 10px; }
  .custom-modal-inner::-webkit-scrollbar-track {
    background: #e6e5e2; }
  .custom-modal-inner::-webkit-scrollbar-thumb {
    background-color: #8c8c8c;
    outline: 1px solid transparent; }
  .custom-modal-inner input::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::-moz-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::-ms-input-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  @media (max-width: 768px) {
    .custom-modal-inner {
      padding: 0 20px; } }

.BreadCrumbs_wrap-crumbs__QThb_ {
  padding: 30px 0; }

.BreadCrumbs_crumbs__3gvQj a {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #8C8C8C;
  -webkit-transition: color .35s ease;
  transition: color .35s ease; }
  .BreadCrumbs_crumbs__3gvQj a:hover {
    color: var(--accent-color); }

.BreadCrumbs_crumbs__3gvQj span {
  display: inline-flex;
  margin: 0 10px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.SubscribeForm_Form__1MChJ {
  margin-bottom: 35px; }
  @media (max-width: 767.8px) {
    .SubscribeForm_Form__1MChJ {
      border-top: 1px solid rgba(140, 140, 140, 0.5);
      padding: 8px 10px 0; } }
  .SubscribeForm_Form__1MChJ .SubscribeForm_FormHeader__KN_Av {
    text-transform: none;
    margin-bottom: 27px; }
    @media (max-width: 767.8px) {
      .SubscribeForm_Form__1MChJ .SubscribeForm_FormHeader__KN_Av {
        margin-bottom: 10px; } }
  .SubscribeForm_Form__1MChJ p {
    line-height: 21px;
    font-size: 16px;
    color: #575757;
    font-weight: 600; }
  .SubscribeForm_FormControl__2AbhN {
    display: flex;
    border-radius: 2px;
    overflow: hidden;
    height: 45px; }
    .SubscribeForm_FormControl__2AbhN input {
      background: var(--light-accent-color);
      padding: 12px 20px;
      line-height: 21px;
      font-size: 16px;
      height: 100%;
      border-radius: 2px;
      margin: 0;
      font-weight: 600;
      border-color: transparent;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px; }
    .SubscribeForm_FormControl__2AbhN button {
      height: 100%;
      margin: 0;
      border-radius: 0;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--accent-color);
      border: 2px solid var(--accent-color); }
      .SubscribeForm_FormControl__2AbhN button:hover {
        -webkit-filter: saturate(120%);
                filter: saturate(120%); }
      .SubscribeForm_FormControl__2AbhN button[disabled], .SubscribeForm_FormControl__2AbhN button:active {
        opacity: 0.7; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Footer_Component__11mH7 {
  background: var(--background-color); }
  .Footer_Component_white_bg__3elfv {
    background: #fff; }

.Footer_Container__10u6u {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  @media (max-width: 575.8px) {
    .Footer_Container__10u6u {
      padding: 0 10px 0; } }
  @media (max-width: 1199.8px) {
    .Footer_Container__10u6u {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767.8px) {
    .Footer_Container__10u6u {
      display: block;
      padding: 0; } }

.Footer_Main__9IoFa {
  border-bottom: 1px solid rgba(140, 140, 140, 0.5);
  padding-bottom: 33px;
  padding-top: 78px; }
  @media (max-width: 1023.8px) {
    .Footer_Main__9IoFa {
      padding-top: 40px;
      padding-bottom: 5px; } }
  @media (max-width: 767.8px) {
    .Footer_Main__9IoFa {
      padding-top: 12px; } }
  .Footer_Main__9IoFa h4,
  .Footer_Main__9IoFa h4 a,
  .Footer_Main__9IoFa a,
  .Footer_Main__9IoFa address {
    color: #575757;
    line-height: 28px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px; }
  .Footer_Main__9IoFa a {
    text-transform: none;
    margin-bottom: 10px;
    border-bottom: 1px solid transparent;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .Footer_Main__9IoFa a:hover {
      color: var(--accent-color);
      border-bottom-color: var(--accent-color); }
  .Footer_Main__9IoFa address {
    text-transform: none;
    margin-bottom: 10px; }
    .Footer_Main__9IoFa address span {
      display: block;
      margin-bottom: 20px; }

.Footer_Socials__2Vfxv {
  display: flex;
  align-items: center; }
  @media (max-width: 767.8px) {
    .Footer_Socials__2Vfxv {
      justify-content: center; } }
  .Footer_Socials__2Vfxv a {
    margin-right: 35px;
    border-bottom: none; }
    .Footer_Socials__2Vfxv a:last-child {
      margin-right: 0; }
    .Footer_Socials__2Vfxv a svg path {
      -webkit-transition: 0.2s;
      transition: 0.2s; }
    .Footer_Socials__2Vfxv a:hover svg path {
      fill-opacity: 1; }
    @media (max-width: 767.8px) {
      .Footer_Socials__2Vfxv a {
        margin-bottom: 0; } }

.Footer_Additional__1qgks {
  padding: 35px 15px 35px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 575.8px) {
    .Footer_Additional__1qgks {
      padding: 35px 10px 35px; } }
  @media (max-width: 1023.8px) {
    .Footer_Additional__1qgks {
      padding-top: 15px;
      padding-bottom: 15px; } }
  @media (max-width: 767.8px) {
    .Footer_Additional__1qgks {
      flex-direction: column; } }
  .Footer_Additional__1qgks p {
    line-height: 1.7em;
    font-size: 16px;
    color: #575757; }
    @media (max-width: 767.8px) {
      .Footer_Additional__1qgks p {
        font-weight: 600;
        font-size: 14px; } }

.Footer_PaymentSystems__2q44W {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767.8px) {
    .Footer_PaymentSystems__2q44W {
      width: 100%; } }
  .Footer_PaymentSystems__2q44W img {
    margin-right: 50px; }
    @media (max-width: 767.8px) {
      .Footer_PaymentSystems__2q44W img {
        margin-right: 0;
        flex-basis: 33.33%; } }

.Footer_Contacts__2_mVH svg {
  display: inline-block;
  margin-right: 12px;
  width: 15px;
  height: 15px; }

@media (max-width: 1023.8px) {
  .Footer_Contacts__2_mVH {
    font-size: 14px; } }

@media (max-width: 767.8px) {
  .Footer_Contacts__2_mVH {
    padding: 10px; }
    .Footer_Contacts__2_mVH h4 {
      display: none; } }

@media (max-width: 767.8px) {
  .Footer_Nav__3kTjt {
    padding-left: 11px; } }

@media (max-width: 1199.8px) {
  .Footer_Footer-custom__3qPgE {
    display: block;
    padding: 0 10px; } }

.Footer_MadeBy__3g_co {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px; }
  .Footer_MadeBy__3g_co p {
    font-size: 14px;
    line-height: 100%;
    color: #575757; }

.Footer_MadeByLogo__2-nDT {
  margin-right: 10%;
  display: block;
  cursor: pointer; }

.Footer_MadeByLogo__2-nDT {
  display: flex;
  align-items: baseline;
  grid-gap: 5px;
  gap: 5px;
  font-size: 14px;
  color: #757e96; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.LanguageSwitcher_Container__36neI {
  position: relative; }
  .LanguageSwitcher_Container__36neI button {
    font-style: normal;
    padding: 0 !important;
    margin: 0 !important;
    font-weight: normal;
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100% !important;
    padding-left: 15px !important; }
    .LanguageSwitcher_Container__36neI button:last-child {
      margin-bottom: 0; }
  .LanguageSwitcher_Container__36neI svg {
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    margin-right: 7px;
    margin-bottom: 1px;
    height: 8px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.LanguageSwitcher_Open__2mzfn svg {
  margin-right: 5px;
  height: 8px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.LanguageSwitcher_Open__2mzfn .LanguageSwitcher_List__27oEY {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.LanguageSwitcher_List__27oEY {
  position: absolute;
  top: 33px;
  right: 0;
  overflow: hidden;
  -webkit-transition: opacity .32s ease, -webkit-transform .32s ease;
  transition: opacity .32s ease, -webkit-transform .32s ease;
  transition: opacity .32s ease, transform .32s ease;
  transition: opacity .32s ease, transform .32s ease, -webkit-transform .32s ease;
  width: 130px;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  opacity: 0;
  background: #fff;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px; }
  @media (max-width: 1199.8px) {
    .LanguageSwitcher_List__27oEY {
      right: auto;
      left: 0; } }
  .LanguageSwitcher_List__27oEY li {
    width: 100%;
    -webkit-transition: background .35s ease;
    transition: background .35s ease;
    padding: 4px 15px; }
    .LanguageSwitcher_List__27oEY li button {
      padding-left: 0 !important; }
    .LanguageSwitcher_List__27oEY li:hover {
      background: #fff2de; }
    .LanguageSwitcher_List__27oEY li:active {
      background: rgba(255, 242, 222, 0.7); }
  .LanguageSwitcher_List__27oEY li:not(:last-child) {
    margin-bottom: 5px; }

.LanguageSwitcher_ml__3ih40 {
  margin-right: 0;
  margin-left: 70px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Button_Component__7sNW-:disabled {
  opacity: 0.8;
  cursor: not-allowed; }

.Button_btn-green__2664f {
  background: #4caf50;
  border-radius: 2px;
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 auto; }
  @media (max-width: 575.8px) {
    .Button_btn-green__2664f {
      width: 100%; } }

.Button_btn-green__2664f:disabled {
  background: #8C8C8C;
  cursor: not-allowed; }

.Button_btn-green__2664f:disabled:hover, .Button_btn-green__2664f:disabled:active {
  background: rgba(140, 140, 140, 0.7); }

.Button_btn-green__2664f:hover {
  background: rgba(76, 175, 80, 0.7); }

.Button_btn-green__2664f:active {
  background: rgba(76, 175, 80, 0.5); }

.HeaderSetting_wrap-setting__lZ9-9 {
  border-top: 1px solid rgba(151, 151, 151, 0.26);
  margin-top: 5px;
  padding: 10px 0;
  margin-bottom: -10px;
  position: relative; }
  @media (max-width: 768px) {
    .HeaderSetting_wrap-setting__lZ9-9 {
      padding: 5px 0 5px; } }

.HeaderSetting_items__3mSo- {
  display: flex;
  justify-content: space-between; }

.HeaderSetting_item__2LZGx {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .HeaderSetting_item__2LZGx > span {
    margin: 0 15px; }

.HeaderSetting_wrap-item__g0h3W {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%; }
  @media (max-width: 1200px) {
    .HeaderSetting_wrap-item__g0h3W {
      padding-right: 40px; } }

.HeaderSetting_nav__27U4- {
  flex-grow: 1;
  display: flex;
  font-size: 13px;
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  align-items: center; }
  .HeaderSetting_nav__27U4-:hover span {
    color: var(--accent-color); }
  .HeaderSetting_nav__27U4-:hover svg {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .HeaderSetting_nav__27U4-:hover path {
    fill: var(--accent-color); }
  .HeaderSetting_nav__27U4- span {
    color: #8C8C8C;
    -webkit-transition: all .35s ease;
    transition: all .35s ease; }
  .HeaderSetting_nav__27U4- svg {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: all .35s;
    transition: all .35s;
    margin-right: 10px; }
  .HeaderSetting_nav__27U4- path {
    -webkit-transition: fill .35s ease;
    transition: fill .35s ease; }
  @media (max-width: 998px) {
    .HeaderSetting_nav__27U4- {
      flex-direction: column-reverse;
      justify-content: flex-end;
      font-size: 12px; }
      .HeaderSetting_nav__27U4- svg {
        min-height: 30px;
        align-items: center;
        display: flex; }
      .HeaderSetting_nav__27U4- > span {
        display: none; } }

.HeaderSetting_active__37vEm span {
  color: var(--accent-color); }

.HeaderSetting_active__37vEm svg {
  -webkit-transform: scale(1);
          transform: scale(1); }

.HeaderSetting_active__37vEm path {
  fill: var(--accent-color); }

.HeaderSetting_icon__3K3jP {
  display: flex;
  align-items: center;
  flex-shrink: 0; }

.HeaderSetting_publish__3ZoYx {
  position: absolute;
  top: 0;
  right: 20px;
  height: 44px;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #4CAF50 !important;
  cursor: pointer; }
  .HeaderSetting_publish__3ZoYx:hover {
    color: #fff;
    background: #4CAF50; }
    .HeaderSetting_publish__3ZoYx:hover > span {
      opacity: 1;
      -webkit-transition: opacity 0.1s ease, -webkit-transform .09s ease;
      transition: opacity 0.1s ease, -webkit-transform .09s ease;
      transition: transform .09s ease, opacity 0.1s ease;
      transition: transform .09s ease, opacity 0.1s ease, -webkit-transform .09s ease;
      -webkit-transform: translateX(-100%) scale(1);
              transform: translateX(-100%) scale(1); }
  .HeaderSetting_publish__3ZoYx > span {
    display: flex;
    color: #fff;
    background: #4CAF50;
    height: 44px;
    position: absolute;
    -webkit-transform: translateX(0) scale(0.1);
            transform: translateX(0) scale(0.1);
    left: 0;
    opacity: 0;
    align-items: center;
    white-space: nowrap;
    padding: 0 20px; }
  .HeaderSetting_publish__3ZoYx svg {
    flex-shrink: 0; }
  @media (max-width: 1200px) {
    .HeaderSetting_publish__3ZoYx {
      right: 0; } }
  @media (max-width: 998px) {
    .HeaderSetting_publish__3ZoYx {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .HeaderSetting_publish__3ZoYx > span {
        display: none; } }
  @media (max-width: 768px) {
    .HeaderSetting_publish__3ZoYx {
      height: 41px;
      width: 41px; } }
  @media (max-width: 576px) {
    .HeaderSetting_publish__3ZoYx {
      width: 35px;
      height: 35px; } }

.HeaderSetting_draft__1Y16E {
  background: var(--accent-color) !important; }
  .HeaderSetting_draft__1Y16E .HeaderSetting_publish-text__37VUE {
    background: var(--accent-color); }
  .HeaderSetting_draft__1Y16E svg circle {
    fill: var(--accent-color); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Navbar_Container__2TAEu {
  background: var(--background-color) url(/static/media/bg-navbar.f8929b1e.svg) center no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translateX(140%);
          transform: translateX(140%);
  -webkit-transition: 0.6s;
  transition: 0.6s; }

.Navbar_Open__1oun2 {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.Navbar_LanguageSwitcher__2bEPY {
  z-index: 1;
  position: absolute !important;
  top: 25px;
  left: 20px; }
  .Navbar_LanguageSwitcher__2bEPY ul {
    margin-top: 10px;
    background: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column; }
    .Navbar_LanguageSwitcher__2bEPY ul li {
      display: flex;
      justify-content: flex-end; }
    .Navbar_LanguageSwitcher__2bEPY ul button {
      margin: 0; }

.Navbar_CloseBtn__3HPDh {
  position: absolute;
  top: 20px;
  right: 20px; }

.Navbar_Nav__mg1uk a, .Navbar_Nav__mg1uk button {
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  color: #575757;
  display: block;
  margin: 0 auto 20px; }
  .Navbar_Nav__mg1uk a:last-child, .Navbar_Nav__mg1uk button:last-child {
    margin-bottom: 0; }

@media (max-width: 1199.8px) {
  .Navbar_Nav__mg1uk a {
    line-height: 30px; } }

.Navbar_Nav__mg1uk button {
  color: #ffffff !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.HeaderCustom_header-btns__1SlQ0 button,
.HeaderCustom_header-btns__1SlQ0 a {
  color: #ffffff;
  background: var(--accent-color);
  border-radius: 2px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .HeaderCustom_header-btns__1SlQ0 button:hover,
  .HeaderCustom_header-btns__1SlQ0 a:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

@media (max-width: 1199.8px) {
  .HeaderCustom_header-btns__1SlQ0 {
    display: none; } }

.HeaderCustom_nameShort__1b3Qo {
  display: none; }

.HeaderCustom_User__2iVpf {
  display: flex;
  align-items: center;
  min-width: 240px; }
  .HeaderCustom_UserIcon__32px1 {
    flex-shrink: 0;
    border-radius: 10em;
    margin-right: 20px;
    background-color: #8c8c8c;
    color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px; }
  @media (max-width: 1199.8px) {
    .HeaderCustom_User__2iVpf {
      position: absolute;
      right: 100px; } }
  @media (max-width: 767.8px) {
    .HeaderCustom_User__2iVpf {
      right: 50px;
      font-size: 14px;
      min-width: auto; }
      .HeaderCustom_UserIcon__32px1 {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        font-size: 14px; }
      .HeaderCustom_User__2iVpf .HeaderCustom_nameShort__1b3Qo {
        display: inline-block; }
      .HeaderCustom_User__2iVpf .HeaderCustom_nameFull__2VwMr {
        display: none; } }

.HeaderCustom_Component__HlqNY {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  z-index: 20;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 767.8px) {
    .HeaderCustom_Component__HlqNY {
      padding: 18px 0; } }

.HeaderCustom_Shadow__3WO-A {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }

.HeaderCustom_Container__2emCI {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  z-index: 2; }
  @media (max-width: 575.8px) {
    .HeaderCustom_Container__2emCI {
      padding: 0 10px 0; } }

.HeaderCustom_Logo__1LPuG {
  cursor: pointer;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }
  @media (max-width: 1439.8px) {
    .HeaderCustom_Logo__1LPuG {
      flex-basis: unset; } }
  @media (max-width: 767.8px) {
    .HeaderCustom_Logo__1LPuG svg {
      height: 20px;
      width: auto; } }

.HeaderCustom_SidebarBtn__KGKUb {
  display: none; }
  @media (max-width: 1199.8px) {
    .HeaderCustom_SidebarBtn__KGKUb {
      display: block; }
      .HeaderCustom_SidebarBtn__KGKUb svg {
        height: 20px;
        width: auto; } }
  @media (max-width: 767.8px) {
    .HeaderCustom_SidebarBtn__KGKUb {
      margin-left: 30px; }
      .HeaderCustom_SidebarBtn__KGKUb svg {
        height: auto; } }

.HeaderCustom_Nav__3XTzO {
  text-align: center;
  display: flex; }
  .HeaderCustom_Nav__3XTzO a {
    background: transparent;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    color: #575757;
    flex-shrink: 0;
    line-height: 28px;
    margin-right: 40px;
    font-size: 16px;
    border-bottom: 1px solid transparent; }
    @media (max-width: 1199.8px) {
      .HeaderCustom_Nav__3XTzO a {
        line-height: 30px !important; } }
    .HeaderCustom_Nav__3XTzO a:first-child {
      margin-left: 30px; }
    .HeaderCustom_Nav__3XTzO a:hover {
      background: var(--light-accent-color);
      color: var(--accent-color); }
    .HeaderCustom_Nav__3XTzO a:active {
      background: var(--accent-color);
      color: var(--background-color); }
  @media (max-width: 1199.8px) {
    .HeaderCustom_Nav__3XTzO {
      display: none; } }

.HeaderCustom_ml__2zuEG {
  margin-left: 250px;
  margin-right: 0; }

.HeaderCustom_LanguageSwitcher__2IVIK ul {
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column; }
  .HeaderCustom_LanguageSwitcher__2IVIK ul li {
    display: flex;
    justify-content: flex-end; }
  .HeaderCustom_LanguageSwitcher__2IVIK ul button {
    margin: 0; }

.HeaderCustom_LanguageSwitcher__2IVIK button:first-child {
  margin-top: 0; }

.HeaderCustom_Actions__1IqJc {
  display: flex;
  margin-right: 60px;
  align-items: center;
  width: 55px; }

@media (max-width: 1199.8px) {
  .HeaderCustom_LanguageSwitcher__2IVIK {
    display: none; }
  .HeaderCustom_Actions__1IqJc {
    margin-right: 20px; } }
  @media (max-width: 1199.8px) and (max-width: 767.8px) {
    .HeaderCustom_Actions__1IqJc {
      margin-right: 8px; } }

.HeaderCustom_header-not-found__MkjYu {
  background-color: var(--background-color);
  box-shadow: none; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.NotFound_wrap-static__2S1_d {
  background: var(--background-color);
  overflow: hidden; }
  .NotFound_wrap-static__2S1_d header {
    z-index: 20 !important; }

.NotFound_wrap-box__31Rvb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
  min-height: 700px; }
  @media (max-width: 575.8px) {
    .NotFound_wrap-box__31Rvb {
      min-height: 600px; } }

.NotFound_box__3k0fA {
  margin: 0 auto;
  position: relative; }

.NotFound_content__3MVCK {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(80px) translateY(-30px);
          transform: translateX(80px) translateY(-30px);
  max-width: 202px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #575757; }
  @media (max-width: 575.8px) {
    .NotFound_content__3MVCK {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      width: 230px;
      text-align: center; } }
  .NotFound_content__3MVCK strong {
    color: #f1635c; }
  .NotFound_content__3MVCK a,
  .NotFound_content__3MVCK button {
    width: 129px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    background: #4f4f4f;
    border-radius: 2px;
    margin-bottom: 10px;
    color: #ffffff;
    -webkit-transition: background 0.35s ease;
    transition: background 0.35s ease; }
    @media (max-width: 575.8px) {
      .NotFound_content__3MVCK a,
      .NotFound_content__3MVCK button {
        margin-left: auto;
        margin-right: auto; } }
    .NotFound_content__3MVCK a:hover,
    .NotFound_content__3MVCK button:hover {
      background: rgba(79, 79, 79, 0.7); }
    .NotFound_content__3MVCK a:active,
    .NotFound_content__3MVCK button:active {
      background: rgba(79, 79, 79, 0.5); }

.NotFound_empty__FnYMg {
  position: relative;
  left: auto;
  top: auto;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Button_custom-btn__3w0wk {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: opacity .35s ease;
  transition: opacity .35s ease;
  color: #fff; }
  .Button_custom-btn__3w0wk:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important; }
  .Button_custom-btn__3w0wk:hover {
    opacity: .7; }
  .Button_custom-btn__3w0wk:active {
    opacity: .5; }
  @media (max-width: 575.8px) {
    .Button_custom-btn__3w0wk {
      width: 100% !important; } }

.Button_Main__1B0gM {
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 0 10px;
  color: #fff; }
  .Button_Main-big__1qoJp {
    border-radius: 27px;
    padding: 12px 40px;
    min-height: 55px; }
  .Button_Main-medium__2XBdU {
    border-radius: 24px;
    padding: 10px 30px;
    min-height: 48px;
    font-size: 18px; }
  .Button_Main-small__ywcFT {
    border-radius: 21px;
    padding: 10px 20px 8px;
    min-height: 42px;
    font-size: 16px; }
  .Button_Main-rounded__1ytEr {
    border-radius: 50%; }
  .Button_Main-primary__2_KHY {
    background: #0078ff; }
    .Button_Main-primary__2_KHY[disabled] {
      background: #99c9ff; }
  .Button_Main-danger__2BU6x {
    background: #E50000; }
    .Button_Main-danger__2BU6x[disabled] {
      background: #ff7f7f; }
  .Button_Main-warning__164rz {
    background: #ffc107; }
    .Button_Main-warning__164rz[disabled] {
      background: #ffe7a0; }
  .Button_Main-grey__22QUu {
    background: #d9d9d9;
    color: #333333; }
    .Button_Main-grey__22QUu[disabled] {
      background: white; }
  .Button_Main-link__2PeQk {
    background: transparent; }
    .Button_Main-link__2PeQk[disabled] {
      color: #99c9ff; }

.Button_loading__2-5R4:disabled {
  background: transparent !important; }

.ModalWrapper_Overlay__2CK6J {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding: 30px;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-overflow-style: none;
  overflow: auto; }
  .ModalWrapper_Overlay-enter__2M_Pc {
    opacity: 0.01; }
    .ModalWrapper_Overlay-enter__2M_Pc .ModalWrapper_Modal__3mI6C {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0; }
  .ModalWrapper_Overlay-enter-active__2dYh6 {
    opacity: 1;
    -webkit-transition: 300ms ease-in;
    transition: 300ms ease-in; }
    .ModalWrapper_Overlay-enter-active__2dYh6 .ModalWrapper_Modal__3mI6C {
      -webkit-transition-delay: 100ms;
              transition-delay: 100ms;
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0; }
  .ModalWrapper_Overlay-exit__3XlIn {
    opacity: 1; }
  .ModalWrapper_Overlay-exit-active__1q6zx {
    opacity: 0.01;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out; }
  @media (max-width: 576px) {
    .ModalWrapper_Overlay__2CK6J {
      padding: 0px; } }
  .ModalWrapper_Overlay__2CK6J::-webkit-scrollbar {
    width: 0; }

.ModalWrapper_Modal__3mI6C {
  position: relative;
  width: 90%;
  max-width: 700px;
  margin: auto;
  height: auto;
  background: #fff;
  padding: 20px 40px 40px;
  border-radius: 2px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .ModalWrapper_Modal__3mI6C strong {
    color: var(--accent-color);
    font-weight: 600; }
  .ModalWrapper_Modal__3mI6C a {
    font-family: var(--font-family);
    font-style: italic;
    font-size: 16px;
    line-height: 21px;
    color: var(--accent-color); }
  @media (max-width: 768px) {
    .ModalWrapper_Modal__3mI6C {
      width: 100%; } }
  @media (max-width: 576px) {
    .ModalWrapper_Modal__3mI6C {
      padding-top: 20px;
      max-width: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Dashboard_Transition-enter__3fM6y {
  opacity: 0.01; }

.Dashboard_Transition-enter-active__23k_1 {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.Dashboard_Transition-exit__1eEaP {
  opacity: 1; }

.Dashboard_Transition-exit-active__1p2I5 {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.Dashboard_italic__1RPoR {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.Dashboard_timePickerMaxIndex__39-DM {
  z-index: 999999 !important; }

@-webkit-keyframes Dashboard_pulse_animation__3vNuG {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Dashboard_pulse_animation__3vNuG {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Dashboard_pulse-shadow__2fJuw {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes Dashboard_pulse-shadow__2fJuw {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.Dashboard_pulse-icon__3pexn {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: Dashboard_pulse-shadow__2fJuw 2s infinite;
          animation: Dashboard_pulse-shadow__2fJuw 2s infinite; }
  .Dashboard_pulse-icon__3pexn:hover {
    -webkit-animation: none;
            animation: none; }

.Dashboard_warning__2XuS4 {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .Dashboard_warning__2XuS4 p {
    color: #fff; }
  .Dashboard_warning__2XuS4.Dashboard_error__3wHG8 {
    background: rgba(252, 68, 88, 0.5) !important; }

.Dashboard_pulse__1OjsT span,
.Dashboard_pulse__1OjsT path {
  -webkit-animation-name: Dashboard_pulse_animation__3vNuG;
          animation-name: Dashboard_pulse_animation__3vNuG;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.Dashboard_custom-btn__2Njld {
  overflow: hidden;
  position: relative; }

.Dashboard_custom-btn__2Njld:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.Dashboard_custom-btn__2Njld:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: Dashboard_slideme__i8c-8;
  animation-name: Dashboard_slideme__i8c-8;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes Dashboard_slideme__i8c-8 {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.Dashboard_wrap-select-custom__2KcC_ {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .Dashboard_wrap-select-custom__2KcC_ input {
    height: 35px !important;
    margin-top: 0; }
  .Dashboard_wrap-select-custom__2KcC_ span {
    right: 0px; }

.Dashboard_opacity0__3u7d3 {
  opacity: 0 !important; }

.Dashboard_titleWithSelect__25D82 {
  display: flex;
  align-items: center; }
  .Dashboard_titleWithSelect__25D82 span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.Dashboard_require__1EO4w {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.Dashboard_container70__1Xo4R {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .Dashboard_container70__1Xo4R {
      padding: 0; } }

.Dashboard_color-green__2auUu {
  color: #4caf50; }

.Dashboard_justify-center__3MRzy {
  display: flex;
  flex-direction: column;
  align-items: center; }

.Dashboard_content-center__2QMJl {
  display: flex;
  align-items: center;
  justify-content: center; }

.Dashboard_max969__1Svw2 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.Dashboard_fz2550__2DOVc {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.Dashboard_fz2530__3S6rZ {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.Dashboard_text-left__1fC9J {
  text-align: left !important; }

.Dashboard_fz1830__YkTY1 {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_fz1830__YkTY1 {
      font-size: 16px;
      line-height: 26px; } }

.Dashboard_fz1628__2b5l0 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .Dashboard_fz1628__2b5l0 {
      font-size: 14px;
      line-height: 28px; } }
  .Dashboard_fz1628__2b5l0 p,
  .Dashboard_fz1628__2b5l0 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .Dashboard_fz1628__2b5l0 p,
      .Dashboard_fz1628__2b5l0 a {
        font-size: 14px;
        line-height: 28px; } }

.Dashboard_fz1426__dxOQ- {
  font-size: 14px;
  line-height: 26px; }

.Dashboard_fw600__3cYKx {
  font-weight: 600; }

.Dashboard_fw799__31bB4 {
  font-weight: 700; }

.Dashboard_c57__3R4vO {
  color: #575757; }

.Dashboard_btn-orange__1N0t4 {
  color: var(--accent-color);
  cursor: pointer; }

.Dashboard_ml10__3m54r {
  margin-left: 10px; }

.Dashboard_mr10__1Kpjh {
  margin-right: 10px; }

.Dashboard_ml20__2rP7V {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_ml20__2rP7V {
      margin-left: 15px; } }

.Dashboard_mr20__37mnf {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_mr20__37mnf {
      margin-right: 15px; } }

.Dashboard_ml30__2FESJ {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_ml30__2FESJ {
      margin-left: 20px; } }

.Dashboard_mr30__2xqdm {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_mr30__2xqdm {
      margin-right: 20px; } }

.Dashboard_mt30__2SIBR {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_mt30__2SIBR {
      margin-top: 15px; } }

.Dashboard_mb30__9dGrx {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_mb30__9dGrx {
      margin-bottom: 15px; } }

.Dashboard_mt50__15nbI {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .Dashboard_mt50__15nbI {
      margin-top: 20px; } }

.Dashboard_mt50i__pSBLc {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .Dashboard_mt50i__pSBLc {
      margin-top: 20px !important; } }

.Dashboard_mb50__1MdgU {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .Dashboard_mb50__1MdgU {
      margin-bottom: 20px; } }

.Dashboard_mt20__1CtoP {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_mt20__1CtoP {
      margin-top: 15px; } }

.Dashboard_mb20__1IF80 {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_mb20__1IF80 {
      margin-bottom: 15px; } }

.Dashboard_mb5__2y9db {
  margin-bottom: 5px !important; }

.Dashboard_mt5__30Qcm {
  margin-top: 5px !important; }

.Dashboard_mb0__1DQhu {
  margin-bottom: 0 !important; }

.Dashboard_mt0__19fuk {
  margin-top: 0 !important;
  flex-grow: 1; }

.Dashboard_mt-15__69x9W {
  margin-top: -15px !important; }

.Dashboard_w100__LyxjV {
  width: 100% !important; }

.Dashboard_pt20__37CWC {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_pt20__37CWC {
      padding-top: 15px; } }

.Dashboard_pb20__bXexn {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_pb20__bXexn {
      padding-bottom: 15px; } }

.Dashboard_p0__IFC0Z {
  padding: 0 !important; }

.Dashboard_m_auto__x7uVr {
  margin-right: auto;
  margin-left: auto; }

.Dashboard_pt50__2MJuB {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .Dashboard_pt50__2MJuB {
      padding-top: 20px; } }

.Dashboard_pt30__32w6o {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_pt30__32w6o {
      padding-top: 15px; } }

.Dashboard_pb100__1YHRc {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .Dashboard_pb100__1YHRc {
      padding-bottom: 50px; } }

.Dashboard_pb50__W6e0t {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .Dashboard_pb50__W6e0t {
      padding-bottom: 20px; } }

.Dashboard_pb30__20Cts {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .Dashboard_pb30__20Cts {
      padding-bottom: 15px; } }

.Dashboard_title-invent__2HPhm {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Dashboard_title-invent__2HPhm {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.Dashboard_description-invent__338E0 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .Dashboard_description-invent__338E0 a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .Dashboard_description-invent__338E0 {
      font-size: 14px;
      line-height: 28px; } }

.Dashboard_caption__37DCl {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .Dashboard_caption__37DCl b {
    display: flex;
    margin-top: -5px; }
    .Dashboard_caption__37DCl b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .Dashboard_caption__37DCl {
      font-size: 22px;
      line-height: 30px; } }

.Dashboard_wrap-loader-overlay__ZDeXQ {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.Dashboard_wrap-loader__1bi7D {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .Dashboard_wrap-loader__1bi7D svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.Dashboard_btn-green__1LDm8 {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .Dashboard_btn-green__1LDm8 {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.Dashboard_btn-green__1LDm8:hover {
  background: rgba(76, 175, 80, 0.7); }

.Dashboard_btn-green__1LDm8:active {
  background: rgba(76, 175, 80, 0.5); }

.Dashboard_button-orange__2CPvW {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .Dashboard_button-orange__2CPvW:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .Dashboard_button-orange__2CPvW:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.Dashboard_button-orange-light__1YxZT {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .Dashboard_button-orange-light__1YxZT:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.Dashboard_fz1728__3ze55 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.Dashboard_c8c__3RFTl {
  color: #8c8c8c; }

.Dashboard_space-between__10QLh {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Dashboard_relative__TdJJu {
  position: relative; }

.Dashboard_scroll__3ge9U {
  overflow-y: scroll !important; }
  .Dashboard_scroll__3ge9U::-webkit-scrollbar {
    width: 2px; }
  .Dashboard_scroll__3ge9U::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .Dashboard_scroll__3ge9U::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.Dashboard_ticketIcon__3aRry {
  position: relative;
  display: inline-flex; }
  .Dashboard_ticketIcon__3aRry strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.Dashboard_textOnButton__1J0SK {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .Dashboard_textOnButton__1J0SK span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.Dashboard_modalType__2iAyC {
  background: var(--background-color) !important;
  max-width: 962px; }

.Dashboard_CloseBtn__2FqoC {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .Dashboard_CloseBtn__2FqoC svg {
    opacity: 1 !important; }
  .Dashboard_CloseBtn__2FqoC:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .Dashboard_CloseBtn__2FqoC:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .Dashboard_CloseBtn__2FqoC {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.Dashboard_inputWithCalendar__3unGB {
  position: relative; }
  .Dashboard_inputWithCalendar__3unGB input {
    padding-right: 20px; }
  .Dashboard_inputWithCalendar__3unGB svg,
  .Dashboard_inputWithCalendar__3unGB span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .Dashboard_inputWithCalendar__3unGB svg path,
    .Dashboard_inputWithCalendar__3unGB span path {
      fill: #8c8c8c; }

.Dashboard_text-orange__aYGIp {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.Dashboard_color-orange__2XhHo {
  color: var(--accent-color) !important; }

.Dashboard_overlay-loader__3x_cI {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.Dashboard_dashboard__3OjYa {
  background: var(--background-color);
  min-height: 100vh; }

.Dashboard_event-lists__1pgq0 {
  padding-bottom: 100px; }
  @media (max-width: 1023.8px) {
    .Dashboard_event-lists__1pgq0 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center; } }

.Dashboard_event-list__3kd2Q {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.09); }
  .Dashboard_event-list__3kd2Q:last-child {
    margin-bottom: 0; }
  @media (max-width: 1023.8px) {
    .Dashboard_event-list__3kd2Q {
      flex-direction: column;
      width: 500px;
      flex-shrink: 0; } }
  @media (max-width: 575.8px) {
    .Dashboard_event-list__3kd2Q {
      width: 100%; } }

.Dashboard_wrap-circle__2Ylwd {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Dashboard_wrap-circle__2Ylwd span {
    position: relative; }
  .Dashboard_wrap-circle__2Ylwd small {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    color: #8c8c8c; }

.Dashboard_unlimited__2nbZU {
  font-size: 22px !important; }

.Dashboard_overlay__aCk4K {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(140, 140, 140, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; }
  .Dashboard_overlay__aCk4K svg {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease; }

.Dashboard_VOLUNTEER__2UkEv {
  opacity: 0 !important; }

.Dashboard_event-img__3FK3X {
  width: 420px;
  min-height: 266px;
  height: 100%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  -webkit-transform: scale(1);
          transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1023.8px) {
    .Dashboard_event-img__3FK3X {
      min-height: 264px;
      width: 100%; } }
  .Dashboard_event-img__3FK3X:hover .Dashboard_overlay__aCk4K {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
    .Dashboard_event-img__3FK3X:hover .Dashboard_overlay__aCk4K svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.Dashboard_show-more__5vAUV {
  right: 20px;
  position: absolute;
  top: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10; }
  @media (max-width: 1023.8px) {
    .Dashboard_show-more__5vAUV {
      top: 272px;
      right: 11px; }
      .Dashboard_show-more__5vAUV svg circle {
        fill: #575757 !important; } }

.Dashboard_setting-event__kaadX {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  min-width: 312px;
  -webkit-transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
  transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
  transition: transform 0.35s ease, opacity 0.35s ease;
  transition: transform 0.35s ease, opacity 0.35s ease, -webkit-transform 0.35s ease;
  padding: 30px 25px;
  background: #fff;
  box-shadow: 0 28px 16px rgba(11, 12, 36, 0.03);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  z-index: 1; }
  @media (max-width: 1023.8px) {
    .Dashboard_setting-event__kaadX {
      bottom: auto;
      min-width: 100%; } }
  .Dashboard_setting-event__kaadX.Dashboard_show__7Yq_b {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  .Dashboard_setting-event__kaadX a,
  .Dashboard_setting-event__kaadX button {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: var(--accent-color);
    display: inline-flex;
    margin-bottom: 15px;
    -webkit-transition: text-indent 0.35s ease;
    transition: text-indent 0.35s ease;
    text-indent: 0;
    margin-left: 0;
    padding-left: 0; }
    .Dashboard_setting-event__kaadX a:hover,
    .Dashboard_setting-event__kaadX button:hover {
      text-indent: 10px; }
    .Dashboard_setting-event__kaadX a:last-child,
    .Dashboard_setting-event__kaadX button:last-child {
      margin-bottom: 0; }

.Dashboard_role__1SEWs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--accent-color);
  z-index: 10;
  padding: 5px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px; }

.Dashboard_event-description__RWTjg {
  flex-grow: 1;
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  z-index: 1; }
  @media (max-width: 1023.8px) {
    .Dashboard_event-description__RWTjg {
      padding: 0 20px; } }
  @media (max-width: 575.8px) {
    .Dashboard_event-description__RWTjg {
      padding: 0 10px; } }

.Dashboard_event-top__2pTtc {
  display: flex;
  justify-content: space-between;
  padding: 20px 0; }
  .Dashboard_event-top__2pTtc h3 {
    margin: 15px 0;
    cursor: pointer; }
    .Dashboard_event-top__2pTtc h3 svg {
      margin-left: 10px;
      opacity: 0; }
    .Dashboard_event-top__2pTtc h3:hover svg {
      opacity: 1; }
  @media (max-width: 1023.8px) {
    .Dashboard_event-top__2pTtc span,
    .Dashboard_event-top__2pTtc p {
      font-size: 16px; } }
  @media (max-width: 575.8px) {
    .Dashboard_event-top__2pTtc span,
    .Dashboard_event-top__2pTtc p {
      font-size: 14px;
      line-height: 18px; }
    .Dashboard_event-top__2pTtc h3 {
      font-size: 20px;
      margin: 10px 0; } }
  @media (max-width: 1023.8px) {
    .Dashboard_event-top__2pTtc > div:first-child {
      padding-right: 20px; } }
  .Dashboard_event-top__2pTtc > div:last-child {
    margin-right: 50px;
    margin-left: 20px; }
    @media (max-width: 1023.8px) {
      .Dashboard_event-top__2pTtc > div:last-child {
        margin-right: 20px; }
        .Dashboard_event-top__2pTtc > div:last-child svg {
          width: 70px;
          height: 70px; } }
    @media (max-width: 575.8px) {
      .Dashboard_event-top__2pTtc > div:last-child {
        margin-left: 0;
        margin-right: 0;
        padding-right: 46px; } }

.Dashboard_event-bottom__2EP4Q {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px dashed rgba(140, 140, 140, 0.3); }
  @media (max-width: 1023.8px) {
    .Dashboard_event-bottom__2EP4Q p {
      font-size: 14px; } }
  @media (max-width: 575.8px) {
    .Dashboard_event-bottom__2EP4Q {
      flex-direction: column; }
      .Dashboard_event-bottom__2EP4Q > div:first-child {
        margin-bottom: 20px; } }
  .Dashboard_event-bottom__2EP4Q > div:first-child {
    display: flex; }
    .Dashboard_event-bottom__2EP4Q > div:first-child p {
      display: flex;
      flex-direction: column;
      margin-left: 15px; }
      .Dashboard_event-bottom__2EP4Q > div:first-child p small {
        display: inline-block;
        color: #8c8c8c;
        line-height: 28px; }
  .Dashboard_event-bottom__2EP4Q > div:last-child p {
    color: #8c8c8c !important; }
  .Dashboard_event-bottom__2EP4Q > div:last-child .Dashboard_accent__p5mQX {
    color: #4caf50; }
  @media (max-width: 575.8px) {
    .Dashboard_event-bottom__2EP4Q > div:last-child {
      margin-top: 10px; } }

.Dashboard_without-organizers__1wP48 > div:first-child {
  opacity: 0;
  visibility: hidden; }

.Dashboard_contact__21iua {
  margin-left: 90px !important; }

.Dashboard_avatar__1ukLv {
  display: flex;
  width: 62px;
  height: 62px;
  border-radius: 10em;
  overflow: hidden;
  border: 1px solid #fff;
  background: #8c8c8c;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  background-size: cover;
  background-position: center;
  position: absolute; }
  .Dashboard_avatar__1ukLv svg path {
    fill: #fff !important; }
  .Dashboard_avatar__1ukLv img {
    min-width: 100%; }
  @media (max-width: 1023.8px) {
    .Dashboard_avatar__1ukLv {
      width: 50px;
      height: 50px; } }

.Dashboard_name-event__GdyXm {
  display: block;
  margin: 15px 0; }

.Dashboard_user__2j4FM {
  position: relative; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Banner_banner__2BHFt {
  margin: 14px auto 15px;
  overflow: hidden;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 665px;
  height: 350px; }
  .Banner_banner__2BHFt:after {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: var(--background-color);
    border-radius: 50%;
    content: ''; }
  .Banner_banner__2BHFt:before {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    right: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    background-color: var(--background-color);
    border-radius: 50%;
    content: ''; }
  .Banner_banner__2BHFt img {
    width: 665px; }
  @media (max-width: 1023.8px) {
    .Banner_banner__2BHFt {
      max-width: 665px;
      width: 100%; } }
  @media (max-width: 575.8px) {
    .Banner_banner__2BHFt {
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
      margin-top: 0;
      background-image: none !important;
      height: auto !important; }
      .Banner_banner__2BHFt img {
        display: block;
        width: 100%; }
      .Banner_banner__2BHFt:after, .Banner_banner__2BHFt:before {
        content: none; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Dots_wrap-dots__Q2iks {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.Dots_dot__1MNAw {
  background: #f6f4f1;
  width: 45px;
  height: 45px;
  position: relative;
  border-radius: 10em; }
  .Dots_dot__1MNAw.Dots_dotTicket__34KH8 {
    width: 36px;
    height: 36px; }
    .Dots_dot__1MNAw.Dots_dotTicket__34KH8 span:after, .Dots_dot__1MNAw.Dots_dotTicket__34KH8 span:before {
      width: 6px;
      height: 6px; }
      @media (max-width: 575.8px) {
        .Dots_dot__1MNAw.Dots_dotTicket__34KH8 span:after, .Dots_dot__1MNAw.Dots_dotTicket__34KH8 span:before {
          width: 8px;
          height: 8px; } }
  .Dots_dot__1MNAw span:after, .Dots_dot__1MNAw span:before {
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    content: '';
    display: block;
    background: #f6f4f1;
    border-radius: 50%; }

.Dots_dot__1MNAw span:nth-child(1):after {
  -webkit-transform: translateY(37px);
          transform: translateY(37px); }

.Dots_dot__1MNAw span:nth-child(1):before {
  -webkit-transform: translateY(-37px);
          transform: translateY(-37px); }

.Dots_dot__1MNAw span:nth-child(2):after {
  -webkit-transform: translateY(53px);
          transform: translateY(53px); }

.Dots_dot__1MNAw span:nth-child(2):before {
  -webkit-transform: translateY(-53px);
          transform: translateY(-53px); }

.Dots_dot__1MNAw span:nth-child(3):after {
  -webkit-transform: translateY(69px);
          transform: translateY(69px); }

.Dots_dot__1MNAw span:nth-child(3):before {
  -webkit-transform: translateY(-69px);
          transform: translateY(-69px); }

.Dots_dot__1MNAw span:nth-child(4):after {
  -webkit-transform: translateY(85px);
          transform: translateY(85px); }

.Dots_dot__1MNAw span:nth-child(4):before {
  -webkit-transform: translateY(-85px);
          transform: translateY(-85px); }

.Dots_dot__1MNAw span:nth-child(5):after {
  -webkit-transform: translateY(101px);
          transform: translateY(101px); }

.Dots_dot__1MNAw span:nth-child(5):before {
  -webkit-transform: translateY(-101px);
          transform: translateY(-101px); }

.Dots_dot__1MNAw span:nth-child(6):after {
  -webkit-transform: translateY(117px);
          transform: translateY(117px); }

.Dots_dot__1MNAw span:nth-child(6):before {
  -webkit-transform: translateY(-117px);
          transform: translateY(-117px); }

.Dots_dot__1MNAw span:nth-child(7):after {
  -webkit-transform: translateY(133px);
          transform: translateY(133px); }

.Dots_dot__1MNAw span:nth-child(7):before {
  -webkit-transform: translateY(-133px);
          transform: translateY(-133px); }

.Dots_dot__1MNAw span:nth-child(8):after {
  -webkit-transform: translateY(149px);
          transform: translateY(149px); }

.Dots_dot__1MNAw span:nth-child(8):before {
  -webkit-transform: translateY(-149px);
          transform: translateY(-149px); }

.Dots_dot__1MNAw span:nth-child(9):after {
  -webkit-transform: translateY(165px);
          transform: translateY(165px); }

.Dots_dot__1MNAw span:nth-child(9):before {
  -webkit-transform: translateY(-165px);
          transform: translateY(-165px); }

.Dots_dot__1MNAw span:nth-child(10):after {
  -webkit-transform: translateY(181px);
          transform: translateY(181px); }

.Dots_dot__1MNAw span:nth-child(10):before {
  -webkit-transform: translateY(-181px);
          transform: translateY(-181px); }

.Dots_dotTicket__34KH8 span:nth-child(1):after {
  -webkit-transform: translateY(28px);
          transform: translateY(28px); }

.Dots_dotTicket__34KH8 span:nth-child(1):before {
  -webkit-transform: translateY(-28px);
          transform: translateY(-28px); }

.Dots_dotTicket__34KH8 span:nth-child(2):after {
  -webkit-transform: translateY(44px);
          transform: translateY(44px); }

.Dots_dotTicket__34KH8 span:nth-child(2):before {
  -webkit-transform: translateY(-44px);
          transform: translateY(-44px); }

.Dots_dotTicket__34KH8 span:nth-child(3):after {
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }

.Dots_dotTicket__34KH8 span:nth-child(3):before {
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px); }

.Dots_dotTicket__34KH8 span:nth-child(4):after {
  -webkit-transform: translateY(76px);
          transform: translateY(76px); }

.Dots_dotTicket__34KH8 span:nth-child(4):before {
  -webkit-transform: translateY(-76px);
          transform: translateY(-76px); }

.Dots_dotTicket__34KH8 span:nth-child(5):after {
  -webkit-transform: translateY(92px);
          transform: translateY(92px); }

.Dots_dotTicket__34KH8 span:nth-child(5):before {
  -webkit-transform: translateY(-92px);
          transform: translateY(-92px); }

.Dots_dotTicket__34KH8 span:nth-child(6):after {
  -webkit-transform: translateY(108px);
          transform: translateY(108px); }

.Dots_dotTicket__34KH8 span:nth-child(6):before {
  -webkit-transform: translateY(-108px);
          transform: translateY(-108px); }

.Dots_dotTicket__34KH8 span:nth-child(7):after {
  -webkit-transform: translateY(124px);
          transform: translateY(124px); }

.Dots_dotTicket__34KH8 span:nth-child(7):before {
  -webkit-transform: translateY(-124px);
          transform: translateY(-124px); }

.Dots_dotTicket__34KH8 span:nth-child(8):after {
  -webkit-transform: translateY(140px);
          transform: translateY(140px); }

.Dots_dotTicket__34KH8 span:nth-child(8):before {
  -webkit-transform: translateY(-140px);
          transform: translateY(-140px); }

.Dots_dotTicket__34KH8 span:nth-child(9):after {
  -webkit-transform: translateY(156px);
          transform: translateY(156px); }

.Dots_dotTicket__34KH8 span:nth-child(9):before {
  -webkit-transform: translateY(-156px);
          transform: translateY(-156px); }

.Dots_dotTicket__34KH8 span:nth-child(10):after {
  -webkit-transform: translateY(172px);
          transform: translateY(172px); }

.Dots_dotTicket__34KH8 span:nth-child(10):before {
  -webkit-transform: translateY(-172px);
          transform: translateY(-172px); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.EventDetails_Transition-enter__2xTN8 {
  opacity: 0.01; }

.EventDetails_Transition-enter-active__1kst9 {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.EventDetails_Transition-exit__36McY {
  opacity: 1; }

.EventDetails_Transition-exit-active__2BqPX {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.EventDetails_italic__1k2vI {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.EventDetails_timePickerMaxIndex__3M5GU {
  z-index: 999999 !important; }

@-webkit-keyframes EventDetails_pulse_animation__RNhec {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes EventDetails_pulse_animation__RNhec {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes EventDetails_pulse-shadow__31-kC {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes EventDetails_pulse-shadow__31-kC {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.EventDetails_pulse-icon__3gU30 {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: EventDetails_pulse-shadow__31-kC 2s infinite;
          animation: EventDetails_pulse-shadow__31-kC 2s infinite; }
  .EventDetails_pulse-icon__3gU30:hover {
    -webkit-animation: none;
            animation: none; }

.EventDetails_warning__p1nhh {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .EventDetails_warning__p1nhh p {
    color: #fff; }
  .EventDetails_warning__p1nhh.EventDetails_error__1mRat {
    background: rgba(252, 68, 88, 0.5) !important; }

.EventDetails_pulse__247oQ span,
.EventDetails_pulse__247oQ path {
  -webkit-animation-name: EventDetails_pulse_animation__RNhec;
          animation-name: EventDetails_pulse_animation__RNhec;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.EventDetails_custom-btn__3Fgv2 {
  overflow: hidden;
  position: relative; }

.EventDetails_custom-btn__3Fgv2:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.EventDetails_custom-btn__3Fgv2:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: EventDetails_slideme__1KKG4;
  animation-name: EventDetails_slideme__1KKG4;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes EventDetails_slideme__1KKG4 {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.EventDetails_wrap-select-custom__rfkvz {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .EventDetails_wrap-select-custom__rfkvz input {
    height: 35px !important;
    margin-top: 0; }
  .EventDetails_wrap-select-custom__rfkvz span {
    right: 0px; }

.EventDetails_opacity0__Se7Nt {
  opacity: 0 !important; }

.EventDetails_titleWithSelect__33rYY {
  display: flex;
  align-items: center; }
  .EventDetails_titleWithSelect__33rYY span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.EventDetails_require__3bf4j {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.EventDetails_container70__-sOaE {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .EventDetails_container70__-sOaE {
      padding: 0; } }

.EventDetails_color-green__18oF9 {
  color: #4caf50; }

.EventDetails_justify-center__2-epI {
  display: flex;
  flex-direction: column;
  align-items: center; }

.EventDetails_content-center__2RBxk {
  display: flex;
  align-items: center;
  justify-content: center; }

.EventDetails_max969__3Dn9Y {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.EventDetails_fz2550__38wI5 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.EventDetails_fz2530__3Hi-8 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.EventDetails_text-left__mGj4s {
  text-align: left !important; }

.EventDetails_fz1830__OT-dZ {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_fz1830__OT-dZ {
      font-size: 16px;
      line-height: 26px; } }

.EventDetails_fz1628__GKdr1 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .EventDetails_fz1628__GKdr1 {
      font-size: 14px;
      line-height: 28px; } }
  .EventDetails_fz1628__GKdr1 p,
  .EventDetails_fz1628__GKdr1 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .EventDetails_fz1628__GKdr1 p,
      .EventDetails_fz1628__GKdr1 a {
        font-size: 14px;
        line-height: 28px; } }

.EventDetails_fz1426__2bCEB {
  font-size: 14px;
  line-height: 26px; }

.EventDetails_fw600__3rSas {
  font-weight: 600; }

.EventDetails_fw799__11419 {
  font-weight: 700; }

.EventDetails_c57__3DgrB {
  color: #575757; }

.EventDetails_btn-orange__31eEX {
  color: var(--accent-color);
  cursor: pointer; }

.EventDetails_ml10__2FD_f {
  margin-left: 10px; }

.EventDetails_mr10__3pO-R {
  margin-right: 10px; }

.EventDetails_ml20__1pVAF {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_ml20__1pVAF {
      margin-left: 15px; } }

.EventDetails_mr20__12AVW {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_mr20__12AVW {
      margin-right: 15px; } }

.EventDetails_ml30__2gPlh {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_ml30__2gPlh {
      margin-left: 20px; } }

.EventDetails_mr30__1cpYC {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_mr30__1cpYC {
      margin-right: 20px; } }

.EventDetails_mt30__1_qTH {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_mt30__1_qTH {
      margin-top: 15px; } }

.EventDetails_mb30__1p5pR {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_mb30__1p5pR {
      margin-bottom: 15px; } }

.EventDetails_mt50__ISbRE {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .EventDetails_mt50__ISbRE {
      margin-top: 20px; } }

.EventDetails_mt50i__2K3h4 {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .EventDetails_mt50i__2K3h4 {
      margin-top: 20px !important; } }

.EventDetails_mb50__2lzQf {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .EventDetails_mb50__2lzQf {
      margin-bottom: 20px; } }

.EventDetails_mt20__2pnlD {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_mt20__2pnlD {
      margin-top: 15px; } }

.EventDetails_mb20__145jT {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_mb20__145jT {
      margin-bottom: 15px; } }

.EventDetails_mb5__1J_7p {
  margin-bottom: 5px !important; }

.EventDetails_mt5__2A51D {
  margin-top: 5px !important; }

.EventDetails_mb0__3DEvF {
  margin-bottom: 0 !important; }

.EventDetails_mt0__H31pC {
  margin-top: 0 !important;
  flex-grow: 1; }

.EventDetails_mt-15__OU8sv {
  margin-top: -15px !important; }

.EventDetails_w100__210Lz {
  width: 100% !important; }

.EventDetails_pt20__okbOv {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_pt20__okbOv {
      padding-top: 15px; } }

.EventDetails_pb20__NUrl6 {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_pb20__NUrl6 {
      padding-bottom: 15px; } }

.EventDetails_p0__2bNey {
  padding: 0 !important; }

.EventDetails_m_auto__15zGb {
  margin-right: auto;
  margin-left: auto; }

.EventDetails_pt50__10Hb_ {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .EventDetails_pt50__10Hb_ {
      padding-top: 20px; } }

.EventDetails_pt30__1obOS {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_pt30__1obOS {
      padding-top: 15px; } }

.EventDetails_pb100__1kdC8 {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .EventDetails_pb100__1kdC8 {
      padding-bottom: 50px; } }

.EventDetails_pb50__1aQsP {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .EventDetails_pb50__1aQsP {
      padding-bottom: 20px; } }

.EventDetails_pb30__3c5K1 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_pb30__3c5K1 {
      padding-bottom: 15px; } }

.EventDetails_title-invent__21pUC {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventDetails_title-invent__21pUC {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.EventDetails_description-invent__XB7XG {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .EventDetails_description-invent__XB7XG a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .EventDetails_description-invent__XB7XG {
      font-size: 14px;
      line-height: 28px; } }

.EventDetails_caption__3wVar {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .EventDetails_caption__3wVar b {
    display: flex;
    margin-top: -5px; }
    .EventDetails_caption__3wVar b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .EventDetails_caption__3wVar {
      font-size: 22px;
      line-height: 30px; } }

.EventDetails_wrap-loader-overlay__2L9-5 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.EventDetails_wrap-loader__2Vp9Y {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .EventDetails_wrap-loader__2Vp9Y svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.EventDetails_btn-green__1XQzh {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .EventDetails_btn-green__1XQzh {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.EventDetails_btn-green__1XQzh:hover {
  background: rgba(76, 175, 80, 0.7); }

.EventDetails_btn-green__1XQzh:active {
  background: rgba(76, 175, 80, 0.5); }

.EventDetails_button-orange__3OtU6 {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .EventDetails_button-orange__3OtU6:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .EventDetails_button-orange__3OtU6:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.EventDetails_button-orange-light__1sIIR {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .EventDetails_button-orange-light__1sIIR:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.EventDetails_fz1728__27O3G {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.EventDetails_c8c__36dek {
  color: #8c8c8c; }

.EventDetails_space-between__29Hix {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.EventDetails_relative__1y_f6 {
  position: relative; }

.EventDetails_scroll__2zpxu {
  overflow-y: scroll !important; }
  .EventDetails_scroll__2zpxu::-webkit-scrollbar {
    width: 2px; }
  .EventDetails_scroll__2zpxu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .EventDetails_scroll__2zpxu::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.EventDetails_ticketIcon__3dac3 {
  position: relative;
  display: inline-flex; }
  .EventDetails_ticketIcon__3dac3 strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.EventDetails_textOnButton__29SQB {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .EventDetails_textOnButton__29SQB span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.EventDetails_modalType__B7Los {
  background: var(--background-color) !important;
  max-width: 962px; }

.EventDetails_CloseBtn__1gKVG {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .EventDetails_CloseBtn__1gKVG svg {
    opacity: 1 !important; }
  .EventDetails_CloseBtn__1gKVG:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .EventDetails_CloseBtn__1gKVG:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .EventDetails_CloseBtn__1gKVG {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.EventDetails_inputWithCalendar__1xoem {
  position: relative; }
  .EventDetails_inputWithCalendar__1xoem input {
    padding-right: 20px; }
  .EventDetails_inputWithCalendar__1xoem svg,
  .EventDetails_inputWithCalendar__1xoem span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .EventDetails_inputWithCalendar__1xoem svg path,
    .EventDetails_inputWithCalendar__1xoem span path {
      fill: #8c8c8c; }

.EventDetails_text-orange__NgJea {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.EventDetails_color-orange__2C8Zp {
  color: var(--accent-color) !important; }

.EventDetails_overlay-loader__3f3hJ {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.EventDetails_CalendarList__jp7zA a {
  color: var(--accent-color); }

.EventDetails_orange__3Y34l {
  background-color: var(--accent-color) !important;
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1); }
  .EventDetails_orange__3Y34l:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

.EventDetails_wrap__1ddag {
  display: flex;
  padding: 24px 30px;
  background: #fff;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  position: relative;
  border-radius: 8.69672px;
  margin-bottom: 50px;
  overflow: hidden; }
  @media (max-width: 1199.8px) {
    .EventDetails_wrap__1ddag {
      flex-direction: column; } }
  @media (max-width: 575.8px) {
    .EventDetails_wrap__1ddag {
      padding: 18px 16px;
      margin-bottom: 0; } }

.EventDetails_items__jWfMQ {
  display: flex; }
  .EventDetails_items__jWfMQ .EventDetails_item__1rX1h {
    min-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .EventDetails_items__jWfMQ .EventDetails_item__1rX1h:first-child {
      margin-right: 15px; }
    .EventDetails_items__jWfMQ .EventDetails_item__1rX1h:last-child {
      margin-left: 40px;
      min-width: 270px; }
    @media (max-width: 575.8px) {
      .EventDetails_items__jWfMQ .EventDetails_item__1rX1h {
        min-width: auto;
        padding-right: 0; } }
  @media (max-width: 1199.8px) {
    .EventDetails_items__jWfMQ {
      flex-direction: column; }
      .EventDetails_items__jWfMQ .EventDetails_item__1rX1h:last-child {
        margin-top: 30px;
        margin-left: 0; } }
  @media (max-width: 575.8px) {
    .EventDetails_items__jWfMQ .EventDetails_item__1rX1h:last-child {
      margin-top: 15px;
      margin-left: 0; } }

.EventDetails_descr__2kQHy {
  padding: 20px 0; }
  .EventDetails_descr__2kQHy.EventDetails_descrLine__1gsSh {
    border-right: 1px solid rgba(140, 140, 140, 0.5);
    padding-right: 30px; }
    @media (max-width: 1199.8px) {
      .EventDetails_descr__2kQHy.EventDetails_descrLine__1gsSh {
        border-right: 0;
        padding-right: 0; } }
    @media (max-width: 575.8px) {
      .EventDetails_descr__2kQHy.EventDetails_descrLine__1gsSh p:last-child span {
        display: block; } }
  @media (max-width: 575.8px) {
    .EventDetails_descr__2kQHy {
      padding: 15px 0; } }

.EventDetails_science__1pVHW {
  position: relative;
  flex-grow: 1;
  padding-right: 80px; }
  @media (max-width: 1199.8px) {
    .EventDetails_science__1pVHW {
      padding-right: 0;
      padding-bottom: 60px; } }

.EventDetails_title__2SZcy {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .EventDetails_title__2SZcy {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 15px; } }

.EventDetails_decor__3KpQ4 {
  position: absolute;
  width: 45px;
  top: 0;
  right: 0;
  height: 100%; }
  @media (max-width: 1199.8px) {
    .EventDetails_decor__3KpQ4 {
      bottom: 0;
      left: 0;
      top: auto;
      width: 100%;
      height: 45px;
      display: none; } }
  @media (max-width: 575.8px) {
    .EventDetails_decor__3KpQ4 {
      display: block; }
      .EventDetails_decor__3KpQ4 > div > div {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); } }

.EventDetails_itemTitle__1Cec4 {
  display: flex;
  align-items: center; }
  .EventDetails_itemTitle__1Cec4 span {
    color: #8c8c8c;
    margin-left: 13px; }

.EventDetails_share__1WYSe {
  width: 262px;
  flex-shrink: 0;
  margin-left: 45px; }
  @media (max-width: 1199.8px) {
    .EventDetails_share__1WYSe {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-left: 0;
      align-items: center;
      padding-top: 20px; } }
  .EventDetails_share-title__1L4N0 {
    padding: 0 20px;
    text-align: center; }
    @media (max-width: 575.8px) {
      .EventDetails_share-title__1L4N0 {
        padding: 0; } }
  .EventDetails_share-links__3akmu {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 32px; }
    @media (max-width: 1199.8px) {
      .EventDetails_share-links__3akmu {
        margin: 20px 0; } }
    .EventDetails_share-links__3akmu > * {
      margin: 0 12px;
      cursor: pointer; }

@media (max-width: 1199.8px) {
  .EventDetails_hideIpad__2drDC {
    display: none; } }

.EventDetails_showIpad__8CcNN {
  margin-top: 20px;
  display: none; }
  @media (max-width: 1199.8px) {
    .EventDetails_showIpad__8CcNN {
      display: block; } }

.EventDetails_locationDescription__ovPd- {
  margin-top: 20px; }

.EventDetails_titleIpad__1E0f1 {
  color: #8c8c8c; }

.EventDetails_Tooltip__1EVdu {
  max-width: 660px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.EventOrganizers_Transition-enter__2XOYP {
  opacity: 0.01; }

.EventOrganizers_Transition-enter-active__3oEL5 {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.EventOrganizers_Transition-exit__2w2wr {
  opacity: 1; }

.EventOrganizers_Transition-exit-active__3l7rK {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.EventOrganizers_italic__31P_- {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.EventOrganizers_timePickerMaxIndex__248Ei {
  z-index: 999999 !important; }

@-webkit-keyframes EventOrganizers_pulse_animation__ORxeu {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes EventOrganizers_pulse_animation__ORxeu {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes EventOrganizers_pulse-shadow__3_t25 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes EventOrganizers_pulse-shadow__3_t25 {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.EventOrganizers_pulse-icon__MNoaU {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: EventOrganizers_pulse-shadow__3_t25 2s infinite;
          animation: EventOrganizers_pulse-shadow__3_t25 2s infinite; }
  .EventOrganizers_pulse-icon__MNoaU:hover {
    -webkit-animation: none;
            animation: none; }

.EventOrganizers_warning__32IDh {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .EventOrganizers_warning__32IDh p {
    color: #fff; }
  .EventOrganizers_warning__32IDh.EventOrganizers_error__enS64 {
    background: rgba(252, 68, 88, 0.5) !important; }

.EventOrganizers_pulse__2w4j0 span,
.EventOrganizers_pulse__2w4j0 path {
  -webkit-animation-name: EventOrganizers_pulse_animation__ORxeu;
          animation-name: EventOrganizers_pulse_animation__ORxeu;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.EventOrganizers_custom-btn__3xgU1 {
  overflow: hidden;
  position: relative; }

.EventOrganizers_custom-btn__3xgU1:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.EventOrganizers_custom-btn__3xgU1:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: EventOrganizers_slideme__3Q9SE;
  animation-name: EventOrganizers_slideme__3Q9SE;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes EventOrganizers_slideme__3Q9SE {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.EventOrganizers_wrap-select-custom__25TJu {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .EventOrganizers_wrap-select-custom__25TJu input {
    height: 35px !important;
    margin-top: 0; }
  .EventOrganizers_wrap-select-custom__25TJu span {
    right: 0px; }

.EventOrganizers_opacity0__3aJAD {
  opacity: 0 !important; }

.EventOrganizers_titleWithSelect__9CaEL {
  display: flex;
  align-items: center; }
  .EventOrganizers_titleWithSelect__9CaEL span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.EventOrganizers_require__risGJ {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.EventOrganizers_container70__2nmWr {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .EventOrganizers_container70__2nmWr {
      padding: 0; } }

.EventOrganizers_color-green__krHJj {
  color: #4caf50; }

.EventOrganizers_justify-center__Etk_y {
  display: flex;
  flex-direction: column;
  align-items: center; }

.EventOrganizers_content-center__35h6m {
  display: flex;
  align-items: center;
  justify-content: center; }

.EventOrganizers_max969__3qrX0 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.EventOrganizers_fz2550__31vq- {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.EventOrganizers_fz2530__3rDl3 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.EventOrganizers_text-left__3iVCP {
  text-align: left !important; }

.EventOrganizers_fz1830__GEoKE {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_fz1830__GEoKE {
      font-size: 16px;
      line-height: 26px; } }

.EventOrganizers_fz1628__1x0-M {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_fz1628__1x0-M {
      font-size: 14px;
      line-height: 28px; } }
  .EventOrganizers_fz1628__1x0-M p,
  .EventOrganizers_fz1628__1x0-M a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .EventOrganizers_fz1628__1x0-M p,
      .EventOrganizers_fz1628__1x0-M a {
        font-size: 14px;
        line-height: 28px; } }

.EventOrganizers_fz1426__1fcgz {
  font-size: 14px;
  line-height: 26px; }

.EventOrganizers_fw600__3ACoU {
  font-weight: 600; }

.EventOrganizers_fw799__30pPT {
  font-weight: 700; }

.EventOrganizers_c57__2QuKv {
  color: #575757; }

.EventOrganizers_btn-orange__2yvyh {
  color: var(--accent-color);
  cursor: pointer; }

.EventOrganizers_ml10__2csFA {
  margin-left: 10px; }

.EventOrganizers_mr10__2pQkf {
  margin-right: 10px; }

.EventOrganizers_ml20__1ICl2 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_ml20__1ICl2 {
      margin-left: 15px; } }

.EventOrganizers_mr20__1zENO {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mr20__1zENO {
      margin-right: 15px; } }

.EventOrganizers_ml30__1PrTR {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_ml30__1PrTR {
      margin-left: 20px; } }

.EventOrganizers_mr30__9rA93 {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mr30__9rA93 {
      margin-right: 20px; } }

.EventOrganizers_mt30__juKY2 {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mt30__juKY2 {
      margin-top: 15px; } }

.EventOrganizers_mb30__2tHie {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mb30__2tHie {
      margin-bottom: 15px; } }

.EventOrganizers_mt50__1RwMv {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mt50__1RwMv {
      margin-top: 20px; } }

.EventOrganizers_mt50i__OgzAS {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mt50i__OgzAS {
      margin-top: 20px !important; } }

.EventOrganizers_mb50__tvvrP {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mb50__tvvrP {
      margin-bottom: 20px; } }

.EventOrganizers_mt20__1eU2Q {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mt20__1eU2Q {
      margin-top: 15px; } }

.EventOrganizers_mb20__2udLQ {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_mb20__2udLQ {
      margin-bottom: 15px; } }

.EventOrganizers_mb5__3Twcs {
  margin-bottom: 5px !important; }

.EventOrganizers_mt5__3ggjD {
  margin-top: 5px !important; }

.EventOrganizers_mb0__35bjU {
  margin-bottom: 0 !important; }

.EventOrganizers_mt0__3_ZkE {
  margin-top: 0 !important;
  flex-grow: 1; }

.EventOrganizers_mt-15__1QH1J {
  margin-top: -15px !important; }

.EventOrganizers_w100__3ETvW {
  width: 100% !important; }

.EventOrganizers_pt20__FJl-J {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pt20__FJl-J {
      padding-top: 15px; } }

.EventOrganizers_pb20__3XBhp {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pb20__3XBhp {
      padding-bottom: 15px; } }

.EventOrganizers_p0__BWMMo {
  padding: 0 !important; }

.EventOrganizers_m_auto__U0LEP {
  margin-right: auto;
  margin-left: auto; }

.EventOrganizers_pt50__ndaYE {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pt50__ndaYE {
      padding-top: 20px; } }

.EventOrganizers_pt30__BPMFw {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pt30__BPMFw {
      padding-top: 15px; } }

.EventOrganizers_pb100__2VnGj {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pb100__2VnGj {
      padding-bottom: 50px; } }

.EventOrganizers_pb50__2A488 {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pb50__2A488 {
      padding-bottom: 20px; } }

.EventOrganizers_pb30__kr7cl {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_pb30__kr7cl {
      padding-bottom: 15px; } }

.EventOrganizers_title-invent__J4HcV {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_title-invent__J4HcV {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.EventOrganizers_description-invent__700sq {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .EventOrganizers_description-invent__700sq a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .EventOrganizers_description-invent__700sq {
      font-size: 14px;
      line-height: 28px; } }

.EventOrganizers_caption__3301A {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .EventOrganizers_caption__3301A b {
    display: flex;
    margin-top: -5px; }
    .EventOrganizers_caption__3301A b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .EventOrganizers_caption__3301A {
      font-size: 22px;
      line-height: 30px; } }

.EventOrganizers_wrap-loader-overlay__3HbED {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.EventOrganizers_wrap-loader__UOg_5 {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .EventOrganizers_wrap-loader__UOg_5 svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.EventOrganizers_btn-green__335if {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .EventOrganizers_btn-green__335if {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.EventOrganizers_btn-green__335if:hover {
  background: rgba(76, 175, 80, 0.7); }

.EventOrganizers_btn-green__335if:active {
  background: rgba(76, 175, 80, 0.5); }

.EventOrganizers_button-orange__3cLXw {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .EventOrganizers_button-orange__3cLXw:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .EventOrganizers_button-orange__3cLXw:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.EventOrganizers_button-orange-light__ZP3c9 {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .EventOrganizers_button-orange-light__ZP3c9:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.EventOrganizers_fz1728__vwogE {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.EventOrganizers_c8c__2bwOv {
  color: #8c8c8c; }

.EventOrganizers_space-between__Gmzf1 {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.EventOrganizers_relative__1oC6V {
  position: relative; }

.EventOrganizers_scroll__1-38G {
  overflow-y: scroll !important; }
  .EventOrganizers_scroll__1-38G::-webkit-scrollbar {
    width: 2px; }
  .EventOrganizers_scroll__1-38G::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .EventOrganizers_scroll__1-38G::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.EventOrganizers_ticketIcon__xx7mB {
  position: relative;
  display: inline-flex; }
  .EventOrganizers_ticketIcon__xx7mB strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.EventOrganizers_textOnButton__88z4n {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .EventOrganizers_textOnButton__88z4n span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.EventOrganizers_modalType__3KagK {
  background: var(--background-color) !important;
  max-width: 962px; }

.EventOrganizers_CloseBtn__3aAyH {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .EventOrganizers_CloseBtn__3aAyH svg {
    opacity: 1 !important; }
  .EventOrganizers_CloseBtn__3aAyH:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .EventOrganizers_CloseBtn__3aAyH:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .EventOrganizers_CloseBtn__3aAyH {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.EventOrganizers_inputWithCalendar__1q3gf {
  position: relative; }
  .EventOrganizers_inputWithCalendar__1q3gf input {
    padding-right: 20px; }
  .EventOrganizers_inputWithCalendar__1q3gf svg,
  .EventOrganizers_inputWithCalendar__1q3gf span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .EventOrganizers_inputWithCalendar__1q3gf svg path,
    .EventOrganizers_inputWithCalendar__1q3gf span path {
      fill: #8c8c8c; }

.EventOrganizers_text-orange__Bh-Bq {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.EventOrganizers_color-orange__36b9V {
  color: var(--accent-color) !important; }

.EventOrganizers_overlay-loader__-7z5m {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.EventOrganizers_wrap-detail__3RL8N {
  display: flex; }

.EventOrganizers_wrap-detail__3RL8N {
  margin-top: 50px; }
  @media (max-width: 767.8px) {
    .EventOrganizers_wrap-detail__3RL8N {
      flex-direction: column-reverse; } }
  @media (max-width: 575.8px) {
    .EventOrganizers_wrap-detail__3RL8N {
      margin-top: 20px; } }

.EventOrganizers_detail__k3a55 {
  flex-grow: 1; }
  .EventOrganizers_detail__k3a55 > div {
    margin-top: 50px; }
    @media (max-width: 575.8px) {
      .EventOrganizers_detail__k3a55 > div {
        margin-top: 19px; } }
    .EventOrganizers_detail__k3a55 > div:first-child {
      margin-top: 0; }
  @media (max-width: 767.8px) {
    .EventOrganizers_detail__k3a55 {
      margin-top: 50px; } }

.EventOrganizers_description-invent__700sq ul, .EventOrganizers_description-invent__700sq li {
  list-style: inside !important; }

.EventOrganizers_rectangle__SuuTy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
  flex-shrink: 0;
  align-self: flex-start;
  padding: 20px 20px 35px;
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03); }
  @media (max-width: 575.8px) {
    .EventOrganizers_rectangle__SuuTy {
      padding-bottom: 20px; } }
  .EventOrganizers_rectangle__SuuTy .EventOrganizers_btn-orange__2yvyh {
    margin-top: 30px; }
    @media (max-width: 575.8px) {
      .EventOrganizers_rectangle__SuuTy .EventOrganizers_btn-orange__2yvyh {
        margin-top: 10px; } }
    .EventOrganizers_rectangle__SuuTy .EventOrganizers_btn-orange__2yvyh:hover svg {
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
    .EventOrganizers_rectangle__SuuTy .EventOrganizers_btn-orange__2yvyh svg {
      -webkit-transition: -webkit-transform .35s ease-in-out;
      transition: -webkit-transform .35s ease-in-out;
      transition: transform .35s ease-in-out;
      transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
      margin-left: 5px; }
  .EventOrganizers_rectangle__SuuTy > * {
    text-align: center; }
  .EventOrganizers_rectangle__SuuTy .EventOrganizers_description-invent__700sq {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: center; }
    @media (max-width: 575.8px) {
      .EventOrganizers_rectangle__SuuTy .EventOrganizers_description-invent__700sq {
        margin-top: 10px; } }
    .EventOrganizers_rectangle__SuuTy .EventOrganizers_description-invent__700sq span {
      margin-left: 10px;
      margin-top: 2px; }
  @media (max-width: 767.8px) {
    .EventOrganizers_rectangle__SuuTy {
      margin: 0 auto;
      width: 100%; } }
  @media (max-width: 575.8px) {
    .EventOrganizers_rectangle__SuuTy {
      margin: 0 auto; } }

.EventOrganizers_name__2dmh1 {
  margin-top: 0;
  margin-bottom: 10px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_name__2dmh1 {
      margin-bottom: 10px !important;
      margin-top: 16px; } }

.EventOrganizers_img-ds__1bndc {
  margin: 20px auto;
  max-width: 100px;
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 10em;
  overflow: hidden; }
  .EventOrganizers_img-ds__1bndc img, .EventOrganizers_img-ds__1bndc svg {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    width: 100%; }

.EventOrganizers_more__1LwAQ .EventOrganizers_description-invent__700sq:first-child {
  margin-top: 0; }

.EventOrganizers_links__1trn4 a {
  margin: 0 8px; }
  @media (max-width: 575.8px) {
    .EventOrganizers_links__1trn4 a {
      margin: 0 13px; } }

.slick-slider {
  width: 350px;
  flex-shrink: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 30px; }
  @media (max-width: 1220px) {
    .slick-slider {
      margin-right: 15px; } }
  @media (max-width: 768px) {
    .slick-slider {
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 15px); } }

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  z-index: 1; }

.slick-prev:before, .slick-next:before {
  color: var(--accent-color);
  font-size: 28px;
  line-height: 28px; }

.slick-list {
  border-radius: 2px; }

.PreviewBlock_main__1Dqpz {
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-radius: 2px;
  padding: 20px 30px;
  position: relative;
  min-height: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  margin-top: 15px;
  flex-direction: column; }
  .PreviewBlock_main__1Dqpz p {
    padding: 10px 0 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #8C8C8C; }

.PreviewBlock_Content__2RgnX {
  padding: 20px 0 !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Dots_wrap-dots__3TtAE {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.Dots_dot__3ZnfA {
  background: #f6f4f1;
  width: 45px;
  height: 45px;
  position: relative;
  border-radius: 10em; }
  .Dots_dot__3ZnfA.Dots_dotTicket__3VzkB {
    width: 36px;
    height: 36px; }
    .Dots_dot__3ZnfA.Dots_dotTicket__3VzkB span:after, .Dots_dot__3ZnfA.Dots_dotTicket__3VzkB span:before {
      width: 6px;
      height: 6px; }
      @media (max-width: 575.8px) {
        .Dots_dot__3ZnfA.Dots_dotTicket__3VzkB span:after, .Dots_dot__3ZnfA.Dots_dotTicket__3VzkB span:before {
          width: 8px;
          height: 8px; } }
  .Dots_dot__3ZnfA span:after, .Dots_dot__3ZnfA span:before {
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    content: '';
    display: block;
    background: #f6f4f1;
    border-radius: 50%; }

.Dots_dot__3ZnfA span:nth-child(1):after {
  -webkit-transform: translateY(37px);
          transform: translateY(37px); }

.Dots_dot__3ZnfA span:nth-child(1):before {
  -webkit-transform: translateY(-37px);
          transform: translateY(-37px); }

.Dots_dot__3ZnfA span:nth-child(2):after {
  -webkit-transform: translateY(53px);
          transform: translateY(53px); }

.Dots_dot__3ZnfA span:nth-child(2):before {
  -webkit-transform: translateY(-53px);
          transform: translateY(-53px); }

.Dots_dot__3ZnfA span:nth-child(3):after {
  -webkit-transform: translateY(69px);
          transform: translateY(69px); }

.Dots_dot__3ZnfA span:nth-child(3):before {
  -webkit-transform: translateY(-69px);
          transform: translateY(-69px); }

.Dots_dot__3ZnfA span:nth-child(4):after {
  -webkit-transform: translateY(85px);
          transform: translateY(85px); }

.Dots_dot__3ZnfA span:nth-child(4):before {
  -webkit-transform: translateY(-85px);
          transform: translateY(-85px); }

.Dots_dot__3ZnfA span:nth-child(5):after {
  -webkit-transform: translateY(101px);
          transform: translateY(101px); }

.Dots_dot__3ZnfA span:nth-child(5):before {
  -webkit-transform: translateY(-101px);
          transform: translateY(-101px); }

.Dots_dot__3ZnfA span:nth-child(6):after {
  -webkit-transform: translateY(117px);
          transform: translateY(117px); }

.Dots_dot__3ZnfA span:nth-child(6):before {
  -webkit-transform: translateY(-117px);
          transform: translateY(-117px); }

.Dots_dot__3ZnfA span:nth-child(7):after {
  -webkit-transform: translateY(133px);
          transform: translateY(133px); }

.Dots_dot__3ZnfA span:nth-child(7):before {
  -webkit-transform: translateY(-133px);
          transform: translateY(-133px); }

.Dots_dot__3ZnfA span:nth-child(8):after {
  -webkit-transform: translateY(149px);
          transform: translateY(149px); }

.Dots_dot__3ZnfA span:nth-child(8):before {
  -webkit-transform: translateY(-149px);
          transform: translateY(-149px); }

.Dots_dot__3ZnfA span:nth-child(9):after {
  -webkit-transform: translateY(165px);
          transform: translateY(165px); }

.Dots_dot__3ZnfA span:nth-child(9):before {
  -webkit-transform: translateY(-165px);
          transform: translateY(-165px); }

.Dots_dot__3ZnfA span:nth-child(10):after {
  -webkit-transform: translateY(181px);
          transform: translateY(181px); }

.Dots_dot__3ZnfA span:nth-child(10):before {
  -webkit-transform: translateY(-181px);
          transform: translateY(-181px); }

.Dots_dotTicket__3VzkB span:nth-child(1):after {
  -webkit-transform: translateY(28px);
          transform: translateY(28px); }

.Dots_dotTicket__3VzkB span:nth-child(1):before {
  -webkit-transform: translateY(-28px);
          transform: translateY(-28px); }

.Dots_dotTicket__3VzkB span:nth-child(2):after {
  -webkit-transform: translateY(44px);
          transform: translateY(44px); }

.Dots_dotTicket__3VzkB span:nth-child(2):before {
  -webkit-transform: translateY(-44px);
          transform: translateY(-44px); }

.Dots_dotTicket__3VzkB span:nth-child(3):after {
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }

.Dots_dotTicket__3VzkB span:nth-child(3):before {
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px); }

.Dots_dotTicket__3VzkB span:nth-child(4):after {
  -webkit-transform: translateY(76px);
          transform: translateY(76px); }

.Dots_dotTicket__3VzkB span:nth-child(4):before {
  -webkit-transform: translateY(-76px);
          transform: translateY(-76px); }

.Dots_dotTicket__3VzkB span:nth-child(5):after {
  -webkit-transform: translateY(92px);
          transform: translateY(92px); }

.Dots_dotTicket__3VzkB span:nth-child(5):before {
  -webkit-transform: translateY(-92px);
          transform: translateY(-92px); }

.Dots_dotTicket__3VzkB span:nth-child(6):after {
  -webkit-transform: translateY(108px);
          transform: translateY(108px); }

.Dots_dotTicket__3VzkB span:nth-child(6):before {
  -webkit-transform: translateY(-108px);
          transform: translateY(-108px); }

.Dots_dotTicket__3VzkB span:nth-child(7):after {
  -webkit-transform: translateY(124px);
          transform: translateY(124px); }

.Dots_dotTicket__3VzkB span:nth-child(7):before {
  -webkit-transform: translateY(-124px);
          transform: translateY(-124px); }

.Dots_dotTicket__3VzkB span:nth-child(8):after {
  -webkit-transform: translateY(140px);
          transform: translateY(140px); }

.Dots_dotTicket__3VzkB span:nth-child(8):before {
  -webkit-transform: translateY(-140px);
          transform: translateY(-140px); }

.Dots_dotTicket__3VzkB span:nth-child(9):after {
  -webkit-transform: translateY(156px);
          transform: translateY(156px); }

.Dots_dotTicket__3VzkB span:nth-child(9):before {
  -webkit-transform: translateY(-156px);
          transform: translateY(-156px); }

.Dots_dotTicket__3VzkB span:nth-child(10):after {
  -webkit-transform: translateY(172px);
          transform: translateY(172px); }

.Dots_dotTicket__3VzkB span:nth-child(10):before {
  -webkit-transform: translateY(-172px);
          transform: translateY(-172px); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Event_Transition-enter__3eybE {
  opacity: 0.01; }

.Event_Transition-enter-active__4vY1G {
  opacity: 1;
  -webkit-transition: 300ms ease-in;
  transition: 300ms ease-in; }

.Event_Transition-exit__3Vy81 {
  opacity: 1; }

.Event_Transition-exit-active__3uuS8 {
  opacity: 0.01;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out; }

.Event_italic__1B78s {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.Event_timePickerMaxIndex__1Ovp3 {
  z-index: 999999 !important; }

@-webkit-keyframes Event_pulse_animation__1AUep {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Event_pulse_animation__1AUep {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  70% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Event_pulse-shadow__3Jjyz {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes Event_pulse-shadow__3Jjyz {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.Event_pulse-icon__2zGmF {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  -webkit-animation: Event_pulse-shadow__3Jjyz 2s infinite;
          animation: Event_pulse-shadow__3Jjyz 2s infinite; }
  .Event_pulse-icon__2zGmF:hover {
    -webkit-animation: none;
            animation: none; }

.Event_warning__3gjV8 {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .Event_warning__3gjV8 p {
    color: #fff; }
  .Event_warning__3gjV8.Event_error__2AyEI {
    background: rgba(252, 68, 88, 0.5) !important; }

.Event_pulse__2qWGf span,
.Event_pulse__2qWGf path {
  -webkit-animation-name: Event_pulse_animation__1AUep;
          animation-name: Event_pulse_animation__1AUep;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.Event_custom-btn__uqgyM {
  overflow: hidden;
  position: relative; }

.Event_custom-btn__uqgyM:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.Event_custom-btn__uqgyM:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: Event_slideme__3hc5O;
  animation-name: Event_slideme__3hc5O;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes Event_slideme__3hc5O {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.Event_wrap-select-custom__6GnQy {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .Event_wrap-select-custom__6GnQy input {
    height: 35px !important;
    margin-top: 0; }
  .Event_wrap-select-custom__6GnQy span {
    right: 0px; }

.Event_opacity0__1o1rN {
  opacity: 0 !important; }

.Event_titleWithSelect__NAzLS {
  display: flex;
  align-items: center; }
  .Event_titleWithSelect__NAzLS span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.Event_require__2URXs {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.Event_container70__1xsJc {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .Event_container70__1xsJc {
      padding: 0; } }

.Event_color-green__1BS_D {
  color: #4caf50; }

.Event_justify-center__37i4- {
  display: flex;
  flex-direction: column;
  align-items: center; }

.Event_content-center__3G-e4 {
  display: flex;
  align-items: center;
  justify-content: center; }

.Event_max969__OpYEx {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.Event_fz2550__1uYkC {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.Event_fz2530__1yP1Z {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.Event_text-left__1-JFv {
  text-align: left !important; }

.Event_fz1830__2lXbC {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .Event_fz1830__2lXbC {
      font-size: 16px;
      line-height: 26px; } }

.Event_fz1628__2ada6 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .Event_fz1628__2ada6 {
      font-size: 14px;
      line-height: 28px; } }
  .Event_fz1628__2ada6 p,
  .Event_fz1628__2ada6 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .Event_fz1628__2ada6 p,
      .Event_fz1628__2ada6 a {
        font-size: 14px;
        line-height: 28px; } }

.Event_fz1426__3tX3V {
  font-size: 14px;
  line-height: 26px; }

.Event_fw600__yixKJ {
  font-weight: 600; }

.Event_fw799__3sMPp {
  font-weight: 700; }

.Event_c57__23xgJ {
  color: #575757; }

.Event_btn-orange__2c96b {
  color: var(--accent-color);
  cursor: pointer; }

.Event_ml10__YNGen {
  margin-left: 10px; }

.Event_mr10__H2mKR {
  margin-right: 10px; }

.Event_ml20__1vrzM {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .Event_ml20__1vrzM {
      margin-left: 15px; } }

.Event_mr20__2o1vF {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .Event_mr20__2o1vF {
      margin-right: 15px; } }

.Event_ml30__2zrWx {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .Event_ml30__2zrWx {
      margin-left: 20px; } }

.Event_mr30__3C6Hl {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .Event_mr30__3C6Hl {
      margin-right: 20px; } }

.Event_mt30__OIpMK {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .Event_mt30__OIpMK {
      margin-top: 15px; } }

.Event_mb30__1drIT {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .Event_mb30__1drIT {
      margin-bottom: 15px; } }

.Event_mt50__1gAc3 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .Event_mt50__1gAc3 {
      margin-top: 20px; } }

.Event_mt50i__1FAjs {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .Event_mt50i__1FAjs {
      margin-top: 20px !important; } }

.Event_mb50__294hb {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .Event_mb50__294hb {
      margin-bottom: 20px; } }

.Event_mt20__sWsGY {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .Event_mt20__sWsGY {
      margin-top: 15px; } }

.Event_mb20__2xsV_ {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Event_mb20__2xsV_ {
      margin-bottom: 15px; } }

.Event_mb5__14F6O {
  margin-bottom: 5px !important; }

.Event_mt5__1e4UJ {
  margin-top: 5px !important; }

.Event_mb0__VODW9 {
  margin-bottom: 0 !important; }

.Event_mt0__yI0FP {
  margin-top: 0 !important;
  flex-grow: 1; }

.Event_mt-15__wrGEj {
  margin-top: -15px !important; }

.Event_w100__1S3QV {
  width: 100% !important; }

.Event_pt20__3RstJ {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .Event_pt20__3RstJ {
      padding-top: 15px; } }

.Event_pb20__2riZA {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Event_pb20__2riZA {
      padding-bottom: 15px; } }

.Event_p0__29SyZ {
  padding: 0 !important; }

.Event_m_auto__1_Sb7 {
  margin-right: auto;
  margin-left: auto; }

.Event_pt50__bXbOO {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .Event_pt50__bXbOO {
      padding-top: 20px; } }

.Event_pt30__2T1_f {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .Event_pt30__2T1_f {
      padding-top: 15px; } }

.Event_pb100__IZXgU {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .Event_pb100__IZXgU {
      padding-bottom: 50px; } }

.Event_pb50__o1mTG {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .Event_pb50__o1mTG {
      padding-bottom: 20px; } }

.Event_pb30__3jCQ4 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .Event_pb30__3jCQ4 {
      padding-bottom: 15px; } }

.Event_title-invent__AzMhE {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .Event_title-invent__AzMhE {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.Event_description-invent__FWafg {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .Event_description-invent__FWafg a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .Event_description-invent__FWafg {
      font-size: 14px;
      line-height: 28px; } }

.Event_caption__2oywN {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .Event_caption__2oywN b {
    display: flex;
    margin-top: -5px; }
    .Event_caption__2oywN b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .Event_caption__2oywN {
      font-size: 22px;
      line-height: 30px; } }

.Event_wrap-loader-overlay__23LSm {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.Event_wrap-loader__3fMfk {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .Event_wrap-loader__3fMfk svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.Event_btn-green__uOzVu {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transition: background 0.34s;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .Event_btn-green__uOzVu {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.Event_btn-green__uOzVu:hover {
  background: rgba(76, 175, 80, 0.7); }

.Event_btn-green__uOzVu:active {
  background: rgba(76, 175, 80, 0.5); }

.Event_button-orange__IvLUV {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .Event_button-orange__IvLUV:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .Event_button-orange__IvLUV:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.Event_button-orange-light__1k1EB {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  -webkit-transition: color 0.35s ease, background 0.35s ease !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .Event_button-orange-light__1k1EB:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.Event_fz1728__3Yz23 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.Event_c8c__1uKgx {
  color: #8c8c8c; }

.Event_space-between__Nqvkk {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Event_relative__2Fi34 {
  position: relative; }

.Event_scroll__rcLG2 {
  overflow-y: scroll !important; }
  .Event_scroll__rcLG2::-webkit-scrollbar {
    width: 2px; }
  .Event_scroll__rcLG2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .Event_scroll__rcLG2::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.Event_ticketIcon__1HqXy {
  position: relative;
  display: inline-flex; }
  .Event_ticketIcon__1HqXy strong {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.Event_textOnButton__2EVjZ {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .Event_textOnButton__2EVjZ span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.Event_modalType__3EzZy {
  background: var(--background-color) !important;
  max-width: 962px; }

.Event_CloseBtn__3aPGp {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 20; }
  .Event_CloseBtn__3aPGp svg {
    opacity: 1 !important; }
  .Event_CloseBtn__3aPGp:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .Event_CloseBtn__3aPGp:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .Event_CloseBtn__3aPGp {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.Event_inputWithCalendar__3D__b {
  position: relative; }
  .Event_inputWithCalendar__3D__b input {
    padding-right: 20px; }
  .Event_inputWithCalendar__3D__b svg,
  .Event_inputWithCalendar__3D__b span {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .Event_inputWithCalendar__3D__b svg path,
    .Event_inputWithCalendar__3D__b span path {
      fill: #8c8c8c; }

.Event_text-orange__3Hz8T {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.Event_color-orange__37cRC {
  color: var(--accent-color) !important; }

.Event_overlay-loader__1ydZM {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Event_input-wrap__3t7NL {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .Event_input-wrap__3t7NL input::-webkit-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .Event_input-wrap__3t7NL input::-moz-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .Event_input-wrap__3t7NL input::-ms-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .Event_input-wrap__3t7NL input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.Event_ticketBackground__1Tadc {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .Event_ticketBackground__1Tadc span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .Event_ticketBackground__1Tadc svg {
    z-index: 1; }
  .Event_ticketBackgroundSmall__11xjG {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-right: -10px; }

.Event_wrap-input__15nWq {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .Event_wrap-input__15nWq {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.Event_labelInput__3Wg2O {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .Event_labelInput__3Wg2O {
      font-size: 14px;
      line-height: 25px; } }

.Event_defaultInput__2sg3o {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .Event_defaultInput__2sg3o {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.Event_checkbox__1sFnz {
  display: none; }

.Event_checkbox__1sFnz:checked + label:after {
  opacity: 1; }

.Event_checkbox__1sFnz + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0.7) translateY(calc(-50% - 5px));
          transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  -webkit-transition: opacity .35s ease;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .Event_checkbox__1sFnz + label:after {
      -webkit-transform: scale(0.7) translateX(3px) translateY(2px);
              transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.Event_checkbox__1sFnz + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  -webkit-transition: .2s;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .Event_checkbox__1sFnz + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.Event_checkbox__1sFnz + label {
  position: relative; }

.Event_checkbox__1sFnz + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0); }

.Event_checkbox__1sFnz:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.Event_checkbox__1sFnz:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa; }

.Event_checkbox__1sFnz:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.Event_simpleSelect__1IaYC {
  position: relative;
  margin-bottom: 20px; }

.Event_simpleInput__3ylWo {
  position: relative;
  margin-bottom: 20px; }
  .Event_simpleInput__3ylWo label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .Event_simpleInput__3ylWo label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .Event_simpleInput__3ylWo input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .Event_simpleInput__3ylWo input:focus, .Event_simpleInput__3ylWo input:active {
      border: 1px solid #8C8C8C !important; }
  .Event_simpleInput__3ylWo textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .Event_simpleInput__3ylWo textarea:focus, .Event_simpleInput__3ylWo textarea:active {
      border: 1px solid #8C8C8C !important; }
  .Event_simpleInput__3ylWo select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    -webkit-transition: border .35s ease;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .Event_simpleInput__3ylWo select option {
      width: 30px; }
    .Event_simpleInput__3ylWo select:focus, .Event_simpleInput__3ylWo select:active {
      border: 1px solid #8C8C8C !important; }

.Event_simpleInputBorder__p-fT- {
  margin-bottom: 0; }
  .Event_simpleInputBorder__p-fT- input {
    border: 1px solid #8C8C8C !important; }

.Event_hideBorder__7iRCv input {
  border: 1px solid transparent !important;
  -webkit-transition: border .35s ease;
  transition: border .35s ease; }

.Event_borderNone__3VID4 {
  border: 1px solid transparent !important; }
  .Event_borderNone__3VID4 * {
    border: none; }

.Event_column__smO96 {
  display: flex;
  flex-direction: column; }

.Event_fz17__2g6_h {
  font-size: 17px !important; }
  .Event_fz17__2g6_h * {
    font-size: 17px !important; }

.Event_radio-control__2E110 {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .Event_radio-control__2E110 {
      flex-direction: column; } }
  .Event_radio-control__2E110 label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .Event_radio-control__2E110 label {
        width: 100%; } }
  .Event_radio-control__2E110 input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.Event_radio__nU-o9 {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  -webkit-transition: border .35s ease, color .35s ease;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .Event_radio__nU-o9 span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    -webkit-transform: scale(0);
            transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    -webkit-transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, transform .35s ease;
    transition: opacity .35s ease, transform .35s ease, -webkit-transform .35s ease; }

.Event_active__YQp3A {
  color: var(--accent-color); }
  .Event_active__YQp3A .Event_radio__nU-o9 {
    border: 1.2px solid var(--accent-color); }
    .Event_active__YQp3A .Event_radio__nU-o9 span {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.Event_input_outline__1_1fr {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .Event_input_outline__1_1fr span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  -webkit-transition: border .34s ease;
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.Event_zIndex10__2oHMd {
  z-index: 10 !important; }

.Event_agree-error__3Dtdo {
  border-bottom: 1px solid #f61618; }

.Event_orange__7l1Oq {
  background-color: var(--accent-color) !important; }

.Event_center__2Oy-e {
  margin-left: auto;
  margin-right: auto; }

.Event_text-left__1-JFv {
  margin-left: 0;
  margin-top: 20px; }

@-webkit-keyframes Event_upAndDown__2OYuU {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@keyframes Event_upAndDown__2OYuU {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

.Event_upAndDown__2OYuU {
  -webkit-animation: Event_upAndDown__2OYuU 1s ease-in-out alternate infinite;
          animation: Event_upAndDown__2OYuU 1s ease-in-out alternate infinite; }

.Event_popup-content__3I0jd span,
.Event_popup-content__3I0jd strong {
  text-align: center; }

.Event_bottom-block__A7G6V {
  position: fixed;
  top: 62px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 4px -4px 16px rgba(11, 12, 36, 0.03);
  z-index: 9;
  -webkit-transform: translateY(-400%);
          transform: translateY(-400%);
  -webkit-transition: -webkit-transform 0.35s ease;
  transition: -webkit-transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease; }
  @media (max-width: 767.8px) {
    .Event_bottom-block__A7G6V {
      top: 70px; } }
  @media (max-width: 575.8px) {
    .Event_bottom-block__A7G6V {
      top: 58px; } }

.Event_show__DHBo- {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.Event_bottom-inner__3x_eo {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px; }
  @media (max-width: 575.8px) {
    .Event_bottom-inner__3x_eo {
      justify-content: center; } }

.Event_selected__3y00V input {
  border: 1px solid var(--accent-color) !important; }

.Event_selected__3y00V button {
  background-color: var(--accent-color); }
  .Event_selected__3y00V button:hover {
    border: 0.84615px solid rgba(255, 214, 87, 0.7);
    background: rgba(255, 214, 87, 0.7); }
  .Event_selected__3y00V button:active {
    border: 0.84615px solid rgba(255, 214, 87, 0.5);
    background: rgba(255, 214, 87, 0.5); }

.Event_promoError__11DNg input {
  border: 1px solid #fc4458 !important; }

.Event_promoSuccess__38zLF input {
  border: 1px solid #4caf50 !important; }

.Event_promoSuccess__38zLF button {
  background-color: #4caf50; }
  .Event_promoSuccess__38zLF button:hover {
    border: 0.84615px solid rgba(76, 175, 80, 0.7);
    background: rgba(76, 175, 80, 0.7); }
  .Event_promoSuccess__38zLF button:active {
    border: 0.84615px solid rgba(76, 175, 80, 0.5);
    background: rgba(76, 175, 80, 0.5); }

.Event_promo-incorrect-text__iQH8h {
  position: absolute;
  font-size: 11px;
  left: 0;
  bottom: 0;
  color: #fc4458 !important;
  -webkit-transition: -webkit-transform 0.35s ease;
  transition: -webkit-transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.Event_promo-incorrect-text-show__2D3Sh {
  -webkit-transform: translateY(25px);
          transform: translateY(25px); }

.Event_promoUnsuccess__2_G0Z input {
  border: 1px solid #fc4458 !important; }

.Event_promoUnsuccess__2_G0Z button {
  background-color: #fc4458; }
  .Event_promoUnsuccess__2_G0Z button:hover {
    border: 0.846146px solid #fc4458;
    background: #fc4458; }
  .Event_promoUnsuccess__2_G0Z button:active {
    border: 0.84615px solid rgba(252, 68, 88, 0.5);
    background: rgba(252, 68, 88, 0.5); }

.Event_wrap-checkbox__1RC0F {
  margin-bottom: 40px; }
  .Event_wrap-checkbox__1RC0F > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px; }
    .Event_wrap-checkbox__1RC0F > div > div {
      -webkit-transform: translateY(7px);
              transform: translateY(7px); }
  .Event_wrap-checkbox__1RC0F label {
    cursor: pointer;
    color: #8c8c8c;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: inline;
    margin-left: 10px;
    line-height: 28px;
    align-items: center; }
    .Event_wrap-checkbox__1RC0F label a {
      color: var(--accent-color); }
    @media (max-width: 575.8px) {
      .Event_wrap-checkbox__1RC0F label {
        font-size: 14px;
        flex-wrap: wrap;
        line-height: 28px;
        align-items: flex-start; } }

.Event_tickets-description__2VNcj {
  margin-bottom: 45px; }

.Event_s-tickets__MMELa {
  margin-bottom: 80px;
  margin-top: 50px;
  position: relative;
  z-index: 15; }

.Event_invent__2hqjI {
  background: var(--background-color); }
  @media (max-width: 575.8px) {
    .Event_invent__2hqjI .Event_container__22Z2t {
      padding: 0 16px; } }

.Event_wrap-items__1wNRj {
  position: relative;
  border-left: 1px solid #c7c6c5;
  box-sizing: border-box;
  box-shadow: 0px 4.96955px 3.72717px rgba(222, 222, 222, 0.09);
  margin-left: 20px;
  margin-right: 80px;
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
  transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
  transition: opacity 0.35s ease, transform 0.35s ease;
  transition: opacity 0.35s ease, transform 0.35s ease, -webkit-transform 0.35s ease;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: 20; }
  @media (max-width: 767.8px) {
    .Event_wrap-items__1wNRj {
      margin-right: 10px; } }
  @media (max-width: 575.8px) {
    .Event_wrap-items__1wNRj {
      margin-right: 0; } }

.Event_hideForm__2Y1rJ {
  overflow: hidden;
  position: absolute;
  left: -100000px;
  opacity: 0;
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.Event_slide-down__1exx3 {
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%; }
  @media (max-width: 575.8px) {
    .Event_slide-down__1exx3 {
      height: 34px; }
      .Event_slide-down__1exx3 svg {
        width: 10px !important; } }

.Event_item__1qrMe {
  margin-top: 30px;
  display: flex;
  align-items: center;
  position: relative; }
  @media (max-width: 575.8px) {
    .Event_item__1qrMe {
      width: calc(100% + 20px);
      -webkit-transform: translateX(-19px);
              transform: translateX(-19px); } }

.Event_item__1qrMe:first-child {
  margin-top: 0 !important; }

.Event_item-number__2wTO8 {
  width: 50px;
  height: 50px;
  border-radius: 10em;
  background: #ffffff;
  border: 1px solid #c7c6c5;
  color: #c7c6c5;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  flex-shrink: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding-top: 4px; }
  @media (max-width: 767.8px) {
    .Event_item-number__2wTO8 {
      width: 40px;
      font-size: 16px;
      height: 40px; } }
  @media (max-width: 575.8px) {
    .Event_item-number__2wTO8 {
      display: none; } }

.Event_item-number__2wTO8.Event_active__YQp3A {
  border: 1px solid var(--accent-color);
  color: var(--accent-color); }

.Event_item-wrap__3Ydjv {
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .Event_item-wrap__3Ydjv h5 {
    display: flex;
    align-items: center; }
    @media (max-width: 575.8px) {
      .Event_item-wrap__3Ydjv h5 {
        align-items: flex-start; }
        .Event_item-wrap__3Ydjv h5 svg {
          -webkit-transform: translateY(7px);
                  transform: translateY(7px);
          width: 14px;
          height: 14px; } }
    .Event_item-wrap__3Ydjv h5 .Event_saved-ticket__3dqkB {
      margin-left: 10px; }
      @media (max-width: 575.8px) {
        .Event_item-wrap__3Ydjv h5 .Event_saved-ticket__3dqkB {
          display: flex;
          flex-direction: column; } }
  @media (max-width: 767.8px) {
    .Event_item-wrap__3Ydjv {
      margin-left: 0; } }

.Event_item-header__2yZoI {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 83px;
  flex-grow: 1;
  cursor: pointer; }
  @media (max-width: 1023.8px) {
    .Event_item-header__2yZoI {
      min-height: 60px;
      padding: 20px; } }
  @media (max-width: 575.8px) {
    .Event_item-header__2yZoI {
      padding: 20px 16px;
      align-items: flex-start; } }

.Event_item-content__2envw {
  width: 100%;
  -webkit-transition: opacity 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  position: absolute;
  visibility: hidden;
  padding: 0 30px 30px;
  z-index: 10; }
  @media (max-width: 575.8px) {
    .Event_item-content__2envw {
      padding: 0 16px 16px; } }

.Event_showItem__3gp5A {
  position: relative;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  visibility: visible; }

.Event_item-fields__38RDx {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(calc(50% - 30px), 1fr));
  grid-column-gap: 30px;
  align-items: flex-end; }
  @media (max-width: 1023.8px) {
    .Event_item-fields__38RDx {
      display: flex;
      flex-direction: column; }
      .Event_item-fields__38RDx > div {
        width: 100%; } }

.Event_question__19vPB {
  position: relative;
  padding-bottom: 25px;
  flex-shrink: 0; }

.Event_text-help__3IcS1 {
  position: absolute;
  top: 81px; }
  @media (max-width: 575.8px) {
    .Event_text-help__3IcS1 {
      position: relative;
      top: 5px; } }
  .Event_text-help__3IcS1 a {
    color: var(--accent-color) !important; }

.Event_text-error__2QDsU {
  position: relative;
  margin-bottom: 0;
  font-size: 0.8rem; }

.Event_sale__2a2RB {
  padding: 15px 0 30px 70px;
  text-align: center; }
  .Event_sale__2a2RB p {
    min-height: 28px; }
  @media (max-width: 767.8px) {
    .Event_sale__2a2RB {
      padding-left: 40px; } }
  @media (max-width: 575.8px) {
    .Event_sale__2a2RB {
      padding-left: 20px;
      padding-right: 39px;
      padding-bottom: 15px; } }
  .Event_sale__2a2RB p {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 28px;
    color: var(--accent-color); }
    @media (max-width: 575.8px) {
      .Event_sale__2a2RB p {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Open Sans;
        font-size: 14px;
        line-height: 25px;
        text-align: center; }
        .Event_sale__2a2RB p span {
          margin-left: 0;
          margin-top: 10px; } }
    .Event_sale__2a2RB p span {
      margin-left: 7px; }

.Event_add-item__NgNJZ {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center; }
  .Event_add-item__NgNJZ span {
    margin-left: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: var(--accent-color);
    text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .Event_add-item__NgNJZ {
      padding: 15px 0; }
      .Event_add-item__NgNJZ span {
        font-weight: 600;
        font-size: 10px;
        line-height: 26px;
        text-transform: uppercase; }
      .Event_add-item__NgNJZ svg {
        width: 13px; } }

.Event_header-arrow__2eWDZ svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }
  @media (max-width: 575.8px) {
    .Event_header-arrow__2eWDZ svg {
      position: relative;
      top: 7px; } }

.Event_active__YQp3A svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.Event_item-delete__otMo- {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  cursor: pointer;
  -webkit-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease; }
  .Event_item-delete__otMo-:hover {
    opacity: 0.8; }
  .Event_item-delete__otMo-:active {
    opacity: 0.7; }
  @media (max-width: 575.8px) {
    .Event_item-delete__otMo- svg {
      width: 16px !important; } }

.Event_save__3fQLv {
  background: var(--accent-color);
  border-radius: 2px;
  width: 158px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1); }
  .Event_save__3fQLv:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

.Event_ticket_is_empty__16PgR {
  display: flex;
  align-items: center; }
  .Event_ticket_is_empty__16PgR p {
    min-width: 171px;
    font-size: 18px !important;
    margin-bottom: 0 !important; }

.Event_t-category__3S2Hg {
  margin-bottom: 30px;
  position: relative;
  z-index: 10; }

.Event_t-header__16FOp {
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-radius: 2px;
  padding: 20px 30px;
  position: relative;
  min-height: 127px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  z-index: 10; }
  @media (max-width: 1023.8px) {
    .Event_t-header__16FOp {
      padding: 20px; } }
  @media (max-width: 767.8px) {
    .Event_t-header__16FOp {
      flex-direction: column; } }

.Event_t-title__2G4du {
  width: 280px;
  color: #575757;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center; }
  .Event_t-title__2G4du span {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-transform: uppercase; }
    @media (max-width: 575.8px) {
      .Event_t-title__2G4du span {
        font-size: 18px; } }
  .Event_t-title__2G4du small {
    margin-top: 3px;
    font-family: Open Sans;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4rem;
    display: block; }
    @media (max-width: 767.8px) {
      .Event_t-title__2G4du small {
        margin-bottom: 20px; } }
  @media (max-width: 767.8px) {
    .Event_t-title__2G4du {
      max-width: 100%;
      width: 100%;
      text-align: center;
      padding-right: 0; } }

.Event_wrap-count__NGdvu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1; }
  @media (max-width: 1023.8px) {
    .Event_wrap-count__NGdvu {
      display: grid;
      grid-template-areas: 'price promo' 'count promo';
      grid-column-gap: 40px; } }
  @media (max-width: 767.8px) {
    .Event_wrap-count__NGdvu {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center; } }

.Event_count__2xhsf {
  display: flex;
  align-items: center; }
  @media (max-width: 1023.8px) {
    .Event_count__2xhsf {
      align-self: flex-start;
      grid-area: count; } }
  @media (max-width: 767.8px) {
    .Event_count__2xhsf {
      align-self: center;
      order: 2; } }
  .Event_count__2xhsf button {
    background: #8c8c8c;
    border: 0.84615px solid #8c8c8c;
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative; }
    .Event_count__2xhsf button span:after {
      content: '';
      display: block;
      background: #ffffff;
      border-radius: 0.974012px;
      position: absolute;
      width: 13px;
      height: 1.7px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .Event_count__2xhsf button.Event_plus__325DG span:before {
      content: '';
      position: absolute;
      background: #ffffff;
      border-radius: 0.974012px;
      display: block;
      height: 13px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 1.7px; }
  .Event_count__2xhsf input {
    border: 1px solid #8c8c8c;
    border-radius: 8.33321px;
    width: 61px;
    text-align: center;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #8c8c8c;
    height: 28px;
    margin: 0 20px; }

.Event_price__2E06E {
  margin-left: 50px;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  position: relative;
  color: #575757;
  margin-right: 60px;
  width: 150px; }
  @media (max-width: 1023.8px) {
    .Event_price__2E06E {
      width: auto;
      grid-area: price;
      max-width: 150px;
      align-self: flex-end;
      margin: 0; } }
  @media (max-width: 767.8px) {
    .Event_price__2E06E {
      align-self: center;
      order: 1; } }
  @media (max-width: 575.8px) {
    .Event_price__2E06E {
      font-size: 17px; } }
  .Event_price__2E06E small {
    font-family: Open Sans;
    font-size: 14px;
    right: -35px;
    top: -12px;
    line-height: 28px;
    position: absolute; }
    @media (max-width: 575.8px) {
      .Event_price__2E06E small {
        font-size: 12px;
        right: -30px; } }
  .Event_price__2E06E span {
    position: relative; }

.Event_promo__322nL {
  position: relative;
  padding-left: 86px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 10px; }
  @media (max-width: 1023.8px) {
    .Event_promo__322nL {
      padding-right: 0;
      padding-left: 66px;
      grid-area: promo; } }
  @media (max-width: 767.8px) {
    .Event_promo__322nL {
      align-self: center;
      margin-top: 20px;
      padding-left: 0;
      order: 3; } }
  .Event_promo__322nL p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #8c8c8c;
    margin-bottom: 5px; }
    @media (max-width: 1023.8px) {
      .Event_promo__322nL p {
        font-size: 14px;
        line-height: 26px; } }

.Event_promo-item__2AlXb {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  position: relative;
  min-width: 150px; }
  @media (max-width: 1023.8px) {
    .Event_promo-item__2AlXb {
      -webkit-transform: translateY(0);
              transform: translateY(0); } }

.Event_input-wrap__3t7NL {
  height: 35px;
  box-sizing: border-box;
  margin-bottom: 6px;
  grid-column-gap: 0; }

.Event_input-wrap__3t7NL * {
  box-sizing: border-box; }

.Event_line__3_qel {
  position: absolute;
  left: 0;
  width: 36px;
  height: 100%;
  display: block; }
  @media (max-width: 767.8px) {
    .Event_line__3_qel {
      display: none; } }
  @media (max-width: 575.8px) {
    .Event_line__3_qel {
      display: block;
      position: relative;
      margin: 0 auto;
      height: 40px;
      left: auto;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }

.Event_active__YQp3A button {
  border: 0.8px solid var(--accent-color);
  background: var(--accent-color);
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .Event_active__YQp3A button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

.Event_active__YQp3A input {
  border: 1px solid var(--accent-color);
  color: var(--accent-color); }

.Event_totalSum__h-EPB {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-family: 'Open Sans';
  color: #8c8c8c;
  font-size: 16px;
  justify-content: center; }
  .Event_totalSum__h-EPB p {
    font-size: 16px; }
  .Event_totalSum__h-EPB span {
    display: inline-flex;
    margin-left: 15px;
    position: relative;
    font-weight: 600;
    color: #575757;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px); }
    .Event_totalSum__h-EPB span small {
      position: relative;
      top: -8px;
      font-size: 10px;
      text-transform: uppercase; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Event_Component__IG5mK {
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  position: relative;
  z-index: 6;
  align-items: center;
  display: flex; }

.Event_Input__yxkuM {
  padding: 18px 5px 13px;
  flex-grow: 1;
  background: #ffffff;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.7em;
  margin: 0;
  border: 1px solid transparent;
  height: 60px;
  color: #575757; }
  .Event_InputPromo__39AbQ {
    border-radius: 2px 0 0 2px !important;
    padding: 10px !important; }

.Event_Button__18b3X {
  background: var(--accent-color);
  position: relative;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 0 7px 7px 0;
  height: 100%;
  border: 1px solid transparent; }
  .Event_Button__18b3X:hover {
    background: var(--accent-color);
    border-color: 1px solid var(--accent-color); }
  .Event_Button__18b3X[disabled], .Event_Button__18b3X:active {
    background: #f2b39d;
    border-color: 1px solid #f2b39d; }

.Event_promo-button__3YmEK {
  background: #8c8c8c;
  width: auto;
  color: #fff;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Event_promo-button__3YmEK:hover {
    background: rgba(140, 140, 140, 0.7); }
  .Event_promo-button__3YmEK[disabled], .Event_promo-button__3YmEK:active {
    background: rgba(140, 140, 140, 0.5); }

.Event_input-item__2mYnf {
  width: 80px;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 20px;
  padding-right: 50px;
  padding-left: 10px;
  height: 100%; }
  @media (max-width: 767.8px) {
    .Event_input-item__2mYnf {
      width: 250px; } }

.Event_oldPrice__ZLiuu {
  font-size: 14px;
  color: rgba(220, 53, 69, 0.7) !important;
  margin-right: 3px;
  text-decoration: line-through; }

.Event_icon-rotate__l9zQD {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.input-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .input-wrap input::-webkit-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .input-wrap input::-moz-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .input-wrap input::-ms-input-placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }
  .input-wrap input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ticketBackground {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .ticketBackground span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .ticketBackground svg {
    z-index: 1; }
  .ticketBackgroundSmall {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-right: -10px; }

.wrap-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .wrap-input {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.labelInput {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .labelInput {
      font-size: 14px;
      line-height: 25px; } }

.defaultInput {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .defaultInput {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.checkbox {
  display: none; }

.checkbox:checked + label:after {
  opacity: 1; }

.checkbox + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0.7) translateY(calc(-50% - 5px));
          transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  -webkit-transition: opacity .35s ease;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .checkbox + label:after {
      -webkit-transform: scale(0.7) translateX(3px) translateY(2px);
              transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.checkbox + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  -webkit-transition: .2s;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .checkbox + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.checkbox + label {
  position: relative; }

.checkbox + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0); }

.checkbox:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.checkbox:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa; }

.checkbox:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.simpleSelect {
  position: relative;
  margin-bottom: 20px; }

.simpleInput {
  position: relative;
  margin-bottom: 20px; }
  .simpleInput label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .simpleInput label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .simpleInput input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .simpleInput input:focus, .simpleInput input:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .simpleInput textarea:focus, .simpleInput textarea:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    -webkit-transition: border .35s ease;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .simpleInput select option {
      width: 30px; }
    .simpleInput select:focus, .simpleInput select:active {
      border: 1px solid #8C8C8C !important; }

.simpleInputBorder {
  margin-bottom: 0; }
  .simpleInputBorder input {
    border: 1px solid #8C8C8C !important; }

.hideBorder input {
  border: 1px solid transparent !important;
  -webkit-transition: border .35s ease;
  transition: border .35s ease; }

.borderNone {
  border: 1px solid transparent !important; }
  .borderNone * {
    border: none; }

.column {
  display: flex;
  flex-direction: column; }

.fz17 {
  font-size: 17px !important; }
  .fz17 * {
    font-size: 17px !important; }

.radio-control {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .radio-control {
      flex-direction: column; } }
  .radio-control label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .radio-control label {
        width: 100%; } }
  .radio-control input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.radio {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  -webkit-transition: border .35s ease, color .35s ease;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .radio span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    -webkit-transform: scale(0);
            transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    -webkit-transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, -webkit-transform .35s ease;
    transition: opacity .35s ease, transform .35s ease;
    transition: opacity .35s ease, transform .35s ease, -webkit-transform .35s ease; }

.active {
  color: var(--accent-color); }
  .active .radio {
    border: 1.2px solid var(--accent-color); }
    .active .radio span {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.input_outline {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .input_outline span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  -webkit-transition: border .34s ease;
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.zIndex10 {
  z-index: 10 !important; }

.select-wrap {
  position: relative;
  z-index: 1;
  width: 100%; }

.select {
  width: 100%; }

.zindex10 {
  z-index: 10 !important; }

.options {
  width: 570px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 3px rgba(222, 222, 222, 0.5);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  opacity: 0;
  bottom: 0px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  left: -5000px;
  position: absolute;
  max-height: 400px; }
  .options::-webkit-scrollbar {
    width: 2px; }
  .options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .options::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }
  .options span {
    display: flex;
    align-items: center;
    padding: 5px 0;
    flex-direction: row;
    cursor: pointer; }
    .options span small {
      font-family: var(--font-family);
      font-style: italic;
      font-weight: 300;
      display: inline-flex;
      text-indent: 0;
      -webkit-transition: color .35s ease, text-indent .35s ease;
      transition: color .35s ease, text-indent .35s ease;
      font-size: 16px;
      line-height: 21px;
      color: #8C8C8C; }
    .options span strong {
      -webkit-transition: opacity .35s ease-in-out, -webkit-transform .35s ease;
      transition: opacity .35s ease-in-out, -webkit-transform .35s ease;
      transition: opacity .35s ease-in-out, transform .35s ease;
      transition: opacity .35s ease-in-out, transform .35s ease, -webkit-transform .35s ease;
      opacity: 0;
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
      display: flex;
      align-items: center; }
    .options span:hover small {
      color: var(--accent-color);
      text-indent: 10px; }
    .options span:hover strong {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); }

.options-scroll {
  overflow-y: auto; }

.show {
  opacity: 1;
  left: 0; }

.input-wrap {
  position: relative; }

.select-arrow {
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  left: auto !important; }
  .select-arrow svg {
    -webkit-transition: -webkit-transform .35s ease;
    transition: -webkit-transform .35s ease;
    transition: transform .35s ease;
    transition: transform .35s ease, -webkit-transform .35s ease; }

.selected small {
  color: var(--accent-color) !important;
  text-indent: 10px !important; }

.selected strong {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important; }

.selectArrowActive svg {
  -webkit-transform: rotate(-90deg) !important;
          transform: rotate(-90deg) !important; }

.select-trigger {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  flex-grow: 1;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  color: #8C8C8C; }

.b0 {
  border: 1px solid transparent !important; }

.limit-text {
  font-size: 12px;
  display: flex;
  line-height: 14px; }
  .limit-text strong {
    margin-left: 3px; }

.event-page .event-after-description-row {
  border-top: 1px solid #ddd;
  padding: 32px 0;
  text-align: center; }

.event-page #payment-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.event-page .checkbox-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .event-page .checkbox-field label {
    margin-left: 15px; }

.event-page .checkbox-field_top {
  align-items: flex-start; }
  .event-page .checkbox-field_top label {
    margin-top: -2px; }

.event-page .ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  z-index: 2;
  color: #f0f0f0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px; }
  .event-page .ribbon.draft {
    background: #e82; }

.event-page .event-cover {
  background: #000;
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 3%; }
  .event-page .event-cover::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.95)), color-stop(60%, transparent));
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.95) 0%, transparent 60%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.95) 0%, transparent 60%); }
  .event-page .event-cover__img {
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .event-page .event-cover__img_shading {
    opacity: 0.4; }
  .event-page .event-cover__title {
    padding-top: 106px; }
  .event-page .event-cover .date-location {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    margin-bottom: 12px; }
  .event-page .event-cover .event-name {
    line-height: 1.15;
    margin-bottom: 60px;
    font-size: 54px;
    color: #fff;
    font-weight: 700;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75); }
  .event-page .event-cover__buying {
    padding: 25px 0;
    border-top: solid 1px rgba(255, 255, 255, 0.1); }
    .event-page .event-cover__buying .buying-text,
    .event-page .event-cover__buying .buying-price,
    .event-page .event-cover__buying .buying-btn {
      display: inline-block;
      vertical-align: top; }
    .event-page .event-cover__buying .buying-text {
      width: 50%; }
    .event-page .event-cover__buying .buying-rigth-side {
      text-align: right;
      display: inline-block;
      vertical-align: top;
      width: 50%; }
      .event-page .event-cover__buying .buying-rigth-side .btn {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold; }
    .event-page .event-cover__buying .buying-title {
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px; }
    .event-page .event-cover__buying .buying-info {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.2);
      font-weight: 300;
      line-height: 18px; }
    .event-page .event-cover__buying .buying-price {
      margin-right: 20px;
      margin-bottom: 24px; }
      .event-page .event-cover__buying .buying-price .currency {
        font-size: 34px;
        color: #7c7a9d; }
      .event-page .event-cover__buying .buying-price .price {
        font-size: 54px;
        color: #fff; }
        .event-page .event-cover__buying .buying-price .price_old {
          text-decoration: line-through;
          font-size: 26px;
          margin-left: 30px; }
          .event-page .event-cover__buying .buying-price .price_old .currency {
            font-size: 26px; }
    .event-page .event-cover__buying.multiple-tickets {
      position: relative;
      border: none;
      padding-top: 36px; }
      .event-page .event-cover__buying.multiple-tickets .blur-bg {
        background-repeat: no-repeat;
        background-position: center 100%;
        background-size: 150% 330%;
        -webkit-filter: blur(50px);
                filter: blur(50px);
        overflow: hidden;
        background: #000;
        width: 200%;
        height: 100%;
        position: absolute;
        left: -50%;
        top: 0;
        z-index: -1; }
        .event-page .event-cover__buying.multiple-tickets .blur-bg svg {
          opacity: 0.6;
          position: absolute;
          top: -30%;
          width: 100%;
          height: 220%;
          z-index: -2; }
          .event-page .event-cover__buying.multiple-tickets .blur-bg svg .blur-bg-img {
            height: 300%; }
      .event-page .event-cover__buying.multiple-tickets .row {
        display: flex;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        color: #ffffff;
        font-size: 16px; }
        .event-page .event-cover__buying.multiple-tickets .row:first-of-type {
          padding-top: 0; }
      .event-page .event-cover__buying.multiple-tickets .left-side {
        max-width: 50%;
        flex-basis: 50%;
        padding-right: 5%; }
      .event-page .event-cover__buying.multiple-tickets .right-side {
        flex-basis: 50%;
        max-width: 50%;
        text-align: right; }
      .event-page .event-cover__buying.multiple-tickets .buying-title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 0px; }
      .event-page .event-cover__buying.multiple-tickets .buying-info {
        margin-top: 8px; }
      .event-page .event-cover__buying.multiple-tickets .currency {
        font-size: 24px;
        color: #7c7a9d; }
      .event-page .event-cover__buying.multiple-tickets .price {
        font-size: 40px;
        color: #fff; }
        .event-page .event-cover__buying.multiple-tickets .price_old {
          text-decoration: line-through;
          font-size: 26px;
          margin-left: 30px; }
          .event-page .event-cover__buying.multiple-tickets .price_old .currency {
            font-size: 26px; }
      .event-page .event-cover__buying.multiple-tickets .tickets-left {
        font-size: 11px;
        color: #ccff00; }
      .event-page .event-cover__buying.multiple-tickets .ticket-price {
        text-align: right;
        margin-left: 15px;
        min-width: 130px;
        padding-top: 2px; }
        .event-page .event-cover__buying.multiple-tickets .ticket-price .tickets-left {
          margin-top: 5px; }
      .event-page .event-cover__buying.multiple-tickets .tickets-number {
        margin-left: 27px; }
      .event-page .event-cover__buying.multiple-tickets .expiry-date {
        margin-top: 7px;
        font-size: 12px;
        color: #757c8e; }
        .event-page .event-cover__buying.multiple-tickets .expiry-date .date {
          margin-top: 5px;
          color: #fff;
          display: block; }
      .event-page .event-cover__buying.multiple-tickets .expiry-date,
      .event-page .event-cover__buying.multiple-tickets .ticket-price,
      .event-page .event-cover__buying.multiple-tickets .tickets-number {
        display: inline-block;
        vertical-align: top; }
      .event-page .event-cover__buying.multiple-tickets .buying-row {
        padding: 28px 0 20px;
        text-align: right;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between; }
        .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn {
          background: #808080;
          cursor: default; }
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn:before {
            background: #808080; }
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn .btn,
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn .btn:before {
            color: #fff;
            background: #007b01;
            cursor: pointer; }
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn:hover span {
            -webkit-transform: none;
                    transform: none; }
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn span {
            color: rgba(255, 255, 255, 0.4);
            -webkit-transition: 0.3s;
            transition: 0.3s; }
          .event-page .event-cover__buying.multiple-tickets .buying-row.non-active .btn .total-price {
            visibility: hidden;
            opacity: 1; }
        .event-page .event-cover__buying.multiple-tickets .buying-row .total-price {
          display: inline-block;
          vertical-align: top;
          min-width: 130px;
          margin-left: 15px;
          margin-top: 4px;
          visibility: visible;
          opacity: 1;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
        .event-page .event-cover__buying.multiple-tickets .buying-row .buying-submit-btn .btn {
          margin-top: 10px;
          margin-left: 0;
          min-width: 242px; }

.event-page .event-info {
  padding-top: 56px; }
  .event-page .event-info__socials {
    float: right;
    width: 50px;
    margin-top: 8px; }
    .event-page .event-info__socials a {
      text-align: center;
      line-height: 50px;
      font-size: 22px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: solid 2px;
      display: block;
      margin-bottom: 18px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      position: relative; }
      .event-page .event-info__socials a svg {
        max-width: 20px;
        max-height: 20px;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
      .event-page .event-info__socials a:hover {
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.28); }
      .event-page .event-info__socials a.twitter {
        border-color: #5EA9DD;
        color: #5EA9DD; }
      .event-page .event-info__socials a.gplus {
        border-color: #DA2713;
        color: #DA2713; }
      .event-page .event-info__socials a.facebook {
        border-color: #3B5998;
        color: #3B5998; }
  .event-page .event-info__text {
    max-width: 765px;
    /* SHOULD MATCH WITH EVENT`S EDIT DESCRIPTION STYLES */ }
    .event-page .event-info__text blockquote,
    .event-page .event-info__text ul,
    .event-page .event-info__text ol,
    .event-page .event-info__text pre,
    .event-page .event-info__text div {
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 23px; }
    .event-page .event-info__text h1 {
      font-size: 46px;
      max-width: 580px;
      line-height: 1.4;
      padding-left: 0;
      margin-bottom: 30px;
      margin-top: 67px; }
      .event-page .event-info__text h1:first-of-type {
        margin-top: 0; }
      @media (max-width: 768px) {
        .event-page .event-info__text h1 {
          font-size: 26px; } }
    .event-page .event-info__text em {
      font-style: italic; }
    .event-page .event-info__text strong {
      font-weight: 700; }
    .event-page .event-info__text blockquote {
      font-style: italic;
      padding-left: 20px;
      border-left: 4px solid #333; }
    .event-page .event-info__text pre {
      white-space: pre-wrap;
      word-wrap: break-word; }
    .event-page .event-info__text ul {
      padding-left: 20px;
      list-style-type: disc; }
    .event-page .event-info__text ol {
      padding-left: 20px;
      list-style-type: decimal; }
  .event-page .event-info .payment-status {
    text-align: center; }
    .event-page .event-info .payment-status p {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px; }
  .event-page .event-info .event-info__map {
    margin-top: 57px;
    position: relative; }
    .event-page .event-info .event-info__map .gmap {
      height: 435px; }
    .event-page .event-info .event-info__map .map-text {
      position: absolute;
      background: rgba(24, 24, 55, 0.8);
      padding: 31px 60px 48px;
      z-index: 1;
      max-width: 370px;
      left: 0;
      top: 0; }
      @media (max-width: 768px) {
        .event-page .event-info .event-info__map .map-text {
          max-width: 100%;
          padding: 30px; } }
      .event-page .event-info .event-info__map .map-text .title {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px; }
      .event-page .event-info .event-info__map .map-text p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 15px;
        line-height: 1.1; }
  .event-page .event-info__footer {
    background: #f2f2f2;
    padding: 70px 3%;
    text-align: center; }
    .event-page .event-info__footer h5, .event-page .event-info__footer .organizer-name {
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 500;
      font-size: 14px; }
    .event-page .event-info__footer .organizer-logo {
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
      width: auto; }
    .event-page .event-info__footer h3 {
      color: #000;
      font-weight: 500;
      font-size: 20px;
      margin-top: 11px; }
    .event-page .event-info__footer a {
      display: block;
      margin: 10px 0 0;
      font-weight: bold;
      color: #4b4b4b; }

.event-page .event-registration-form {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px; }
  .event-page .event-registration-form h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px; }
  .event-page .event-registration-form h6 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    border-bottom: none;
    position: relative; }
    .event-page .event-registration-form h6 .remove-attendee {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-radius: 18px;
      color: #000;
      background: #fff;
      border: 2px solid #000;
      padding: 8px 12px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      font-size: 16px; }
      .event-page .event-registration-form h6 .remove-attendee:hover {
        background: #000;
        color: #ffffff; }
  .event-page .event-registration-form .ticket-form {
    margin-bottom: 20px; }
    .event-page .event-registration-form .ticket-form .ticket-questions {
      margin-bottom: 20px; }
      .event-page .event-registration-form .ticket-form .ticket-questions .question {
        margin-bottom: 20px; }
        .event-page .event-registration-form .ticket-form .ticket-questions .question .help-text {
          color: grey;
          margin-top: 5px; }
    .event-page .event-registration-form .ticket-form .add-new {
      padding: 0 20px;
      display: flex;
      margin: 0 auto; }
  .event-page .event-registration-form .buying-btn {
    text-align: center; }

@media (max-width: 768px) {
  .event-page .event-cover__title {
    padding-top: 40px; }
    .event-page .event-cover__title .date-location {
      font-size: 24px; }
    .event-page .event-cover__title .event-name {
      font-size: 44px; }
  .event-page .event-cover__buying .buying-rigth-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .event-page .event-cover__buying .buying-rigth-side .buying-price {
      margin: 0 0 10px; }
      .event-page .event-cover__buying .buying-rigth-side .buying-price .currency {
        font-size: 24px; }
      .event-page .event-cover__buying .buying-rigth-side .buying-price .price {
        font-size: 40px; }
  .event-page .event-cover__buying.multiple-tickets .row {
    flex-wrap: wrap; }
    .event-page .event-cover__buying.multiple-tickets .row .right-side,
    .event-page .event-cover__buying.multiple-tickets .row .left-side {
      flex-basis: 100%;
      max-width: 100%; }
    .event-page .event-cover__buying.multiple-tickets .row .left-side {
      text-align: left; }
    .event-page .event-cover__buying.multiple-tickets .row .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .event-page .event-cover__buying.multiple-tickets .row .right-side .ticket-price {
        width: auto;
        margin: 0; }
  .event-page .event-cover__buying.multiple-tickets .buying-submit {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center; }
    .event-page .event-cover__buying.multiple-tickets .buying-submit .total-price {
      margin: 0 0 10px;
      text-align: center; }
    .event-page .event-cover__buying.multiple-tickets .buying-submit .buying-submit-btn {
      margin: 0; }
  .event-page .event-info__footer {
    padding: 20px 3%; } }

@media (max-width: 576px) {
  .event-page .event-cover__title {
    padding-top: 20px; }
    .event-page .event-cover__title .date-location {
      font-size: 18px; }
    .event-page .event-cover__title .event-name {
      font-size: 32px; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Header_Component__2brVN {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  padding: 25px 0;
  background: var(--background-color);
  z-index: 20;
  -webkit-transition: box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out; }
  @media (max-width: 575.8px) {
    .Header_Component__2brVN {
      padding: 17px 0 10px; } }

.Header_Shadow__3jym0 {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }

.Header_Container__3A7CI {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 575.8px) {
    .Header_Container__3A7CI {
      padding: 0 10px 0; } }
  @media (max-width: 767.8px) {
    .Header_Container__3A7CI {
      padding: 3px 15px; } }

.Header_Logo__1DSEN {
  flex-basis: 20%;
  cursor: pointer; }
  @media (max-width: 1439.8px) {
    .Header_Logo__1DSEN {
      flex-basis: unset; } }
  @media (max-width: 767.8px) {
    .Header_Logo__1DSEN svg {
      height: 20px;
      width: auto; } }
  @media (max-width: 575.8px) {
    .Header_Logo__1DSEN {
      margin-bottom: 8px;
      margin-left: 4px; } }

.Header_Nav__29cRr {
  text-align: center;
  flex-grow: 1;
  margin-top: 5px; }
  .Header_Nav__29cRr a {
    color: #575757;
    line-height: 28px;
    font-size: 16px;
    margin-right: 40px;
    border-bottom: 1px solid transparent; }
    .Header_Nav__29cRr a:last-child {
      margin-right: 0; }
    .Header_Nav__29cRr a:hover {
      border-bottom-color: var(--accent-color); }
  @media (max-width: 1199.8px) {
    .Header_Nav__29cRr {
      display: none; } }

.Header_Actions__s2pCN {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.Header_SignBtn__291fK {
  margin-left: 40px;
  min-width: 151px;
  height: 45px;
  font-size: 16px;
  font-weight: 400; }

.Header_TicketButton__xe6Vl {
  width: 270px;
  height: 60px;
  margin-left: 30px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  color: var(--soft-blue); }
  .Header_TicketButton__xe6Vl span {
    position: relative;
    z-index: 2; }
  .Header_TicketButton__xe6Vl svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .Header_TicketButton__xe6Vl svg path {
      -webkit-transition: 0.2s;
      transition: 0.2s; }
  .Header_TicketButton__xe6Vl:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .Header_TicketButton__xe6Vl[disabled],
  .Header_TicketButton__xe6Vl:active svg path {
    cursor: not-allowed; }
  @media (max-width: 1439.8px) {
    .Header_TicketButton__xe6Vl {
      width: 220px;
      height: 50px; } }
  @media (max-width: 767.8px) {
    .Header_TicketButton__xe6Vl {
      width: 135px;
      height: 30px;
      font-size: 10px; } }
  @media (max-width: 575.8px) {
    .Header_TicketButton__xe6Vl {
      margin-left: 0px; } }

@media (max-width: 575.8px) {
  .Header_LanguageSwitcher__1VPZH {
    padding: 0 20px; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Posters_Page__G-Cfu {
  flex-grow: 1;
  background: var(--background-color) url(/static/media/bg_poster_page_lines.cadfd30f.svg) center 100px no-repeat;
  padding-top: 110px; }
  @media (max-width: 1023.8px) {
    .Posters_Page__G-Cfu {
      padding-top: 70px; } }
  @media (max-width: 767.8px) {
    .Posters_Page__G-Cfu {
      padding-top: 50px; } }
  @media (max-width: 575.8px) {
    .Posters_Page__G-Cfu {
      padding-top: 10px; } }

@-webkit-keyframes Posters_zoominoutsinglefeatured__1_5F0 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: .71; }
  50% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
    opacity: 1; }
  100% {
    opacity: .71;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes Posters_zoominoutsinglefeatured__1_5F0 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: .71; }
  50% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
    opacity: 1; }
  100% {
    opacity: .71;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

.Posters_keyframe__1Kojv {
  -webkit-animation: Posters_zoominoutsinglefeatured__1_5F0 2s infinite;
          animation: Posters_zoominoutsinglefeatured__1_5F0 2s infinite; }

.Posters_Intro__28buA {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575.8px) {
    .Posters_Intro__28buA {
      padding: 0 10px 0; } }
  .Posters_IntroTitle__12zj_ {
    white-space: pre-wrap; }
    @media (max-width: 1023.8px) {
      .Posters_IntroTitle__12zj_ {
        margin-bottom: 40px; } }
  @media (max-width: 1023.8px) {
    .Posters_Intro__28buA {
      padding-top: 100px;
      flex-direction: column;
      align-items: center; }
      .Posters_Intro__28buA img {
        max-width: 100%; } }

.Posters_Filters__A2JaK {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 30px; }
  @media (max-width: 575.8px) {
    .Posters_Filters__A2JaK {
      padding: 0 10px 0; } }
  @media (max-width: 1023.8px) {
    .Posters_Filters__A2JaK {
      grid-template-columns: 1fr; } }
  .Posters_FiltersSubmit__3UFTj {
    width: 190px;
    height: 60px;
    padding: 18px 0;
    font-weight: 600;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 26px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
    .Posters_FiltersSubmit__3UFTj span {
      position: relative;
      z-index: 2; }
    .Posters_FiltersSubmit__3UFTj svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
      .Posters_FiltersSubmit__3UFTj svg path {
        -webkit-transition: 0.2s;
        transition: 0.2s; }
    .Posters_FiltersSubmit__3UFTj:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    .Posters_FiltersSubmit__3UFTj[disabled],
    .Posters_FiltersSubmit__3UFTj:active svg path {
      cursor: not-allowed; }
    @media (max-width: 1023.8px) {
      .Posters_FiltersSubmitWrap__1BSvV {
        text-align: center; } }

.Posters_EventSection__3lJ9X {
  padding: 60px 15px 60px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center; }
  @media (max-width: 575.8px) {
    .Posters_EventSection__3lJ9X {
      padding: 60px 10px 60px; } }

.Posters_EventList__2iHl1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 370px));
  grid-auto-rows: minmax(390px, auto);
  grid-gap: 30px;
  margin-bottom: 60px;
  justify-content: center;
  text-align: left; }
  @media (max-width: 767.8px) {
    .Posters_EventList__2iHl1 {
      grid-auto-rows: auto; } }
  @media (max-width: 575.8px) {
    .Posters_EventList__2iHl1 {
      grid-template-columns: 290px; } }

.Posters_EventBtn__s1JVg {
  width: 100%;
  max-width: 370px; }

.Posters_Loader__1Nou3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px; }
  .Posters_Loader__1Nou3 svg {
    height: 50px;
    width: 50px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.SearchEventInput_Component__1D_Ma {
  z-index: 3;
  position: relative; }

.SearchEventInput_List__1QUSE {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 5px 6px 15px rgba(11, 12, 36, 0.04);
  border-radius: 2px;
  background-color: #fff;
  padding-top: 60px;
  max-height: 160px;
  overflow: auto;
  z-index: 1; }
  .SearchEventInput_ListItem__1yS35 {
    padding: 0;
    color: #868686;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1.4em;
    text-align: left;
    width: 100%; }
    .SearchEventInput_ListItem__1yS35:hover {
      color: #575757;
      background: var(--background-color); }

.SearchEventInput_Loader__3JL_B {
  padding: 10px;
  display: block;
  text-align: center; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.InputWithButton_Component__3EWfO {
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  position: relative;
  z-index: 6;
  align-items: center;
  display: flex; }

.InputWithButton_Input__147Jh {
  padding: 18px 6px 13px;
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.7em;
  margin: 0;
  border: 1px solid transparent;
  height: 60px;
  color: #575757; }

.InputWithButton_Button__2rAwt {
  background: var(--accent-color);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 0 7px 7px 0;
  height: 100%;
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1); }
  .InputWithButton_Button__2rAwt:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .InputWithButton_Button__2rAwt[disabled] {
    cursor: not-allowed; }

.InputWithButton_promo-button__G9nhI {
  background: #8c8c8c;
  width: 44px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .InputWithButton_promo-button__G9nhI:hover {
    background: rgba(140, 140, 140, 0.7); }
  .InputWithButton_promo-button__G9nhI[disabled], .InputWithButton_promo-button__G9nhI:active {
    background: rgba(140, 140, 140, 0.5); }

.InputWithButton_input-item__3PTRY {
  width: 136px;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 20px;
  height: 100%; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.DateInput_Component__n0Bgu {
  position: relative;
  z-index: 2; }
  .DateInput_Component_visible__1RtSw {
    z-index: 20; }

.DateInput_Dropdown__3Hdtx {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  min-width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  padding: 75px 10px 27px;
  z-index: 5; }
  .DateInput_DropdownClose__2x6QH {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0; }
  @media (max-width: 767.8px) {
    .DateInput_Dropdown__3Hdtx {
      position: fixed;
      z-index: 25;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex; }
      .DateInput_DropdownClose__2x6QH {
        display: block;
        top: 10px;
        right: 10px; } }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.DatePicker_Component__32AEd {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto; }

.DatePicker_Calendar__3xtZD {
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-gap: 10px 0; }

.DatePicker_Month__30J3e {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.7em; }
  .DatePicker_Month__30J3e button:first-of-type {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.DatePicker_Day__20Z2w {
  font-size: 16px;
  line-height: 1.7em;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
  color: #575757; }
  .DatePicker_Day_today__1DonF {
    color: var(--accent-color); }
  .DatePicker_Day__20Z2w:disabled, .DatePicker_Day_disabled__3wm00 {
    color: #ddd; }
  .DatePicker_Day_active__2VGzh {
    background: var(--accent-color);
    color: #575757; }
  .DatePicker_Day_active-first__2CKKQ {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px; }
  .DatePicker_Day_active-last__2qtz0 {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.EventCard_orange__3pJhA {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out; }
  .EventCard_orange__3pJhA:hover {
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    color: #ebefff; }

.EventCard_line-price__1fY4l {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  background: var(--accent-color);
  color: #ffffff; }

.EventCard_Component__CuSyC {
  box-shadow: 5px 6px 15px rgba(11, 12, 36, 0.04);
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative; }
  .EventCard_Component_full__3A7sv {
    flex-direction: row; }
    .EventCard_Component_full__3A7sv .EventCard_Description__3zpOG {
      display: block; }
    .EventCard_Component_full__3A7sv .EventCard_Btn__17CD3 {
      width: 50%; }
    .EventCard_Component_full__3A7sv .EventCard_Main__2PGk6 {
      align-items: flex-end; }
    @media (max-width: 1023.8px) {
      .EventCard_Component_full__3A7sv {
        flex-direction: column; }
        .EventCard_Component_full__3A7sv .EventCard_Main__2PGk6 {
          align-items: flex-start; }
        .EventCard_Component_full__3A7sv .EventCard_Description__3zpOG {
          display: none; }
        .EventCard_Component_full__3A7sv .EventCard_Btn__17CD3 {
          width: 100%; } }

.EventCard_Description__3zpOG {
  font-size: 18px;
  line-height: 1.65em;
  color: #575757;
  margin: 0;
  display: none; }

.EventCard_Image__1NFHl {
  min-width: 370px;
  max-width: 370px;
  height: 193px;
  background-color: #fff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden; }
  @media (max-width: 575.8px) {
    .EventCard_Image__1NFHl {
      height: 150px;
      min-width: unset;
      max-width: unset; } }

.EventCard_wrap-loader__1AUvh {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767.8px) {
    .EventCard_wrap-loader__1AUvh svg {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); } }

.EventCard_Price__2gvHD {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  color: #8c8c8c;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3em;
  -webkit-transform: scale(0.75);
          transform: scale(0.75); }
  @media (max-width: 575.8px) {
    .EventCard_Price__2gvHD {
      font-size: 12px; } }

.EventCard_Main__2PGk6 {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding-top: 40px; }
  @media (max-width: 575.8px) {
    .EventCard_Main__2PGk6 {
      padding: 15px;
      padding-top: 30px; } }

.EventCard_Info__24yWp {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 575.8px) {
    .EventCard_Info__24yWp {
      margin-bottom: 10px; } }

.EventCard_Title__2ds_Q {
  font-family: var(--font-family-title);
  font-weight: bold;
  font-size: 22px;
  line-height: 1.4em;
  margin: 0 0 5px;
  color: #575757; }
  @media (max-width: 575.8px) {
    .EventCard_Title__2ds_Q {
      font-size: 16px; } }

.EventCard_Param__3jpEB {
  font-size: 14px;
  line-height: 1.4em;
  margin: 0;
  margin: 0 0 5px;
  color: #575757; }
  .EventCard_Param__3jpEB span {
    display: inline-flex;
    padding-top: 2px; }
  .EventCard_Param__3jpEB svg {
    margin-right: 5px;
    margin-top: 5px;
    min-width: 20px; }
  @media (max-width: 575.8px) {
    .EventCard_Param__3jpEB {
      font-size: 14px;
      display: flex; } }

.EventCard_Calendar__3uUMR {
  display: flex;
  align-items: flex-start; }
  .EventCard_Calendar__3uUMR svg {
    margin-top: 3px; }

:root {
  --accent-color: #0c2696;
  --light-accent-color: #ebefff;
  --soft-blue: #123bed;
  --background-color: #fdfcff;
  --font-family: 'Open Sans', sans-serif;
  --font-family-title: 'Comfortaa', sans-serif; }

/*START CSS RESET*/
html {
  scroll-padding-top: 100px; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%; }

*, *:before, *:after {
  box-sizing: border-box; }

html, button {
  line-height: 1; }

body, button, input, textarea {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family);
  line-height: 1;
  font-size: 14px;
  text-rendering: optimizeLegibility !important; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

a {
  text-decoration: none; }

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block; }

:focus {
  outline: none; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

/* END RESET CSS */
input {
  -webkit-transition: 0.25s border-color;
  transition: 0.25s border-color; }

.select dt:before {
  content: '';
  display: block;
  position: absolute; }

.select dt:before {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.event-cover__img, .event-cover__buying.multiple-tickets .blur-bg svg {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.event-info__socials a svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.cf:before, .cf:after {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

.cf:after {
  clear: both; }

.wrap {
  max-width: 1160px;
  margin: auto; }

.pointer {
  cursor: pointer; }

.hide {
  display: none !important; }

body {
  overflow-x: hidden;
  background: #fff; }

.wrap {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%; }

header.page-header {
  padding: 8px 0;
  width: 100%;
  top: 0;
  left: 0; }
  header.page-header .wrap {
    max-width: 1200px;
    align-items: center; }
    header.page-header .wrap > * {
      flex-basis: calc(100% / 3); }
    @media (max-width: 768px) {
      header.page-header .wrap {
        flex-wrap: wrap; }
        header.page-header .wrap > * {
          flex-basis: 100%; } }
  header.page-header .actions {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 768px) {
      header.page-header .actions {
        justify-content: space-around; } }
    header.page-header .actions .profile-link {
      font-size: 15px;
      height: 35px;
      display: flex;
      align-items: center;
      margin: 0 10px;
      font-weight: bold; }
    header.page-header .actions .sign-out {
      font-size: 15px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      opacity: 0.6;
      margin-right: 30px;
      font-weight: bold; }
      @media (max-width: 768px) {
        header.page-header .actions .sign-out {
          margin: 0; } }
      header.page-header .actions .sign-out:hover {
        opacity: 1; }
  header.page-header .logo {
    margin-left: calc(100% / 3);
    text-align: center;
    font-weight: 700;
    color: #1e1d31;
    font-size: 21px; }
    @media (max-width: 768px) {
      header.page-header .logo {
        margin: 0; } }

.page-card {
  max-width: 1200px;
  margin: auto;
  z-index: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .page-card.no-header {
    margin-top: 51px; }

.page-footer {
  padding: 36px 0;
  text-align: center;
  color: #000;
  font-size: 14px; }

label {
  font-size: 14px;
  color: #23404b;
  font-weight: 500; }

select {
  background: #fff;
  margin-top: 5px;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  background: #fff url(/static/media/drop.43b5a81d.webp) 98% center no-repeat; }

select::-ms-expand {
  display: none; }

button {
  cursor: pointer;
  background-color: transparent;
  border: none; }

.btn {
  margin-left: 10px;
  padding: 17px 48px;
  position: relative;
  background: #007b01;
  border-radius: 25px;
  -webkit-transition: 0.45s;
  transition: 0.45s;
  z-index: 0;
  cursor: pointer;
  border: none; }

.btn:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: #007b01;
  border-radius: 25px;
  z-index: -1;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease; }

.btn span {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: inline-block; }

.btn:hover {
  background: rgba(0, 123, 1, 0.6); }

.btn:hover span {
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.btn:hover:before {
  background: rgba(0, 123, 1, 0.6);
  -webkit-transform: scale(0);
          transform: scale(0); }

.btn-trans,
.btn-white {
  height: 41px;
  line-height: 41px;
  font-size: 18px;
  border-radius: 21px;
  border: 2px solid transparent;
  text-align: center;
  color: #000;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  padding: 0 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.btn-white {
  font-size: 14px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.btn-trans:hover,
.btn-trans.active,
.btn-trans.router-link-exact-active {
  border-color: #ff9900;
  color: #ff9900; }

.btn-blue,
.btn-grey,
.btn-black {
  cursor: pointer;
  font-size: 20px;
  border: none;
  line-height: 58px;
  height: 55px;
  color: #fff;
  font-weight: 500;
  border-radius: 27px;
  display: inline-block;
  padding: 0 40px;
  vertical-align: middle;
  margin: 0 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #0078ff; }

.btn-black {
  color: #fff;
  height: 37px;
  line-height: 37px;
  font-size: 13px;
  padding: 0px 25px;
  background-color: #000; }

.btn-grey {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #d9d9d9; }

.btn-grey:hover {
  background-color: #d0d0d0; }

.btn-black:hover {
  background-color: #313131; }

.btn-blue:hover {
  background-color: #00A5FF; }

input,
textarea,
select {
  width: 100%;
  display: inline-block;
  border: 1px solid #cccccc;
  height: 42px;
  font-size: 20px;
  border-radius: 2px;
  color: #23404b;
  font-weight: 500;
  padding: 7px;
  margin-top: 5px; }

.text-danger {
  color: #dc3545 !important;
  margin-bottom: 10px; }

.border-danger {
  border-color: #dc3545 !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.sale-show {
  opacity: 1 !important; }

.sale-out {
  opacity: 0; }

.text-small {
  font-size: 0.7em;
  line-height: 1em; }

.d-flex {
  display: flex; }

@media (max-width: 1010px) {
  .wrap {
    padding: 0 3%; }
  .page-card {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%; } }

/* REFACTOR IN FUTURE */
.react-date-picker {
  display: block;
  background-color: #fff;
  width: 100%; }
  .react-date-picker .react-date-picker__button {
    width: 100%;
    border-color: #ccc; }
  .react-date-picker .react-date-picker__button__input__month {
    width: auto !important;
    text-align: center; }
  .react-date-picker .react-date-picker__button__input__day {
    width: auto !important;
    text-align: center; }
  .react-date-picker .react-date-picker__button__input__year {
    width: auto !important;
    text-align: center; }

/* REACT PHONE-INPUT */
.react-phone-number-input {
  border: 1px solid #ccc;
  border-radius: 2px; }
  .react-phone-number-input__country {
    margin-left: 10px; }
  .react-phone-number-input__input {
    border-bottom: none !important;
    border-left: 1px solid #ccc !important;
    margin: 0 !important;
    padding: 20px !important; }

button {
  outline: 0 !important; }

button:focus {
  outline: 0; }

*:focus {
  outline: none !important; }

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; }

.sign .main,
.sign .footer {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 130;
  text-align: center; }

.sign .main {
  z-index: 131; }

.sign h1 {
  font-weight: 700;
  font-size: 55px;
  color: #fff; }

.sign .main {
  top: calc(50% - 100px); }
  .sign .main p {
    font-size: 25px;
    color: #fff;
    margin: 20px 0; }
    @media (max-width: 767px) {
      .sign .main p {
        font-size: 22px;
        line-height: 1.4em;
        padding: 0 5%; } }

.sign .footer {
  bottom: 40px; }
  .sign .footer a {
    color: #23404b;
    padding: 10px;
    display: inline-block; }

.sign .homepage-hero-module {
  border-right: none;
  border-left: none;
  z-index: 2;
  position: relative; }

.sign .no-video .video-container video,
.sign .touch .video-container video {
  display: none; }

.sign .no-video .video-container .poster,
.sign .touch .video-container .poster {
  display: block !important; }

.sign .video-container {
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000; }
  .sign .video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
    min-width: 100%;
    min-height: 100%; }
  .sign .video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%; }
  .sign .video-container video {
    position: absolute;
    z-index: 0;
    bottom: 0; }
    .sign .video-container video.fillWidth {
      min-width: 100%;
      min-height: 100%; }

.buy-tickets .payment {
  padding: 30px 50px; }

.buy-tickets .visa {
  font-size: 40px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 40px;
  color: #fff; }

.buy-tickets .payment-form {
  padding: 30px;
  width: 55%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  margin-bottom: 30px; }
  .buy-tickets .payment-form label {
    color: rgba(255, 255, 255, 0.5); }
  .buy-tickets .payment-form input {
    color: #fff;
    font-size: 21px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

.buy-tickets .cvv {
  float: right;
  width: 60px; }

.buy-tickets .cvv input {
  text-align: center; }

.buy-tickets .expiration {
  display: inline-block; }

.buy-tickets .tickets-order {
  font-size: 0; }

.buy-tickets .tickets-list {
  font-size: 16px;
  display: inline-block;
  width: 55%;
  padding-left: 50px;
  padding-top: 30px;
  vertical-align: top; }

.buy-tickets h6 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 30px; }

.buy-tickets input {
  margin-bottom: 30px; }

.buy-tickets .order {
  padding: 30px;
  vertical-align: top;
  font-size: 15px;
  display: inline-block;
  width: 45%; }

.buy-tickets .inner {
  padding: 30px;
  background-color: #f0f0f0;
  border-radius: 15px;
  font-weight: 500; }

.buy-tickets .tickets-types {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  margin: 10px 0; }

.buy-tickets .total {
  color: rgba(0, 0, 0, 0.5); }

.buy-tickets .inner .row {
  margin: 15px 0;
  font-size: 0; }

.buy-tickets .col-6 {
  display: inline-block;
  width: 50%;
  font-size: 20px; }

.buy-tickets .col-3 {
  display: inline-block;
  width: 25%;
  font-size: 15px;
  text-align: right; }

.buy-tickets .sum {
  float: right;
  vertical-align: middle;
  font-size: 18px;
  color: #000; }

.buy-tickets .header {
  background-color: #333;
  padding: 35px 100px; }

.buy-tickets .header.cover {
  background: 0 0 no-repeat;
  background-size: 100% auto; }

.buy-tickets .user-actions {
  text-align: right; }

.buy-tickets .user-actions .router-link {
  font-size: 14px;
  color: #fff; }

.buy-tickets .user-actions i {
  font-size: 18px; }

.buy-tickets .date {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5); }

.buy-tickets .title {
  font-size: 31px;
  color: #fff;
  font-weight: 700; }

@media (min-width: 768px) and (max-width: 991px) {
  .buy-tickets .header {
    padding: 35px; }
  .buy-tickets .title {
    font-size: 25px;
    word-wrap: break-word; }
  .buy-tickets .date {
    font-size: 16px; }
  .buy-tickets .tickets-list,
  .buy-tickets .order,
  .buy-tickets .payment-form {
    width: 100%;
    padding: 30px; } }

@media (max-width: 767px) {
  .buy-tickets .header {
    padding: 20px; }
  .buy-tickets .header.cover {
    background-size: auto; }
  .buy-tickets .event-title {
    padding-top: 10px; }
  .buy-tickets .title {
    font-size: 25px;
    word-wrap: break-word; }
  .buy-tickets .date {
    font-size: 16px; }
  .buy-tickets .tickets-list,
  .buy-tickets .order,
  .buy-tickets .payment-form {
    width: 100%;
    padding: 15px; } }

@media (max-width: 480px) {
  .buy-tickets .payment {
    padding: 15px; }
  .buy-tickets .cvv {
    float: none;
    margin: 0 auto; }
  .buy-tickets .expiration {
    width: 100%; } }

.ErrorBoundary_Error__3522x {
  background: var(--background-color);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ErrorBoundary_ErrorContent__3iwvF {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
:root {
  --brand-primary: #f6db83;
  --brand-primary-light: var(--background-color); }

* {
  font-family: var(--font-family); }

body {
  font-family: var(--font-family);
  color: #575757; }

h1 {
  font-family: var(--font-family-title); }

.container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px; }
  @media (max-width: 575.8px) {
    .container {
      padding: 0 10px 0; } }
  @media (max-width: 575.8px) {
    .container.containerInvent {
      padding: 0 16px; } }

input,
select,
textarea,
a,
button {
  font-family: inherit; }

textarea {
  resize: none; }

a,
button {
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.66em;
  margin: 0; }
  @media (max-width: 767.8px) {
    h1 {
      font-size: 19px; } }

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0; }
  @media (max-width: 768px) {
    h2 {
      font-size: 16px; } }

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.85em;
  margin: 0; }

h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.65em;
  margin: 0; }

p {
  line-height: 1.66em;
  margin: 0;
  font-size: 18px;
  color: #575757; }
  @media (max-width: 768px) {
    p {
      font-size: 14px; } }

p.small {
  line-height: 1.75em;
  font-size: 16px; }

svg path {
  -webkit-transition: fill 0.35s ease;
  transition: fill 0.35s ease; }

.hide {
  position: absolute;
  opacity: 0;
  left: -10000px;
  visibility: hidden; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.wrap-table {
  width: 100%;
  background: #fff;
  border-radius: 2px; }

.table {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 1080px;
  color: #575757; }

.table-header {
  background: #e6e5e2;
  box-sizing: border-box;
  display: grid;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  grid-template-columns: 60px 3fr 2fr 1fr;
  grid-template-rows: 70px;
  grid-column-gap: 30px;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: flex-start;
  text-align: left;
  padding: 0 20px; }
  .table-header span:last-child {
    justify-self: center; }

.table-row {
  display: grid;
  padding: 0 20px;
  grid-template-rows: 70px;
  grid-template-columns: 60px 3fr 2fr 1fr;
  grid-column-gap: 30px;
  align-items: center;
  justify-content: flex-start;
  text-align: left; }
  .table-row span {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    text-transform: capitalize; }
    .table-row span a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #575757;
      text-transform: lowercase; }

.tableFull {
  grid-template-columns: 60px 3fr 2fr 1fr 170px; }

.block-action-wrap {
  position: relative; }

.action-area {
  position: relative; }
  .action-area .svg {
    display: inline-flex;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    left: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer; }

.block-action {
  padding-top: 20px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  -webkit-transform: translateY(calc(100% + 2px));
          transform: translateY(calc(100% + 2px));
  right: -80px; }
  .block-action.bottom {
    bottom: 30px;
    right: -60px; }
    .block-action.bottom .block-action-inner:after {
      right: 105px; }
  .block-action-inner {
    min-width: 309px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 10px rgba(222, 222, 222, 0.4);
    border-radius: 10.4939px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; }
    .block-action-inner:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 15px solid #fff;
      display: block;
      right: 155px;
      top: -12px;
      position: absolute; }
  .block-action button {
    font-style: normal;
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    padding-bottom: 13px; }
    .block-action button span {
      -webkit-transition: -webkit-transform 0.35s ease;
      transition: -webkit-transform 0.35s ease;
      transition: transform 0.35s ease;
      transition: transform 0.35s ease, -webkit-transform 0.35s ease;
      text-align: start;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); }
    .block-action button:last-child {
      padding-bottom: 0; }
    .block-action button svg {
      margin-right: 20px;
      max-width: 15px;
      flex-shrink: 0; }
    .block-action button svg path {
      fill: #575757;
      -webkit-transition: fill 0.35s ease;
      transition: fill 0.35s ease; }
    .block-action button svg.svgWhite circle {
      -webkit-transition: fill 0.35s ease;
      transition: fill 0.35s ease; }
    .block-action button svg.svgWhite path {
      fill: white; }
    .block-action button:hover span {
      color: #f24822 !important;
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
    .block-action button:hover svg path {
      fill: var(--accent-color); }
    .block-action button:hover svg.svgWhite circle {
      fill: var(--accent-color); }
    .block-action button:hover svg.svgWhite path {
      fill: white; }

.uploadFiles p {
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #8c8c8c; }

.height-block {
  height: 100px;
  display: block; }
  @media (max-width: 767.8px) {
    .height-block {
      height: 86px; } }
  @media (max-width: 575.8px) {
    .height-block {
      height: 58px; } }

.popup {
  display: flex;
  flex-direction: column;
  background: var(--background-color) !important;
  max-width: 1030px !important; }

.wrap-btn {
  display: flex;
  justify-content: center; }
  .wrap-btn button:last-child {
    margin-left: 20px; }
  @media (max-width: 575.8px) {
    .wrap-btn {
      flex-direction: column; }
      .wrap-btn button:last-child {
        margin-left: 0;
        margin-top: 20px; } }

.dropdown {
  position: relative; }

.react-add-to-calendar__dropdown {
  position: relative;
  display: flex;
  top: -23px;
  margin-bottom: -23px;
  background: #fff; }
  .react-add-to-calendar__dropdown a {
    color: var(--accent-color); }
    .react-add-to-calendar__dropdown a i {
      margin-right: 10px; }

.header-custom {
  z-index: 111 !important; }

@media (max-width: 575.8px) {
  .not-found-illustration {
    opacity: 0.3;
    width: 100% !important; } }

.tabs-control {
  display: flex;
  border-bottom: 2px solid var(--accent-color);
  margin-bottom: 50px; }
  @media (max-width: 767.8px) {
    .tabs-control {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      overflow: hidden;
      border-radius: 2px;
      border-bottom: 0; } }
  .tabs-control button {
    margin-bottom: 2px;
    position: relative;
    top: 2px;
    cursor: pointer;
    margin-right: 30px;
    flex-grow: 1;
    border-radius: 2px 2px 0 0;
    height: 70px;
    min-width: 170px;
    border: none;
    background: var(--light-accent-color);
    color: var(--accent-color);
    -webkit-transition: background 0.35s ease, color 0.35s ease;
    transition: background 0.35s ease, color 0.35s ease; }
    @media (max-width: 767.8px) {
      .tabs-control button {
        min-width: 100px;
        height: 45px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 0;
        margin: 0; } }
    .tabs-control button:last-child {
      margin-right: 0; }
    .tabs-control button.active, .tabs-control button:hover {
      background: var(--accent-color);
      color: #fff; }

.text-black {
  color: #333 !important; }
  .text-black button,
  .text-black a {
    color: #333 !important; }

.overflowH {
  overflow: hidden; }

.tabs-wrap {
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  overflow: hidden; }
  .tabs-wrap button {
    margin: 5px 10px 0 !important; }

.notSupported {
  background: red;
  color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1111;
  font-size: 24px;
  text-align: center;
  padding: 20px; }
  .notSupported a {
    text-decoration: underline;
    color: #000; }

.outline {
  position: relative; }
  .outline:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid var(--accent-color);
    border-radius: 2px; }

.remove-btn {
  margin-right: 0.5rem; }

.item-enter {
  opacity: 0; }

.item-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.item-exit {
  opacity: 1; }

.item-exit-active {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.fade {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 0; }

.entering {
  opacity: 1; }

.entered {
  opacity: 1; }

.exiting {
  opacity: 0; }

.exited {
  opacity: 0; }

.bg-pink {
  background: var(--background-color);
  min-height: 100vh;
  position: relative;
  box-sizing: border-box; }

.bottom-text-input {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c;
  margin-top: 10px; }
  @media (max-width: 575.8px) {
    .bottom-text-input {
      font-size: 14px;
      line-height: 18px; } }
  .bottom-text-input span {
    color: var(--accent-color);
    margin-right: 5px;
    position: relative;
    top: -2px;
    font-weight: normal; }

.btn-wrap {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 575.8px) {
    .btn-wrap {
      margin-top: 40px; } }
  .btn-wrap > button:last-child {
    margin-left: 20px; }

.upload-lists {
  display: flex;
  flex-direction: column; }
  .upload-lists .upload-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #575757;
    margin-left: 15px; }
    @media (max-width: 575.8px) {
      .upload-lists .upload-title {
        font-size: 14px;
        line-height: 26px;
        margin-left: 0; } }
  .upload-lists li {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 575.8px) {
      .upload-lists li {
        background: #ffffff;
        box-shadow: 0px 0px 27.4733px rgba(11, 12, 36, 0.04);
        border-radius: 12.6053px;
        padding: 10px 20px;
        flex-direction: column; } }
    @media (max-width: 575.8px) {
      .upload-lists li > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .upload-lists .upload-action span {
    cursor: pointer; }
  .upload-lists .upload-action span:last-child {
    margin-left: 20px; }
    @media (max-width: 575.8px) {
      .upload-lists .upload-action span:last-child {
        margin-left: 50px; } }

.list-empty {
  color: #8c8c8c;
  text-transform: uppercase; }

.password-field {
  position: relative; }
  .password-field svg path {
    fill: #8c8c8c; }
  .password-field input {
    padding-right: 40px; }
  .password-field.is-show svg path {
    fill: var(--accent-color); }
  .password-field strong[role],
  .password-field button {
    position: absolute;
    top: 50%;
    display: inline-flex;
    -webkit-transform: translateY(calc(-50% + 1px));
            transform: translateY(calc(-50% + 1px));
    right: 10px; }

.custom-modal {
  display: inline-flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%; }

.custom-modal-inner {
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: 1170px;
  overflow-y: auto;
  padding: 0 90px;
  background: var(--background-color);
  border-radius: 2px;
  margin-right: auto;
  margin-left: auto; }
  .custom-modal-inner::-webkit-scrollbar {
    width: 10px; }
  .custom-modal-inner::-webkit-scrollbar-track {
    background: #e6e5e2; }
  .custom-modal-inner::-webkit-scrollbar-thumb {
    background-color: #8c8c8c;
    outline: 1px solid transparent; }
  .custom-modal-inner input::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::-moz-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::-ms-input-placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  .custom-modal-inner input::placeholder {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8c8c8c; }
  @media (max-width: 768px) {
    .custom-modal-inner {
      padding: 0 20px; } }

