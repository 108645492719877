:root {
	// Color Variables:
	--accent-color: #0c2696; //#e5663b;
	--light-accent-color: #ebefff; //#ffe0d2;

	--soft-blue: #123bed;

	--background-color: #fdfcff;

	--font-family: 'Open Sans', sans-serif;
	--font-family-title: 'Comfortaa', sans-serif;
}
