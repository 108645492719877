@import '~scss/variables.scss';

.wrap-table {
	width: 100%;
	background: #fff;
	border-radius: 2px;
}

.table {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
	width: 1080px;
	color: #575757;
}

.table-header {
	background: #e6e5e2;
	box-sizing: border-box;
	display: grid;
	border-top-right-radius: 7px;
	border-top-left-radius: 7px;
	grid-template-columns: 60px 3fr 2fr 1fr;
	grid-template-rows: 70px;
	grid-column-gap: 30px;
	align-items: center;
	font-weight: 600;
	text-transform: uppercase;
	justify-content: flex-start;
	text-align: left;
	padding: 0 20px;

	span:last-child {
		justify-self: center;
	}
}

.table-row {
	display: grid;
	padding: 0 20px;
	grid-template-rows: 70px;
	grid-template-columns: 60px 3fr 2fr 1fr;
	grid-column-gap: 30px;
	align-items: center;
	justify-content: flex-start;
	text-align: left;

	span {
		display: flex;
		flex-direction: column;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #575757;
		text-transform: capitalize;

		a {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: #575757;
			text-transform: lowercase;
		}
	}
}

.tableFull {
	grid-template-columns: 60px 3fr 2fr 1fr 170px;
}

.block-action-wrap {
	position: relative;
}

.action-area {
	position: relative;

	.svg {
		display: inline-flex;
		position: absolute;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		left: 50%;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.block-action {
	padding-top: 20px;
	position: absolute;
	z-index: 1;
	bottom: 0;
	transform: translateY(calc(100% + 2px));
	right: -80px;

	&.bottom {
		bottom: 30px;
		right: -60px;

		.block-action-inner {
			&:after {
				right: 105px;
			}
		}
	}

	&-inner {
		min-width: 309px;
		background: #ffffff;
		box-shadow: 0px 0px 8px 10px rgba(222, 222, 222, 0.4);
		border-radius: 10.4939px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 15px solid #fff;
			display: block;
			right: 155px;
			top: -12px;
			position: absolute;
		}
	}

	button {
		font-style: normal;
		flex-grow: 1;
		width: 100%;
		display: flex;
		align-items: center;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #575757;
		padding-bottom: 13px;

		span {
			transition: transform 0.35s ease;
			text-align: start;
			transform: translateX(0px);
		}

		&:last-child {
			padding-bottom: 0;
		}

		svg {
			margin-right: 20px;
			max-width: 15px;
			flex-shrink: 0;
		}

		svg path {
			fill: #575757;
			transition: fill 0.35s ease;
		}

		svg.svgWhite {
			circle {
				transition: fill 0.35s ease;
			}

			path {
				fill: white;
			}
		}

		&:hover {
			span {
				color: #f24822 !important;
				transform: translateX(5px);
			}

			svg path {
				fill: var(--accent-color);
			}

			svg.svgWhite {
				circle {
					fill: var(--accent-color);
				}

				path {
					fill: white;
				}
			}
		}
	}
}

.uploadFiles {
	p {
		font-style: normal;
		font-weight: normal !important;
		font-size: 14px !important;
		line-height: 20px !important;
		color: #8c8c8c;
	}
}

.height-block {
	height: 100px;
	display: block;
	@include landscape {
		height: 86px;
	}
	@include phone {
		height: 58px;
	}
}

.popup {
	display: flex;
	flex-direction: column;
	background: var(--background-color) !important;
	max-width: 1030px !important;
}

.wrap-btn {
	display: flex;
	justify-content: center;

	button {
		&:last-child {
			margin-left: 20px;
		}
	}

	@include phone {
		flex-direction: column;
		button {
			&:last-child {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}
}

.dropdown {
	position: relative;
}

.react-add-to-calendar__dropdown {
	position: relative;
	display: flex;
	top: -23px;
	margin-bottom: -23px;
	background: #fff;

	a {
		color: var(--accent-color);

		i {
			margin-right: 10px;
		}
	}
}

.header-custom {
	z-index: 111 !important;
}

.not-found-illustration {
	@include phone {
		opacity: 0.3;
		width: 100% !important;
	}
}

.tabs-control {
	display: flex;
	border-bottom: 2px solid var(--accent-color);
	margin-bottom: 50px;
	@include landscape {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		overflow: hidden;
		border-radius: 2px;
		border-bottom: 0;
	}

	button {
		margin-bottom: 2px;
		position: relative;
		top: 2px;
		cursor: pointer;
		margin-right: 30px;
		flex-grow: 1;
		border-radius: 2px 2px 0 0;
		height: 70px;
		min-width: 170px;
		border: none;
		background: var(--light-accent-color);
		color: var(--accent-color);
		transition:
			background 0.35s ease,
			color 0.35s ease;
		@include landscape {
			min-width: 100px;
			height: 45px;
			font-size: 14px;
			line-height: 18px;
			border-radius: 0;
			margin: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.active,
		&:hover {
			background: var(--accent-color);
			color: #fff;
		}
	}
}

.text-black {
	color: #333 !important;

	button,
	a {
		color: #333 !important;
	}
}

.overflowH {
	overflow: hidden;
}

.tabs-wrap {
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	width: calc(100% + 20px);
	overflow: hidden;

	button {
		margin: 5px 10px 0 !important;
	}
}

.notSupported {
	background: red;
	color: #fff;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 1111;
	font-size: 24px;
	text-align: center;
	padding: 20px;

	a {
		text-decoration: underline;
		color: #000;
	}
}

.outline {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border: 2px solid var(--accent-color);
		border-radius: 2px;
	}
}

// .outline-new {
// 	color: var(--background-color) !important;
// 	background: var(--accent-color);
// 	border-radius: 4px;
// }

.remove-btn {
	margin-right: 0.5rem;
}

.item-enter {
	opacity: 0;
}

.item-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.item-exit {
	opacity: 1;
}

.item-exit-active {
	opacity: 0;
	transition: opacity 500ms ease-in;
}

.fade {
	transition: opacity 0.3s ease;
	opacity: 0;
}

.entering {
	opacity: 1;
}

.entered {
	opacity: 1;
}

.exiting {
	opacity: 0;
}

.exited {
	opacity: 0;
}

.bg-pink {
	background: var(--background-color);
	min-height: 100vh;
	position: relative;
	box-sizing: border-box;
}

.bottom-text-input {
	font-style: italic;
	font-weight: 300;
	font-size: 17px;
	line-height: 28px;
	color: #8c8c8c;
	margin-top: 10px;
	@include phone {
		font-size: 14px;
		line-height: 18px;
	}

	span {
		color: var(--accent-color);
		margin-right: 5px;
		position: relative;
		top: -2px;
		font-weight: normal;
	}
}

.btn-wrap {
	margin-top: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	@include phone {
		margin-top: 40px;
	}

	> button:last-child {
		margin-left: 20px;
	}
}

.upload-lists {
	display: flex;
	flex-direction: column;

	.upload-title {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 30px;
		color: #575757;
		margin-left: 15px;
		@include phone {
			font-size: 14px;
			line-height: 26px;
			margin-left: 0;
		}
	}

	li {
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include phone {
			background: #ffffff;
			box-shadow: 0px 0px 27.4733px rgba(11, 12, 36, 0.04);
			border-radius: 12.6053px;
			padding: 10px 20px;
			flex-direction: column;
		}

		> div:first-child {
			@include phone {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.upload-action {
		span {
			cursor: pointer;
		}

		span:last-child {
			margin-left: 20px;
			@include phone {
				margin-left: 50px;
			}
		}
	}
}

.list-empty {
	color: #8c8c8c;
	text-transform: uppercase;
}

.password-field {
	position: relative;

	svg path {
		fill: #8c8c8c;
	}

	input {
		padding-right: 40px;
	}

	&.is-show {
		svg path {
			fill: var(--accent-color);
		}
	}

	strong[role],
	button {
		position: absolute;
		top: 50%;
		display: inline-flex;
		transform: translateY(calc(-50% + 1px));
		right: 10px;
	}
}

.custom-modal {
	display: inline-flex;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 95%;
}

.custom-modal-inner {
	position: relative;
	width: 100%;
	z-index: 1;
	max-width: 1170px;
	overflow-y: auto;
	padding: 0 90px;
	background: var(--background-color);
	border-radius: 2px;
	margin-right: auto;
	margin-left: auto;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #e6e5e2;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8c8c8c;
		outline: 1px solid transparent;
	}

	input::placeholder {
		font-style: italic;
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;
		color: #8c8c8c;
	}

	@media (max-width: 768px) {
		padding: 0 20px;
	}
}
